import React from 'react';

const getPopupCancelTitle = (entity: string, count = 1): string => {
	return `Confirm ${count > 1 ? `${entity}s` : entity} cancellation`;
};
const getPopupCancelMessage = (entity: string, count = 1): JSX.Element => {
	return (
		<div>
			{`Are you sure you want to cancel the running ${count > 1 ? `${entity}s` : entity}?`}
			<br />
			{`This action will stop the ${entity}ing process on the selected properties. Any incomplete ${
				count > 1 ? `${entity}s` : entity
			} results will be discarded.`}
		</div>
	);
};
const getConfirmButtonText = (entity: string, count = 1): string => {
	return `Yes, cancel running ${count > 1 ? `${entity}s` : entity}`;
};

const getJobStatus = (entity: string, count = 1): string =>
	`${count} ${count > 1 ? `${entity}s are` : `${entity} is`} running`;

export default {
	getPopupCancelTitle,
	getPopupCancelMessage,
	getConfirmButtonText,
	getJobStatus
};
