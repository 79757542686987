import { union } from 'lodash';
import { IFilterOptions, orderByMethod } from '../components/data-filter/DataFilterHelper';
import CONFORMANCE_LEVELS from '../consts/ConformanceLevels';
import FormatHelper from '../helpers/FormatHelper';
import { FieldOptionName } from '../interfaces/Scan';
import scanService from './scan/ScanService';

export const getScansFilterListOptions = async (
	baseScanId: string,
	comparableScanId: string,
	option: FieldOptionName,
	viewId: string = null
): Promise<string[]> => {
	const baseScanOptions = await scanService.getScanResultsFieldOptions(baseScanId, option, viewId);
	const compareScanOptions = await scanService.getScanResultsFieldOptions(
		comparableScanId,
		option,
		viewId
	);
	return orderByMethod(union(baseScanOptions, compareScanOptions), option);
};

export const getInitialFilterOptions = async (
	scanId: string,
	viewId: string = null
): Promise<IFilterOptions> => {
	const filterOptionsFromServer = await scanService.getScanFilterOptionsV2(scanId, viewId);

	const response: IFilterOptions = {
		types: FormatHelper.formatFilterOptionsForEvSelect(
			orderByMethod(filterOptionsFromServer.types)
		),
		severities: FormatHelper.formatFilterOptionsForEvSelect(
			orderByMethod(filterOptionsFromServer.severities, FieldOptionName.SEVERITY)
		),
		tags: [
			...FormatHelper.formatFilterOptionsForEvSelect(orderByMethod(filterOptionsFromServer.tags)),
			{
				value: CONFORMANCE_LEVELS.UNSPECIFIED,
				label: CONFORMANCE_LEVELS.UNSPECIFIED
			}
		]
	};
	if (filterOptionsFromServer?.components) {
		response.components = FormatHelper.formatFilterOptionsForEvSelect(
			filterOptionsFromServer.components
		);
	}
	return response;
};
