import React, { FC } from 'react';
import ApiErrorHelper from '../helpers/ApiErrorHelper';
import { IServerError } from '../interfaces/ServerError';
import ContactSupportMailLink from './contact-support/ContactSupportMailLink';

interface IUnallowedDomainErrorProps {
	serverError?: IServerError;
	showServerErrorMessage?: boolean;
}

const UnallowedDomainError: FC<IUnallowedDomainErrorProps> = ({
	serverError,
	showServerErrorMessage = true
}: IUnallowedDomainErrorProps) => {
	const isDomainNotOnUserList = ApiErrorHelper.isDomainNotOnUserListError(serverError);
	const customMessage = isDomainNotOnUserList
		? 'By default, in order to prevent abuse, you will only be able to crawl/scan the web domain of your email address and any of its subdomains. If you need this constraint relaxed, '
		: 'If you need to update your allowed domains list, ';

	const renderServerError = (): JSX.Element => {
		if (!showServerErrorMessage) {
			return null;
		}
		const serverErrorMessage = ApiErrorHelper.getErrorMessage(serverError) || '';
		return (
			<>
				{serverErrorMessage}
				<br />
				<br />
			</>
		);
	};

	const renderCustomMessage = (): JSX.Element => {
		return (
			<div className="allowed-domains-support-message">
				{customMessage}
				please contact us at <ContactSupportMailLink />
			</div>
		);
	};

	return (
		<>
			{renderServerError()}
			{renderCustomMessage()}
		</>
	);
};

export default UnallowedDomainError;
