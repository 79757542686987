import logger from '../../services/Logger';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const safeJsonParse = (str: string, errorMsg = ''): any | null => {
	try {
		return JSON.parse(str);
	} catch (err) {
		logger.error(errorMsg, err);
		return null;
	}
};
