export default class ClipboardHelper {
	/**
	 * Copies text to clipboard
	 * @param text
	 * @param successText
	 * @param showSuccessToast
	 */
	static copyToClipboard = (text: string): boolean => {
		const el = document.createElement('textarea');
		el.value = text;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		el.select();
		const success = document.execCommand('copy');
		document.body.removeChild(el);
		return success;
	};
}
