import { TOAST_STATUS, VIEW_MODES } from '@evinced-private/ui-common';
import React, {
	FC,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useLocation } from 'react-router';
import {
	checkRunningScansStateChange,
	getCurrentTab,
	newRunningScans
} from '../../helpers/dashboard/DashboardHelper';
import useInterval from '../../hooks/useInterval';
import { IScan } from '../../interfaces/Scan';
import SCAN_STATE from '../../interfaces/ScanState';
import {
	COMPARE_TYPE,
	DEFAULT_COMPARE_TYPE,
	TActivePropertiesList,
	TActiveProperty,
	TActivePropertyDataParams,
	TAverageScoreKpi,
	TPagesMonitoredKpi,
	TQuickWins,
	TScansParams,
	TScansWithStatus,
	TTenantCriticalIssuesKpi
} from '../../pages/dashboard/DashboardTypes';
import { HOME_PAGES } from '../../pages/dashboard/HomePageTypes';
import {
	getActivePropertiesData,
	getActivePropertiesList,
	getActivePropertiesQuickWins,
	getCriticalIssuesKpi,
	getPagesMonitoredKpi,
	getScans,
	getScansWithStatus,
	getTenantScoreKpi
} from '../../services/dashboard/DashboardService';
import { useCollections } from '../collections/CollectionsProvider';
import { useNotifications } from '../notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../notificationsProvider/NotificationsContext';

interface IDashboard {
	numberOfRunningScans: number;
	avgScoreKpi: TAverageScoreKpi;
	tenantCriticalIssuesKpi: TTenantCriticalIssuesKpi;
	pageMonitoredKpi: TPagesMonitoredKpi;
	activePropertiesList: TActivePropertiesList;
	numberOfActiveProperties: number;
	activePropertiesData: TActiveProperty[];
	defaultActivePropertiesViews: TActiveProperty[];
	updateActivePropertiesData: (params: TActivePropertyDataParams) => void;
	updateScans: (scanParams: Omit<TScansParams, 'states'>) => void;
	quickWins: TQuickWins;
	scans: IScan[];
	getScansByPropertyView: ({ viewId, propertyId }: TScansParams) => Promise<TScansWithStatus>;
	setLiftPeriodCompare: (compareType: COMPARE_TYPE) => void;
	liftPeriodCompare: COMPARE_TYPE;
	loadingDuePeriodChangeForTable: boolean;
	loadingDuePeriodChangeForKpis: boolean;
	performanceOverTimeViewMode: VIEW_MODES;
	setPerformanceOverTimeViewMode: React.Dispatch<React.SetStateAction<VIEW_MODES>>;
	refreshDashboardData: () => void;
}

const REFRESH_INTERVAL = 20000;
const DashboardContext = createContext<IDashboard>(null);
const MAX_VIEWS_TO_APPLY = 3;

const DashboardProvider: FC = ({ children }) => {
	const [runningScans, setRunningScans] = useState<IScan[]>([]);
	const [avgScoreKpi, setAvgScoreKpi] = useState<TAverageScoreKpi>(null);
	const [tenantCriticalIssuesKpi, setTenantCriticalIssuesKpi] =
		useState<TTenantCriticalIssuesKpi>(null);
	const [pageMonitoredKpi, setPageMonitoredKpi] = useState<TPagesMonitoredKpi>(null);
	const [activePropertiesList, setActivePropertiesList] = useState<TActivePropertiesList>(null);
	const [activePropertiesData, setActivePropertiesData] = useState<TActivePropertiesList>(null);
	const [defaultActivePropertiesViews, setDefaultActivePropertiesViews] =
		useState<TActivePropertiesList>(null);
	const [quickWins, setQuickWins] = useState<TQuickWins>(null);
	const [scans, setScans] = useState<IScan[]>(null);
	const [liftPeriodCompare, setLiftPeriodCompare] = useState<COMPARE_TYPE>(DEFAULT_COMPARE_TYPE);
	const [loadingDuePeriodChangeForTable, setLoadingDuePeriodChangeForTable] =
		useState<boolean>(true);
	const [loadingDuePeriodChangeForKpis, setLoadingDuePeriodChangeForKpis] = useState<boolean>(true);
	const notificationsContext: INotificationsContext = useNotifications();
	const [performanceOverTimeViewMode, setPerformanceOverTimeViewMode] = useState<VIEW_MODES>(
		VIEW_MODES.GRAPH
	);
	const location = useLocation();
	const { collectionIds, showOnlyPropertiesInMyCollection, isCollectionsDataReady } =
		useCollections();

	const numberOfActiveProperties = useMemo(
		() => activePropertiesList?.length,
		[activePropertiesList]
	);

	const isRunningScansStateChanged = async (): Promise<void> => {
		const currentRunningScans = await getScans({
			states: [SCAN_STATE.RUNNING],
			collectionIds,
			showOnlyPropertiesInMyCollection
		});
		const failedScans = await getScans({
			states: [SCAN_STATE.FAILED],
			collectionIds,
			showOnlyPropertiesInMyCollection
		});
		const newScansRunning = newRunningScans(currentRunningScans, runningScans);
		const stateChanged = checkRunningScansStateChange(
			currentRunningScans,
			runningScans,
			failedScans
		);
		if (stateChanged && getCurrentTab(location.pathname) === HOME_PAGES.DASHBOARD) {
			notificationsContext.toast({
				show: true,
				status: TOAST_STATUS.INFO,
				announcement: 'Scans have completed. Please refresh the page for updated stats.'
			});
		}
		if (newScansRunning || stateChanged) {
			setRunningScans(currentRunningScans);
		}
	};

	const getNumberOfRunningScans = useCallback(async () => {
		const currentRunningScans = await getScans({
			states: [SCAN_STATE.RUNNING],
			collectionIds,
			showOnlyPropertiesInMyCollection
		});
		setRunningScans(currentRunningScans);
	}, [collectionIds, showOnlyPropertiesInMyCollection]);

	const getKpis = useCallback(async (): Promise<void> => {
		setLoadingDuePeriodChangeForKpis(true);
		const [avgScoreKpiResult, pageMonitoredKpiResult, tenantCriticalIssuesKpiResult] =
			await Promise.all([
				getTenantScoreKpi(liftPeriodCompare, collectionIds, showOnlyPropertiesInMyCollection),
				getPagesMonitoredKpi(liftPeriodCompare, collectionIds, showOnlyPropertiesInMyCollection),
				getCriticalIssuesKpi(liftPeriodCompare, collectionIds, showOnlyPropertiesInMyCollection)
			]);

		// fix for wrong values from the API
		// EP-7655 - itay todo remove when backend fixed
		const formattedAvgScoreKpiResult: TAverageScoreKpi = {
			score: Number(avgScoreKpiResult?.score) || 0,
			lift: Number(avgScoreKpiResult?.lift) || 0,
			liftPercentage: Number(avgScoreKpiResult?.liftPercentage) || 0
		};

		setAvgScoreKpi(formattedAvgScoreKpiResult);
		setPageMonitoredKpi(pageMonitoredKpiResult);
		setTenantCriticalIssuesKpi(tenantCriticalIssuesKpiResult);
		setLoadingDuePeriodChangeForKpis(false);
	}, [liftPeriodCompare, collectionIds, showOnlyPropertiesInMyCollection]);

	const updateActivePropertiesList = useCallback(async (): Promise<void> => {
		const activePropertiesListResult = await getActivePropertiesList(
			collectionIds,
			showOnlyPropertiesInMyCollection
		);
		setActivePropertiesList(activePropertiesListResult);
	}, [collectionIds, showOnlyPropertiesInMyCollection]);

	const updateActivePropertiesData = useCallback(
		async (params: TActivePropertyDataParams & { updateDefaultView?: boolean }) => {
			if (!isCollectionsDataReady) {
				return;
			}
			setLoadingDuePeriodChangeForTable(true);
			const activePropertiesDataResult = await getActivePropertiesData({
				...params,
				compareType: liftPeriodCompare,
				collectionIds,
				showOnlyPropertiesInMyCollection
			});
			setActivePropertiesData(activePropertiesDataResult.properties);
			if (params.updateDefaultView) {
				setDefaultActivePropertiesViews(
					activePropertiesDataResult?.properties.slice(0, MAX_VIEWS_TO_APPLY)
				);
			}
			setLoadingDuePeriodChangeForTable(false);
		},
		[liftPeriodCompare, collectionIds, showOnlyPropertiesInMyCollection, isCollectionsDataReady]
	);

	const updateQuickWins = useCallback(async () => {
		const quickWinsResult = await getActivePropertiesQuickWins({
			collectionIds,
			showOnlyPropertiesInMyCollection
		});
		setQuickWins(quickWinsResult);
	}, [collectionIds, showOnlyPropertiesInMyCollection]);

	useInterval(isRunningScansStateChanged, REFRESH_INTERVAL);

	const refreshDashboardData = useCallback(async (): Promise<void> => {
		await Promise.all([
			getKpis(),
			updateActivePropertiesData({
				pageNumber: 0,
				pageSize: 5,
				views: false,
				updateDefaultView: true
			}),
			getNumberOfRunningScans(),
			updateQuickWins(),
			updateActivePropertiesList()
		]);
	}, [
		getKpis,
		getNumberOfRunningScans,
		updateActivePropertiesList,
		updateQuickWins,
		updateActivePropertiesData
	]);

	const onLoad = useCallback(() => {
		if (isCollectionsDataReady) {
			getNumberOfRunningScans();
			updateActivePropertiesList();
			updateQuickWins();
		}
	}, [
		getNumberOfRunningScans,
		updateActivePropertiesList,
		updateQuickWins,
		isCollectionsDataReady
	]);

	useEffect(() => {
		onLoad();
	}, [onLoad]);

	const onLiftPeriodChange = useCallback(() => {
		if (isCollectionsDataReady) {
			getKpis();
			updateActivePropertiesData({
				pageNumber: 0,
				pageSize: 5,
				views: false,
				updateDefaultView: true
			});
		}
	}, [getKpis, updateActivePropertiesData, isCollectionsDataReady]);

	useEffect(() => {
		onLiftPeriodChange();
	}, [onLiftPeriodChange]);

	const getScansByPropertyView = useCallback(
		async ({ viewId, propertyId }) => {
			return getScansWithStatus({
				states: [SCAN_STATE.DONE, SCAN_STATE.DONE_WITHOUT_CSV, SCAN_STATE.DONE_WITHOUT_PAGES_CSV],
				propertyId,
				viewId,
				collectionIds,
				showOnlyPropertiesInMyCollection
			});
		},
		[collectionIds, showOnlyPropertiesInMyCollection]
	);

	const updateScans = useCallback(
		async ({ viewId, propertyId }) => {
			const scansResult = await getScans({
				states: [SCAN_STATE.DONE, SCAN_STATE.DONE_WITHOUT_CSV, SCAN_STATE.DONE_WITHOUT_PAGES_CSV],
				propertyId,
				viewId,
				collectionIds,
				showOnlyPropertiesInMyCollection
			});
			setScans(scansResult);
		},
		[collectionIds, showOnlyPropertiesInMyCollection]
	);

	useEffect(() => {
		const viewId = defaultActivePropertiesViews?.[0]?.view?.id;
		const propertyId = defaultActivePropertiesViews?.[0]?.property?.id;
		if (propertyId) {
			updateScans({ viewId, propertyId });
		}
	}, [updateScans, defaultActivePropertiesViews]);

	useEffect(() => notificationsContext.toast({ show: false }), [notificationsContext, location]);

	const value = useMemo(() => {
		return {
			numberOfRunningScans: runningScans?.length,
			avgScoreKpi,
			tenantCriticalIssuesKpi,
			pageMonitoredKpi,
			activePropertiesList,
			numberOfActiveProperties,
			activePropertiesData,
			defaultActivePropertiesViews,
			updateActivePropertiesData,
			quickWins,
			updateScans,
			scans,
			getScansByPropertyView,
			setLiftPeriodCompare,
			liftPeriodCompare,
			loadingDuePeriodChangeForTable,
			loadingDuePeriodChangeForKpis,
			performanceOverTimeViewMode,
			setPerformanceOverTimeViewMode,
			refreshDashboardData
		};
	}, [
		runningScans,
		avgScoreKpi,
		tenantCriticalIssuesKpi,
		pageMonitoredKpi,
		activePropertiesList,
		numberOfActiveProperties,
		activePropertiesData,
		defaultActivePropertiesViews,
		updateActivePropertiesData,
		quickWins,
		updateScans,
		scans,
		getScansByPropertyView,
		setLiftPeriodCompare,
		liftPeriodCompare,
		loadingDuePeriodChangeForTable,
		loadingDuePeriodChangeForKpis,
		performanceOverTimeViewMode,
		setPerformanceOverTimeViewMode,
		refreshDashboardData
	]);

	return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

const useDashboard = (): IDashboard => useContext(DashboardContext);

export { DashboardProvider, useDashboard };
