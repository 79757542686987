import { EvSpinner, useSkipLinks } from '@evinced-private/ui-common';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router';
import PropertySettingsActions from '../../components/property-settings/property-settings-actions/PropertySettingsActions';
import PropertySettingsContent from '../../components/property-settings/property-settings-content/PropertySettingsContent';
import PropertySettingsTitle from '../../components/property-settings/property-settings-title/PropertySettingsTitle';
import GA_CONSTS from '../../consts/google-analytics';
import formHelper, { FormError } from '../../helpers/FormHelper';
import PropertyHelper from '../../helpers/PropertyHelper';
import PropertySourceHelper from '../../helpers/PropertySourceHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import withLimitedAccess from '../../hoc/withLimitedAccess';
import { useBlockRefresh } from '../../hooks/useBlockRefresh';
import { IProperty } from '../../interfaces/Property';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import propertiesService from '../../services/PropertiesService';
import analyticsService from '../../services/analytics/AnalyticsService';
import SECTION_TYPES from '../../types/SettingsSectionTypes';
import URL_SET_SOURCES from '../../types/UrlSetSources';
import './PropertySettingsPage.scss';

const PropertySettingsPage: FC = () => {
	const [property, setProperty] = useState<IProperty>(null);
	const [originalProperty, setOriginalProperty] = useState<IProperty>(null);
	const [propertyErrors, setPropertyErrors] = useState<FormError[]>([]);
	const [propertySourceMode, setPropertySourceMode] = useState<URL_SET_SOURCES>();
	const [loading, setLoading] = useState(true);
	const [isSaveInProgress, setIsSaveInProgress] = useState(false);

	const notificationsContext = useNotifications();
	const { shouldBlockNavigation, setShouldBlockNavigation } = useBlockRefresh();
	const { propertyId } = useParams<{ [key: string]: string }>();
	const { setCurrentRoute } = useNavigation();
	const history = useHistory();

	// update skip links list when page is fully loaded
	useSkipLinks(!loading);

	useEffect(() => {
		if (propertyId) {
			setCurrentRoute({
				id: `advanced-settings-${propertyId}`,
				url: RoutesHelper.getPropertySettingsPagePath(propertyId),
				title: 'Advanced settings'
			});
		}
		return () => {
			setCurrentRoute(null);
		};
	}, [propertyId, setCurrentRoute]);

	const isListMode = (): boolean => {
		return PropertySourceHelper.isListMode(propertySourceMode);
	};

	const removePropertyError = (sectionId: SECTION_TYPES): void => {
		const newFormErrors = propertyErrors.filter((error) => error.sectionId !== sectionId);
		setPropertyErrors(newFormErrors);
	};

	const setNewPropertyFromRouteParams = useCallback((): void => {
		const queryParams = new URLSearchParams(history.location.search);
		const baseUrl = queryParams.get('baseurl');
		let newProperty: IProperty = PropertyHelper.getDefaultPropertyFromTemplate(baseUrl);
		newProperty = PropertyHelper.ensurePropertyHasDefaultRule(newProperty);
		setProperty(newProperty);
		setPropertySourceMode(URL_SET_SOURCES.URL_DISCOVERY);
		setLoading(false);
	}, [history.location.search]);

	const isPropertyChanged = useCallback((): boolean => {
		return !_.isEqual(property, originalProperty);
	}, [property, originalProperty]);

	const getPropertyData = useCallback(
		async (sourceMode?: URL_SET_SOURCES): Promise<void> => {
			setLoading(true);

			propertiesService
				.getPropertyData(propertyId)
				.then((propertyFromServer) => {
					if (!propertyFromServer) {
						setNewPropertyFromRouteParams();
						return;
					}

					setOriginalProperty(_.cloneDeep(propertyFromServer));
					setProperty(propertyFromServer);
					const source = sourceMode || propertyFromServer.urlSetConfiguration.source;
					setPropertySourceMode(source);
					setLoading(false);
				})
				.catch((err) => {
					notificationsContext.alert({
						serverError: err,
						onOK: () => history.push(RoutesHelper.getPropertiesPagePath())
					});
				});
		},
		[notificationsContext, history, propertyId, setNewPropertyFromRouteParams]
	);

	const goToProperty = useCallback(
		(propertyId): void => {
			setLoading(true);
			setShouldBlockNavigation(false);
			history.push(RoutesHelper.getPropertyPagePath(propertyId));
		},
		[history, setShouldBlockNavigation]
	);

	const updateProperty = useCallback(
		(propertyToSave): void => {
			propertiesService
				.updateProperty(propertyToSave)
				.then(() => {
					goToProperty(property.id);
				})
				.catch((err) => {
					notificationsContext.alert({
						title: 'Cannot save property',
						serverError: err
					});
				})
				.finally(() => {
					setIsSaveInProgress(false);
				});
		},
		[notificationsContext, goToProperty, property?.id]
	);

	const changePropertySourceMode = useCallback(async (): Promise<void> => {
		if (
			_.isEmpty(property) ||
			!propertySourceMode ||
			property?.urlSetConfiguration.source === propertySourceMode
		) {
			return;
		}
		setLoading(true);
		const newProperty = await propertiesService.changePropertySourceMode(
			property,
			propertySourceMode
		);
		setProperty(newProperty);
		setLoading(false);
	}, [propertySourceMode, setProperty, setLoading, property]);

	// run this when propertySourceMode changes
	useEffect(() => {
		changePropertySourceMode();
	}, [changePropertySourceMode]);

	// listen to property changes and check if we should block navigation due to them
	useEffect(() => {
		if (property) {
			setShouldBlockNavigation(isPropertyChanged());
		}
	}, [property, isPropertyChanged, setShouldBlockNavigation]);

	const createUrlSetAndUpdateProperty = async (propertyToSave: IProperty): Promise<void> => {
		propertiesService
			.createUrlSetAndUpdateProperty(propertyToSave, isListMode())
			.then((property) => {
				goToProperty(property.id);
			})
			.catch((err) => {
				notificationsContext.alert({
					title: 'Cannot save property',
					serverError: err
				});
			})
			.finally(() => {
				setIsSaveInProgress(false);
			});
	};

	const editProperty = (newProperty: IProperty, shouldCrawl: boolean): void => {
		const propertyToSave: IProperty = { ...newProperty };

		if (isListMode() || shouldCrawl) {
			createUrlSetAndUpdateProperty(propertyToSave);
		} else {
			// update only name
			updateProperty(propertyToSave);
		}

		analyticsService.fireEvent({
			category: GA_CONSTS.GA_CATEGORIES.PROPERTIES,
			action: GA_CONSTS.GA_ACTIONS.UPDATED
		});
	};

	const createNewProperty = async (newProperty: IProperty, shouldCrawl: boolean): Promise<void> => {
		propertiesService
			.createNewProperty(newProperty, shouldCrawl, isListMode())
			.then((createdProperty) => {
				setIsSaveInProgress(false);
				goToProperty(createdProperty.id);

				analyticsService.fireEvent({
					category: GA_CONSTS.GA_CATEGORIES.PROPERTIES,
					action: GA_CONSTS.GA_ACTIONS.CREATED
				});
			})
			.catch((err) => {
				setIsSaveInProgress(false);
				notificationsContext.alert({
					title: 'Cannot create property',
					serverError: err
				});
			});
	};

	const onSaveChanges = async (shouldCrawl = false): Promise<void> => {
		const formErrors: FormError[] = formHelper.validateProperty(property, isListMode());
		setPropertyErrors(formErrors);
		if (formErrors.length > 0) {
			return;
		}

		setIsSaveInProgress(true);

		if (PropertyHelper.isPropertyInDraftMode(propertyId)) {
			createNewProperty(property, shouldCrawl);
		} else {
			editProperty(property, shouldCrawl);
		}
	};

	// get property when page loads
	useEffect(() => {
		getPropertyData();
	}, [propertyId, getPropertyData]);

	if (loading) {
		return (
			<div className="spinner">
				<EvSpinner />
			</div>
		);
	}

	return (
		<div className="property-settings-page">
			<Prompt
				when={shouldBlockNavigation}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
			<PropertySettingsTitle
				propertyId={propertyId}
				originalProperty={originalProperty}
				property={property}
				setProperty={setProperty}
				sourceMode={propertySourceMode}
				nameError={propertyErrors.find((error) => error.sectionId === SECTION_TYPES.NAME)}
				editProperty={editProperty}
				getPropertyData={getPropertyData}
				goToProperty={goToProperty}
				setPropertySourceMode={setPropertySourceMode}
				setLoading={setLoading}
			/>
			<PropertySettingsContent
				originalProperty={originalProperty}
				property={property}
				setProperty={setProperty}
				propertyErrors={propertyErrors}
				removePropertyError={removePropertyError}
				isListMode={isListMode()}
			/>
			<PropertySettingsActions
				property={property}
				isListMode={isListMode()}
				isSaveInProgress={isSaveInProgress}
				onSaveChanges={onSaveChanges}
			/>
		</div>
	);
};

export default withLimitedAccess(PropertySettingsPage);
