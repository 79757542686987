import ServerApi from '../api/ServerApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { ITenant, ProductType } from '../interfaces/Tenant';
import { ITenantDescriptor } from '../interfaces/TenantDescriptor';

const { TENANTS_URL_PATH, DESCRIPTORS_URL_PATH } = API_URL_PATHS;

export enum TenantPlan {
	FREE_TRIAL = 'TRIAL',
	COMMUNITY = 'COMMUNITY'
}
const NOT_A_COMPANY_EMAIL_ERROR_MATCH = 'company email';
const EXPIRED_LICESE_ERROR_MATCH = 'expired';

async function getUserTenant(): Promise<ITenant> {
	const tenants: ITenant[] = await ServerApi.apiCall('GET', TENANTS_URL_PATH);
	if (!tenants || tenants.length === 0) {
		return null;
	}
	return tenants[0];
}

export const TenantDescriptorValue = {
	TRIAL: 'TRIAL',
	COMMUNITY: 'COMMUNITY'
};

const getTenantDescriptor = async (): Promise<ITenantDescriptor> => {
	return ServerApi.apiCall('GET', `${DESCRIPTORS_URL_PATH}/tenant`);
};

const hasTenant = async (): Promise<boolean> => {
	const tenant = await getUserTenant();
	return !!tenant;
};

const activateProduct = async (tenantId: string): Promise<void> => {
	const body = {
		type: ProductType.SCANNER,
		tenantId
	};
	return ServerApi.apiCall('POST', `${TENANTS_URL_PATH}/${tenantId}/products`, {
		data: body
	});
};

const hasAccessToProduct = async (userTenant?: ITenant): Promise<boolean> => {
	let tenant: ITenant = null;
	if (userTenant) {
		tenant = { ...userTenant };
	} else {
		tenant = await getUserTenant();
	}
	if (!tenant) {
		return false;
	}
	const productPermissions = tenant.products?.find((p) => p.type === ProductType.SCANNER);
	return !!productPermissions;
};

type ProductLicenseDetails = {
	date: Date;
	isExpired: boolean;
	isLicensedForever: boolean;
};

const getAllProductsLicenseDetails = async (): Promise<Map<ProductType, ProductLicenseDetails>> => {
	const tenant = await getUserTenant();
	if (!tenant) {
		return null;
	}
	const result: Map<ProductType, ProductLicenseDetails> = new Map();
	// eslint-disable-next-line no-unused-expressions
	tenant.products?.forEach((productDetails) => {
		const expirationDate = new Date(productDetails.expirationTime);
		const details: ProductLicenseDetails = {
			date: expirationDate,
			isExpired: expirationDate < new Date(),
			isLicensedForever: tenant.accountPlan !== 'TRIAL'
		};
		result.set(productDetails.type, details);
	});
	return result;
};

const getProductLicenseDetails = async (type: ProductType): Promise<ProductLicenseDetails> => {
	const allProductsDetails = await getAllProductsLicenseDetails();
	return allProductsDetails.get(type);
};

export enum ACTIVATION_ERROR {
	GENERAL,
	NO_TENANT,
	NOT_A_COMPANY_EMAIL,
	LICENSE_EXPIRED
}
const getActivationErrorType = (errorMsg: string): ACTIVATION_ERROR => {
	if (!errorMsg) {
		return ACTIVATION_ERROR.GENERAL;
	}
	if (errorMsg.includes(NOT_A_COMPANY_EMAIL_ERROR_MATCH)) {
		return ACTIVATION_ERROR.NOT_A_COMPANY_EMAIL;
	}
	if (errorMsg.includes(EXPIRED_LICESE_ERROR_MATCH)) {
		return ACTIVATION_ERROR.LICENSE_EXPIRED;
	}
	return ACTIVATION_ERROR.GENERAL;
};

const cancelAllJobs = async (): Promise<void> => {
	const tenant = await getUserTenant();
	return ServerApi.apiCall('PUT', `${TENANTS_URL_PATH}/${tenant.id}/jobs/cancel`);
};

export default {
	getUserTenant,
	getTenantDescriptor,
	hasTenant,
	activateProduct,
	hasAccessToProduct,
	getActivationErrorType,
	getProductLicenseDetails,
	cancelAllJobs
};
