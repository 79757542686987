/* eslint-disable react/jsx-no-useless-fragment */
import { EvButton, EvCard, EvSpinner, EvSection } from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ProductActivationError from '../../components/product-activation-error/ProductActivationError';
import ApiErrorHelper from '../../helpers/ApiErrorHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { ProductType } from '../../interfaces/Tenant';
import { ITenantDescriptor } from '../../interfaces/TenantDescriptor';
import Logger from '../../services/Logger';
import TenantsService, { TenantDescriptorValue, TenantPlan } from '../../services/TenantsService';
import CommunityPlanInfo from '../account-plan-info/CommunityPlanInfo';
import FreeTrialPlanInfo from '../account-plan-info/FreeTrialPlanInfo';
import './ProductActivationPage.scss';

const activateLicenseButtonText = 'Activate License';
const startTrialButtonText = 'Start Trial';

const ProductActivationPage: FC = () => {
	const [isLoading, setLoading] = useState(true);
	const [isSaveInProgress, setSaveInProgress] = useState(false);
	const [error, setError] = useState(null);
	const [accountPlan, setAccountPlan] = useState<TenantPlan>(TenantPlan.FREE_TRIAL);
	const { push } = useHistory();

	const goToHomepage = useCallback((): void => {
		push(RoutesHelper.getHomepagePath());
	}, [push]);

	const initPage = useCallback(async (): Promise<void> => {
		try {
			const hasAccessToProduct = await TenantsService.hasAccessToProduct();
			if (hasAccessToProduct) {
				goToHomepage();
				return;
			}
			const siteScannerLicense = await TenantsService.getProductLicenseDetails(ProductType.SCANNER);
			if (siteScannerLicense.isExpired) {
				setError('product expired');
				return;
			}
			const tenantDescriptor: ITenantDescriptor = await TenantsService.getTenantDescriptor();
			let accountPlan = null;
			if (tenantDescriptor?.accountPlan?.values.includes(TenantDescriptorValue.COMMUNITY)) {
				accountPlan = TenantPlan.COMMUNITY;
			} else {
				accountPlan = TenantPlan.FREE_TRIAL;
			}
			setAccountPlan(accountPlan);
		} catch (err) {
			Logger.error('Error getting user data', err);
		} finally {
			setLoading(false);
		}
	}, [goToHomepage]);

	const isCommunityMode = (): boolean => {
		return accountPlan === TenantPlan.COMMUNITY;
	};

	const getTitleText = (): string => {
		if (isCommunityMode()) {
			return 'Site Scanner Community License';
		}
		return 'Site Scanner 14-Day Free Trial';
	};

	const getButtonText = (): string => {
		return isCommunityMode() ? activateLicenseButtonText : startTrialButtonText;
	};

	useEffect(() => {
		initPage();
	}, [initPage]);

	const activateSiteScanner = async (): Promise<void> => {
		setSaveInProgress(true);
		const tenant = await TenantsService.getUserTenant();
		if (!tenant) {
			Logger.error('No tenant was found for this user, weird, they should not go to this page');
			setError('No tenant found');
			setSaveInProgress(false);
			return;
		}
		try {
			await TenantsService.activateProduct(tenant.id);
			Logger.debug('Product activated successfully');
			goToHomepage();
		} catch (error) {
			Logger.error('Error activating product', error);
			setError(ApiErrorHelper.getErrorMessage(error));
			setSaveInProgress(false);
		}
	};

	if (isLoading) {
		return <EvSpinner />;
	}

	if (error) {
		return <ProductActivationError errorType={TenantsService.getActivationErrorType(error)} />;
	}

	const getStartNowButton = (): JSX.Element => {
		return (
			<EvButton
				title={getButtonText()}
				onClick={activateSiteScanner}
				loading={isSaveInProgress}
				className="start-now-button"
			>
				{getButtonText()}
			</EvButton>
		);
	};

	return (
		<div className="product-activation-page">
			<EvCard
				title={getTitleText()}
				className={classNames(
					'product-activation-card',
					isCommunityMode() ? 'community-mode' : 'free-trial-mode'
				)}
				footer={getStartNowButton()}
				headingLevel={1}
			>
				<>
					<EvSection ariaLabel="main-section" className="activation-info">
						<div className="creation-information">
							{isCommunityMode() ? <CommunityPlanInfo /> : <FreeTrialPlanInfo />}
						</div>
					</EvSection>
				</>
			</EvCard>
		</div>
	);
};

export default ProductActivationPage;
