import React, { FC } from 'react';
import { EvSpinner } from '@evinced-private/ui-common';
import GraphIcon from '../../../../components/icons/GraphIcon.svg';
import NoDataInCollection from '../../../../components/no-data-in-collection/NoDataInCollection';
import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import LoadErrorMsgLayout from '../../../property/LoadErrorMsgLayout';

interface INoDataErrorMessage {
	showOnlyPropertiesInMyCollection: boolean;
}
const MAIN_TITLE = 'We don’t have enough data to activate your dashboard.';
const SECONDARY_MESSAGE = 'Please scan more properties, or contact your account admin.';

const NoDataErrorMessage: FC<INoDataErrorMessage> = ({ showOnlyPropertiesInMyCollection }) => {
	const { loadingDuePeriodChangeForKpis, loadingDuePeriodChangeForTable } = useDashboard();

	if (loadingDuePeriodChangeForKpis || loadingDuePeriodChangeForTable) {
		return <EvSpinner />;
	}
	return showOnlyPropertiesInMyCollection ? (
		<NoDataInCollection />
	) : (
		<LoadErrorMsgLayout mainTitle={MAIN_TITLE} secondaryMsg={SECONDARY_MESSAGE} icon={GraphIcon} />
	);
};

export default NoDataErrorMessage;
