import React, { FC } from 'react';
import LoadErrorMsgLayout from '../../../pages/property/LoadErrorMsgLayout';
import NoPagesMatchIcon from '../../icons/NoPagesMatchIcon.svg';

const mainTitle = 'No Pages match';
const secondaryMsg = 'Try switching the active view to a different one.';

const NoPagesMatchErrorPage: FC = () => {
	return (
		<div className="no-pages-match-wrapper">
			<LoadErrorMsgLayout
				mainTitle={mainTitle}
				secondaryMsg={secondaryMsg}
				icon={NoPagesMatchIcon}
			/>
		</div>
	);
};

export default NoPagesMatchErrorPage;
