import { EvToast } from '@evinced-private/ui-common';
import React, { FC, useCallback, useMemo, useState } from 'react';
import AlertPopup, { IAlertPopup } from '../../components/alert-popup/AlertPopup';
import NotificationsContext, { ILocalEvToastProps } from './NotificationsContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Provider: FC<{ children: any }> = ({ children }) => {
	const [isOpened, setOpened] = useState(false);
	const [errorDetails, setErrorDetails] = useState<IAlertPopup>();

	// EvToast states
	const [isEvToastOpened, setEvToastOpened] = useState<boolean>(false);
	const [toastDetails, setToastDetails] = useState<ILocalEvToastProps>();

	const onErrorPopupClosed = useCallback((): void => {
		setErrorDetails(null);
		setOpened(false);
	}, []);

	const value = useMemo(() => {
		return {
			alert: (errorDetails: IAlertPopup) => {
				setErrorDetails(errorDetails);
				setOpened(true);
			},
			toast: (toastDetails: ILocalEvToastProps) => {
				setToastDetails(toastDetails);
				setEvToastOpened(toastDetails.show);
			}
		};
	}, []);

	const onOk = useCallback((): void => {
		if (errorDetails.onOK) {
			errorDetails.onOK();
		}
		onErrorPopupClosed();
	}, [errorDetails, onErrorPopupClosed]);

	return (
		<NotificationsContext.Provider value={value}>
			{children}
			{isOpened && (
				<AlertPopup
					isOpened={isOpened}
					title={errorDetails.title}
					errorMessage={errorDetails.errorMessage}
					serverError={errorDetails.serverError}
					onOK={onOk}
				/>
			)}
			{isEvToastOpened && (
				<EvToast
					announcement={toastDetails.announcement}
					status={toastDetails.status}
					show={isEvToastOpened}
					onToastClose={() => setEvToastOpened(false)}
				/>
			)}
		</NotificationsContext.Provider>
	);
};

export default Provider;
