import { FormatHelper } from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IIssueTypesPaginationResponse } from '../../../../interfaces/IssueTypesPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { IssueTypesSummary } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';
import ScanIssueTypesTableHelperV2 from './ScanIssueTypesTableHelperV2';

interface IScanIssueTypesView {
	scanId: string;
	propertyId: string;
	viewId?: string;
}

const defaultTableSort = ScanIssueTypesTableHelperV2.getDefaultSort();

const ScanIssueTypesView: FC<IScanIssueTypesView> = ({
	scanId,
	propertyId,
	viewId
}: IScanIssueTypesView) => {
	const [scanResults, setScanResults] = useState<IIssueTypesPaginationResponse>(null);
	const [totalCount, setTotalCount] = useState<number>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<IssueTypesSummary>();
	const screenId = `scan-${scanId}-view-${viewId}-issues-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams?: FilterParams
		): Promise<void> => {
			const response: IIssueTypesPaginationResponse = await scanService.getScanIssueTypes(
				scanId,
				paginationParams,
				filtersParams,
				viewId
			);
			setScanResults(response);
		},
		[viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo: IssueTypesSummary = await scanService.getIssueTypesSummary(
			scanId,
			viewId
		);
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, viewId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		const { totalNumberOfIssues, criticalIssues, seriousIssues } = summaryPaneInfo;
		return [
			{
				value: FormatHelper.formatNumber(totalNumberOfIssues),
				label: 'Total issues'
			},
			{ value: FormatHelper.formatNumber(criticalIssues), label: 'Critical issues' },
			{ value: FormatHelper.formatNumber(seriousIssues), label: 'Serious issues' }
		];
	}, [summaryPaneInfo]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { types } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;

		return ScanIssueTypesTableHelperV2.getTableDefinition(
			types,
			propertyId,
			scanId,
			totalCount,
			totalPaginationableResults,
			screenId
		);
	}, [scanResults, totalCount, propertyId, scanId, screenId]);

	return (
		<BaseScanViewV2
			viewId={viewId}
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
		/>
	);
};

export default ScanIssueTypesView;
