import { OptionType } from '@evinced-private/ui-common';
import _, { Dictionary } from 'lodash';

import AzureHelper from '../components/azure-issue-creator/Helpers/AzureHelper';
import AZURE_URL_PATHS from '../components/azure-issue-creator/Helpers/UrlPaths';
import logger from '../services/Logger';
import {
	IAzureClassificationNode,
	IAzureField,
	IAzureFieldType,
	IAzureOrganization,
	IAzureProject,
	IAzureProjectType,
	IAzureUser
} from '../types/AzureTypes';
import { ITokenData } from '../types/JiraTypes';
import localStorageApi from './LocalStorageApi';

const {
	API_VERSION,
	WORK_ITEM_FIELDS,
	WORK_ITEM_TYPE,
	PROJECTS,
	WORK_ITEMS,
	PROJECT_TYPES,
	USERS,
	BYPASS,
	FIELDS,
	CLASSIFICATIONS,
	USER,
	ATTACHMENT
} = AZURE_URL_PATHS;

const azureAuthURL = process.env.AZURE_AUTH_TOKEN_URL as string;
const clientID = process.env.AZURE_APP_CLIENT_ID as string;
const clientSecret = process.env.AZURE_APP_CLIENT_SECRET as string;
const redirectURL = process.env.AZURE_REDIRECT_URL as string;
const scopes = 'vso.graph vso.identity vso.work_full';

const getAzureAuthURL = (): string => {
	const userID = localStorageApi.getUserFromLocalStorage();
	// used for 'state' param (required for security) - a value that is associated with the user
	// that is directed to the authorization URL
	const state = userID?.userId;
	return `https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${clientID}&response_type=Assertion&state=${state}&scope=${scopes}&redirect_uri=${redirectURL}`;
};
const innerCallApi = async (
	url: string,
	method = 'GET',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: any,
	withToken = true,
	contentType = 'application/json-patch+json'
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const requestHeaders: HeadersInit = new Headers();

	requestHeaders.set('Content-Type', contentType);
	requestHeaders.set('Accept', 'application/json-patch+json');

	if (withToken) {
		const azureToken: ITokenData = JSON.parse(localStorageApi.getAzureAccessToken());
		if (azureToken?.access_token) {
			requestHeaders.set('Authorization', `Bearer ${azureToken.access_token}`);
		} else {
			return Promise.reject(new Error('Unauthorized'));
		}
	}

	const fetchOptions: RequestInit = {
		mode: 'cors',
		method,
		headers: requestHeaders,
		body: null
	};

	if (payload) {
		fetchOptions.body =
			contentType === 'application/json-patch+json' ? JSON.stringify(payload) : payload;
	}
	logger.debug('request started: ', url);
	try {
		let response = await fetch(url, fetchOptions);

		if (response.status === 203) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			await refreshAzureToken();
			response = await innerCallApi(url, method, payload, withToken, contentType);
		}
		if (!response.ok) {
			logger.error('cannot call API', url, fetchOptions, response);
			throw response.status;
		}
		logger.debug('request complete successfully: ', url);
		const json = await response.json();
		logger.debug('Response for ', url, 'is:', json);
		return json;
	} catch (err) {
		logger.error('error for request: ', url, ' with options: ', fetchOptions, 'Error', err);
		throw err;
	}
};

const getAndSaveAzureToken = async (code: string): Promise<void> => {
	const azureToken = localStorageApi.getAzureAccessToken();
	if (azureToken) {
		window.close();
		return;
	}

	const payload = `client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer&client_assertion=${clientSecret}&grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer&assertion=${code}&redirect_uri=${redirectURL}`;
	const data = await innerCallApi(
		azureAuthURL,
		'POST',
		payload,
		false,
		'application/x-www-form-urlencoded'
	);

	if (data.access_token) {
		localStorageApi.setAzureAccessToken(JSON.stringify(data));
		window.close();
	}
};

const refreshAzureToken = async (): Promise<void> => {
	const azureToken = JSON.parse(localStorageApi.getAzureAccessToken());

	if (azureToken) {
		const payload = `client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer&client_assertion=${clientSecret}&grant_type=refresh_token&assertion=${azureToken.refresh_token}&redirect_uri=${redirectURL}`;
		const data = await innerCallApi(
			azureAuthURL,
			'POST',
			payload,
			false,
			'application/x-www-form-urlencoded'
		);

		if (data.access_token) {
			localStorageApi.setAzureAccessToken(JSON.stringify(data));
		}
	}
};

const getProjects = async (host: string): Promise<IAzureProject[]> => {
	const url = AzureHelper.getOrganizationNameUrl(host);
	try {
		const projects = await innerCallApi(`${url}${PROJECTS}?${API_VERSION}`);
		return AzureHelper.getProjectsOptionType(projects.value) ?? [];
	} catch (error) {
		logger.error('error for request: getProjects', url, 'Error', error);
		return [];
	}
};

const getUsers = async (host: string): Promise<IAzureUser[]> => {
	const url = AzureHelper.getUsersUrl(host);

	try {
		const response = (await innerCallApi(`${url}${USERS}`)).value;
		const users = response.filter((user) => {
			if (!user.displayName.includes('Build Service')) {
				return {
					id: user.originId,
					email: user.mailAddress,
					displayName: user.displayName
				};
			}
			return null;
		});

		return users;
	} catch (error) {
		logger.error('error for request: getUsers', url, 'Error', error);
		return [];
	}
};

const getClassification = async (host: string): Promise<IAzureClassificationNode[]> => {
	const url = `${host}/${CLASSIFICATIONS}&${API_VERSION}`;
	try {
		const classification = (await innerCallApi(url)).value;
		return classification;
	} catch (error) {
		logger.error('error for request: getClassification', url, 'Error', error);
		return [];
	}
};

const getSelectedIssueType = async (
	host: string,
	selectedIssueType: string
): Promise<IAzureFieldType[]> => {
	const url = `${host}${WORK_ITEM_TYPE}/${selectedIssueType}${WORK_ITEM_FIELDS}&${API_VERSION}`;
	try {
		const selectedIssueType: IAzureFieldType[] = (await innerCallApi(url)).value;
		return selectedIssueType;
	} catch (error) {
		logger.error('error for request: selectedIssueType', url, 'Error', error);
		return [];
	}
};

const getProjectFields = async (
	host: string,
	selectedIssueType: string
): Promise<Dictionary<IAzureFieldType>> => {
	const fieldsUrl = `${host}${FIELDS}?${API_VERSION}`;
	try {
		let selectedIssueTypeFields: IAzureFieldType[] = await getSelectedIssueType(
			host,
			selectedIssueType
		);
		const fields: Dictionary<IAzureField> = _.keyBy(
			(await innerCallApi(fieldsUrl)).value,
			'referenceName'
		);
		const users: string[] = (await getUsers(host)).map((user: IAzureUser) => user.displayName);
		const classificationNodes: IAzureClassificationNode[] = await getClassification(host);
		const areaNodes: IAzureClassificationNode[] = [classificationNodes[0]];
		const iterationsNodes: IAzureClassificationNode[] = [classificationNodes[1]];
		const areas: string[] = AzureHelper.getClassificationNodes(areaNodes);
		const iterations: string[] = AzureHelper.getClassificationNodes(iterationsNodes);

		selectedIssueTypeFields = selectedIssueTypeFields.map((field: IAzureFieldType) => {
			if (field.referenceName === 'System.AreaPath') {
				return { ...field, allowedValues: areas, type: 'select' };
			}
			if (field.referenceName === 'System.IterationPath') {
				return { ...field, allowedValues: iterations, type: 'select' };
			}
			return fields[field.referenceName]?.isIdentity ? { ...field, allowedValues: users } : field;
		});

		return AzureHelper.getFormattedFields(fields, selectedIssueTypeFields);
	} catch (error) {
		logger.error('error for request: getProjectFields', fieldsUrl, 'Error', error);
		return _.keyBy([]);
	}
};

const getUser = async (): Promise<IAzureUser> => {
	try {
		const response = await innerCallApi(USER);
		const user = {
			id: response.id,
			email: response.emailAddress,
			displayName: response.displayName
		};
		return user;
	} catch (error) {
		logger.error('error for request: getUser', USER, 'Error', error);
		return {
			id: '',
			email: '',
			displayName: ''
		};
	}
};

const getOrganizations = async (): Promise<OptionType[]> => {
	try {
		const { id } = await getUser();
		const organizations: IAzureOrganization[] = (
			await innerCallApi(
				`https://app.vssps.visualstudio.com/_apis/accounts?memberId=${id}&api-version=6.0`
			)
		).value;
		return organizations.map((organization) => {
			const paths = AzureHelper.getPaths(organization.accountUri);
			const organizationName = paths[paths.length - 2];
			return { label: organizationName, value: organizationName };
		});
	} catch (error) {
		logger.error('error for request: getOrganizations', 'Error', error);
		return null;
	}
};

const getProjectTypes = async (host: string): Promise<OptionType[]> => {
	const url: string = AzureHelper.getProjectNameUrl(host);
	try {
		const projectTypes: IAzureProjectType[] = (
			await innerCallApi(`${url}${PROJECT_TYPES}?${API_VERSION}`)
		).value;
		return AzureHelper.getProjectTypes(projectTypes) ?? null;
	} catch (error) {
		logger.error('error for request: getProjectTypes', url, 'Error', error);
		return [];
	}
};

const openAzureTicket = (url: string): void => {
	AzureHelper.openUrlInNewTab(url);
};

const createIssue = async (
	host: string,
	selectedIssueType: string,
	screenshotUrlBase64: string,
	fields: Dictionary<IAzureFieldType>
): Promise<boolean> => {
	const attachmentHost: string = AzureHelper.getProjectNameUrl(host);

	try {
		let payload = AzureHelper.getFieldsMetaData(fields);

		if (screenshotUrlBase64) {
			const attachmentFile = AzureHelper.dataURItoBlob(screenshotUrlBase64);
			const attachment = await innerCallApi(
				`${attachmentHost}${ATTACHMENT}&${API_VERSION}`,
				'POST',
				attachmentFile,
				true,
				'application/octet-stream'
			);

			payload = AzureHelper.addAttachmentToPayload(attachment.url, payload);
		}
		const response = await innerCallApi(
			`${attachmentHost}${WORK_ITEMS}$${selectedIssueType}${BYPASS}&${API_VERSION}`,
			'POST',
			payload
		);

		if (response._links.html.href) {
			openAzureTicket(response._links.html.href);
		}
		return !!response;
	} catch (error) {
		logger.error('error for request: createIssue', attachmentHost, 'Error', error);
		return false;
	}
};

export default {
	getProjectFields,
	getOrganizations,
	getAzureAuthURL,
	getAndSaveAzureToken,
	createIssue,
	getProjects,
	getUsers,
	getProjectTypes,
	getUser
};
