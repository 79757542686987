import {
	EvTableColumn,
	EvTableOptions,
	SORT_ORDER,
	TableSortOption,
	UrlCellFormatter
} from '@evinced-private/ui-common';
import React from 'react';
import { ScanTableDefinition } from '../../../../components/scan-analysis/base/BaseScanViewV2';
import UrlStatusDetailsFormatter from '../../../../components/scan-analysis/table-formatters/url-status-details-formatter/UrlStatusDetailsFormatter';
import {
	PageResultState,
	RequestedUrl
} from '../../../../interfaces/RequestedUrlsPaginationResponse';

const getDefaultSort = (): TableSortOption => ({
	dataField: 'status',
	order: SORT_ORDER.ASC
});

const getColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'url',
			text: 'Page',
			style: { width: '500px', maxWidth: '500px' },
			headerStyle: { width: '500px' },
			sort: true,
			formatter: (url: string) => {
				return <UrlCellFormatter url={url} />;
			}
		},
		{
			dataField: 'status',
			text: 'Status',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: (status: PageResultState) => {
				if (status === PageResultState.SUCCESS) {
					return 'Success';
				}
				if (status === PageResultState.FAILED) {
					return 'Failed';
				}
				if (status === PageResultState.FILTERED_OUT) {
					return 'Duplicate';
				}
				return status;
			}
		},
		{
			dataField: 'finalUrl',
			text: 'Details',
			style: { width: '500px' },
			headerStyle: { width: '500px' },
			sort: true,
			formatter: (url: string) => {
				return <UrlStatusDetailsFormatter url={url} />;
			}
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Scanned URLs Information',
		caption: 'Scanned URLs table',
		pagination: true,
		remote: true,
		dataType: 'urls',
		defaultSorted: [getDefaultSort()]
	};
};

type PagesTableRowData = {
	id: string;
	url: string;
	status: PageResultState;
	finalUrl: string;
};

const getTableData = (pages: RequestedUrl[]): PagesTableRowData[] => {
	return pages.map((p) => ({
		id: p.url,
		url: p.url,
		status: p.status,
		finalUrl: p.finalUrl !== p.url ? p.finalUrl : ''
	}));
};

const getTableDefinition = (
	pages: RequestedUrl[],
	totalCount: number,
	totalPaginationableResults: number
): ScanTableDefinition => {
	const result: ScanTableDefinition = {
		columns: getColumns(),
		options: getOptions(),
		data: getTableData(pages),
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition, getDefaultSort };
