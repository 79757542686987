import {
	BUTTON_TYPES,
	EvSection,
	EvTextInput,
	OptionType,
	TOAST_STATUS
} from '@evinced-private/ui-common';
import React, { FC, useEffect, useRef, useState } from 'react';
import UUIDHelper from '../../helpers/UUIDHelper';
import urlDiscoveryScopeHelper from '../../helpers/UrlDiscoveryScopeHelper';
import { IPropertyView } from '../../interfaces/PropertyViews';
import { IScopeRuleUIFormat } from '../../interfaces/ScopeRuleUIFormat';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import Logger from '../../services/Logger';
import PropertyViewsService from '../../services/PropertyViewsService';
import SCOPE_TYPES from '../../types/scope/ScopeTypes';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import './EditViewButton.scss';
import {
	buildRulesList,
	createDefaultViewsRulesList,
	editExistingView,
	saveNewView
} from './EditViewButtonHelper';
import ScopeHeader from './scope/scope-header/ScopeHeader';
import ScopeRulesList from './scope/scope-rules-list/ScopeRulesList';

const ACTIONS_LIST: SCOPE_TYPES[] = [SCOPE_TYPES.URL, SCOPE_TYPES.ISSUE_TYPES];

interface IEditViewButtonProps {
	propertyId: string;
	innerText: string;
	type: BUTTON_TYPES;
	view?: IPropertyView;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	accessibilityProps?: any;
}

const EditViewButton: FC<IEditViewButtonProps> = ({
	propertyId,
	innerText,
	type,
	accessibilityProps,
	view = null
}) => {
	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const notificationsContext: INotificationsContext = useNotifications();

	const [viewName, setViewName] = useState<string>('');
	const [rulesList, setRulesList] = useState<IScopeRuleUIFormat[]>();
	const [issueTypesList, setIssueTypesList] = useState<OptionType[]>([]);

	const defaultRulesList = useRef<IScopeRuleUIFormat[]>(null);
	const ARIA_LABEL_ID: string = useRef<string>(
		`view-name-input-field-${UUIDHelper.generateUUID()}`
	).current;

	const addScopeRule = (scopeType: SCOPE_TYPES): void => {
		const newRule: IScopeRuleUIFormat = urlDiscoveryScopeHelper.createDefaultRuleInUIFormat(
			scopeType,
			issueTypesList
		);
		setRulesList((currentRulesList) => [...currentRulesList, newRule]);
	};

	const removeScopeRule = (id: string): void => {
		setRulesList((currentRulesList) => [...currentRulesList.filter((rule) => rule.id !== id)]);
	};

	const onRuleChange = (updatedRule: IScopeRuleUIFormat): void => {
		const rulesListCopy = [...rulesList];
		const ruleIndex = rulesListCopy.findIndex((rule) => rule.id === updatedRule.id);

		if (ruleIndex !== -1) {
			rulesListCopy[ruleIndex] = updatedRule;
			setRulesList(rulesListCopy);
		}
	};

	const onSaveSuccess = (): void => {
		properyViewContext.onViewsChange();
		notificationsContext.toast({
			show: true,
			status: TOAST_STATUS.SUCCESS,
			announcement: 'View saved.'
		});
	};

	const onSaveFailure = (errorMsg: string): void => {
		notificationsContext.toast({
			show: true,
			status: TOAST_STATUS.FAIL,
			announcement: `Failed to save view. ${errorMsg}`
		});
	};

	const addView = async (): Promise<void> => {
		try {
			await saveNewView({ name: viewName, propertyId, rulesList });
			onSaveSuccess();
		} catch (err) {
			onSaveFailure(err.error.message);
			Logger.error('Failed to create new view', err);
		}
	};

	const editView = async (): Promise<void> => {
		try {
			await editExistingView({ name: viewName, id: view.id, rulesList, propertyId });
			onSaveSuccess();
		} catch (err) {
			onSaveFailure(err.error.message);
			Logger.error('Failed to edit view', err);
		}
	};

	const isViewValid = (): boolean => {
		const rulesNotEmpty: boolean = rulesList?.some(
			(rule: IScopeRuleUIFormat) => rule.url.trim() || rule.issueTypes?.length
		);
		return Boolean(viewName.trim() && rulesNotEmpty);
	};

	const onPopupOpen = async (): Promise<void> => {
		notificationsContext.toast({ show: false });

		if (view) {
			const viewFilters = view.configuration.filters;
			setViewName(view.name);
			setRulesList(viewFilters.length ? buildRulesList(viewFilters) : defaultRulesList?.current);
		}
	};

	const onClosePopup = (onClose): void => {
		setViewName('');
		setRulesList(defaultRulesList?.current);
		if (onClose) {
			onClose();
		}
	};

	const buttons = [
		{
			title: 'Cancel',
			type: BUTTON_TYPES.SECONDARY,
			onClick: (onClose) => {
				onClose();
			}
		},
		{
			title: view ? 'Save' : 'Create',
			disabled: !isViewValid(),
			onClick: (onClose) => {
				if (view) {
					editView();
				} else {
					addView();
				}
				onClose();
			}
		}
	];

	useEffect(() => {
		const fetchIssueTypesList = async (): Promise<void> => {
			try {
				const issueTypesList: string[] = await PropertyViewsService.getIssueTypesList();
				const issuesList: OptionType[] = issueTypesList.map((issueType) => {
					return { label: issueType, value: issueType };
				});
				defaultRulesList.current = createDefaultViewsRulesList(issuesList);
				setIssueTypesList(issuesList);
				setRulesList(defaultRulesList.current);
			} catch (err) {
				notificationsContext.toast({
					show: true,
					status: TOAST_STATUS.FAIL,
					announcement: 'Couldn’t load issues list.'
				});
				Logger.error('Couldn’t load issue list', err);
			}
		};

		fetchIssueTypesList();
	}, [notificationsContext]);

	const title = `${view ? 'Edit' : 'Create'} View`;

	return (
		<SiteScannerPopup
			className="edit-view-button"
			buttons={buttons}
			isCentered
			isBgTransparent
			title={title}
			onOpen={onPopupOpen}
			onClose={onClosePopup}
			triggerButtonProps={{
				accessibilityProps,
				title,
				type,
				children: innerText
			}}
			popupWidth={600}
		>
			<div className="view-popup-header">
				<label id={ARIA_LABEL_ID} className="view-name-label" aria-hidden>
					View name
				</label>
				<EvTextInput
					ariaLabelledBy={ARIA_LABEL_ID}
					ariaLabel="view-name-input"
					className="view-name-input"
					value={viewName}
					onChange={setViewName}
				/>
			</div>

			<EvSection className="view-popup-scope-section">
				<ScopeHeader onActionBtnClick={addScopeRule} actionsList={ACTIONS_LIST} />
				<div className="view-popup-scope-body">
					<ScopeRulesList
						issueTypesList={issueTypesList}
						rulesList={rulesList}
						onRemove={removeScopeRule}
						onRuleChange={onRuleChange}
					/>
				</div>
			</EvSection>
		</SiteScannerPopup>
	);
};

export default EditViewButton;
