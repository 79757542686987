import {
	EvLinkNavigationTabs,
	EvSection,
	EvSwitch,
	IMenuOption,
	TableSortOption
} from '@evinced-private/ui-common';
import React, { FC, useCallback, useMemo, useState } from 'react';
import ScanComparisonComponentsTableHelper from '../../../components/scan-comparison-components-table/ScanComparisonComponentsTableHelper';
import ScanComparisonIssuesTableHelper from '../../../components/scan-comparison-issues-table/ScanComparisonIssuesTableHelper';
import TrendsNoDataIndication from '../../../components/trends-no-data-indication/TrendsNoDataIndication';
import { IComponentsPaginationResponse } from '../../../interfaces/ComponentsPaginationResponse';
import { IIssuesPaginationResponse } from '../../../interfaces/IssuesPaginationResponse';
import logger from '../../../services/Logger';
import ScansComparisonService, {
	COMPARISON_TYPE,
	IIssuesOverview
} from '../../../services/scan/ScansComparisonService';
import { FilterParams } from '../../../types/FilterParams';
import { PaginationParams } from '../../../types/PaginationParams';
import ISSUE_COMPARISON_TYPE from '../IssueComparisonType';
import './CompareTablesSection.scss';
import TableWithFilters from './TableWithFilters';

const TYPE_TRANSLATOR = {
	[ISSUE_COMPARISON_TYPE.NEW_ISSUES]: COMPARISON_TYPE.NEW,
	[ISSUE_COMPARISON_TYPE.RECURRING_ISSUES]: COMPARISON_TYPE.RECURRING,
	[ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES]: COMPARISON_TYPE.RESOLVED
};

const getComparisonType = (type: ISSUE_COMPARISON_TYPE): COMPARISON_TYPE => TYPE_TRANSLATOR[type];

interface ICompareTablesSection {
	baseScanId: string;
	comparableScanId: string;
	propertyId: string;
	issuesOverview?: IIssuesOverview;
	commonOnly?: boolean;
	viewId?: string;
}
const CompareTablesSection: FC<ICompareTablesSection> = ({
	baseScanId,
	comparableScanId,
	propertyId,
	issuesOverview,
	commonOnly = false,
	viewId
}) => {
	const [mode, setMode] = useState<ISSUE_COMPARISON_TYPE>(ISSUE_COMPARISON_TYPE.NEW_ISSUES);
	const [groupByComponent, setGroupByComponent] = useState(false);
	const [isTableLoading, setTableLoading] = useState(true);
	const [comparisonComponentsResponse, setComparisonComponentsResponse] =
		useState<IComponentsPaginationResponse>(null);
	const [comparisonIssuesResponse, setComparisonIssuesResponse] =
		useState<IIssuesPaginationResponse>(null);
	const [error, setError] = useState(false);

	const filtersId = useMemo(() => {
		return `scan-compare-${baseScanId}-${comparableScanId}-view-${viewId}`;
	}, [baseScanId, comparableScanId, viewId]);

	const tableId = useMemo(() => {
		return `scan-compare-${baseScanId}-${comparableScanId}-${mode}-${
			groupByComponent ? '-components' : '-issues'
		}`;
	}, [baseScanId, comparableScanId, groupByComponent, mode]);

	const comparisonOptions: IMenuOption[] = useMemo(
		() => [
			{
				onClick: () => setMode(ISSUE_COMPARISON_TYPE.NEW_ISSUES),
				title: ISSUE_COMPARISON_TYPE.NEW_ISSUES,
				id: ISSUE_COMPARISON_TYPE.NEW_ISSUES
			},
			{
				onClick: () => setMode(ISSUE_COMPARISON_TYPE.RECURRING_ISSUES),
				title: ISSUE_COMPARISON_TYPE.RECURRING_ISSUES,
				id: ISSUE_COMPARISON_TYPE.RECURRING_ISSUES
			},
			{
				onClick: () => setMode(ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES),
				title: ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES,
				id: ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES
			}
		],
		[]
	);

	const defaultSort = useMemo((): TableSortOption => {
		return groupByComponent
			? ScanComparisonComponentsTableHelper.getDefaultSort()
			: ScanComparisonIssuesTableHelper.getDefaultSort();
	}, [groupByComponent]);

	/**
	 * Loads all scans and sets the initlal selected dropdown values.
	 */
	const loadDataForTable = useCallback(
		async (
			baseScanId: string,
			comparableScanId: string,
			paginationParams: PaginationParams,
			filtersParams: FilterParams
		): Promise<void> => {
			try {
				setTableLoading(true);
				if (groupByComponent) {
					const response = await ScansComparisonService.getScanComparisonComponents(
						baseScanId,
						comparableScanId,
						getComparisonType(mode),
						commonOnly,
						paginationParams,
						filtersParams,
						viewId
					);
					setComparisonComponentsResponse(response);
				} else {
					const response = await ScansComparisonService.getScanComparisonIssues(
						baseScanId,
						comparableScanId,
						getComparisonType(mode),
						commonOnly,
						paginationParams,
						filtersParams,
						viewId
					);
					setComparisonIssuesResponse(response);
				}
				setTableLoading(false);
			} catch (err) {
				setError(true);
				logger.error(
					`Scan comparison page - error loading issues for base: ${baseScanId}, comparable: ${comparableScanId}, type: ${mode}`,
					err
				);
			}
		},
		[mode, commonOnly, groupByComponent, viewId]
	);

	const totalCountWithoutFilter: number = useMemo(() => {
		if (!issuesOverview) {
			return 0;
		}
		const { recurring, resolved } = issuesOverview;
		const newIssues = issuesOverview.new;
		let total: number;
		switch (mode) {
			case ISSUE_COMPARISON_TYPE.NEW_ISSUES: {
				total = groupByComponent ? newIssues.componentCount : newIssues.issueCount;
				break;
			}
			case ISSUE_COMPARISON_TYPE.RECURRING_ISSUES: {
				total = groupByComponent ? recurring.componentCount : recurring.issueCount;
				break;
			}
			case ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES: {
				total = groupByComponent ? resolved.componentCount : resolved.issueCount;
				break;
			}
			default: {
				return 0;
			}
		}
		return total;
	}, [groupByComponent, issuesOverview, mode]);

	const toggleButtonGroupByComponent = useMemo((): JSX.Element => {
		return (
			<div className="group-by-component">
				<EvSwitch
					label="Group by components"
					checked={groupByComponent}
					onChange={() => setGroupByComponent((previous) => !previous)}
					disabled={isTableLoading}
				/>
			</div>
		);
	}, [groupByComponent, isTableLoading]);

	const tableDefinition = useMemo(() => {
		if (groupByComponent) {
			return (
				comparisonComponentsResponse &&
				ScanComparisonComponentsTableHelper.getTableDefinition(
					comparisonComponentsResponse.components,
					propertyId,
					mode === ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES ? baseScanId : comparableScanId,
					totalCountWithoutFilter,
					comparisonComponentsResponse.totalResults
				)
			);
		}
		return (
			comparisonIssuesResponse &&
			ScanComparisonIssuesTableHelper.getTableDefinition(
				comparisonIssuesResponse.issues,
				totalCountWithoutFilter,
				comparisonIssuesResponse.totalResults
			)
		);
	}, [
		baseScanId,
		comparableScanId,
		comparisonComponentsResponse,
		comparisonIssuesResponse,
		groupByComponent,
		mode,
		propertyId,
		totalCountWithoutFilter
	]);

	if (error) {
		return (
			<div className="page-content">
				<TrendsNoDataIndication />
			</div>
		);
	}

	return (
		<TableWithFilters
			className="compare-tables-section"
			mainArea={
				// eslint-disable-next-line react/jsx-wrap-multilines
				<EvSection
					ariaLabel="Comparison types"
					skipLinkId="scan-comparison-types"
					className="scan-comparison-tabs"
				>
					<EvLinkNavigationTabs options={comparisonOptions} selectedTabId={mode} />
				</EvSection>
			}
			componentNextToFilters={toggleButtonGroupByComponent}
			defaultTableSort={defaultSort}
			tableId={tableId}
			filtersId={filtersId}
			baseScanId={baseScanId}
			comparableScanId={comparableScanId}
			tableDefinition={tableDefinition}
			loadTableItems={loadDataForTable}
			tableWithUrlSwitcher={true}
		/>
	);
};

export default CompareTablesSection;
