import { BUTTON_TYPES, DropdownOptionType, IDropdownMenuOption } from '@evinced-private/ui-common';
import {
	ALL_CONTENT_VIEW,
	ALL_CONTENT_VIEW_ID
} from '../components/common/ev-property-views/consts/PropertyViews';
import { IPropertyView, ISelectedViewResponse } from '../interfaces/PropertyViews';
import PropertyViewsService from '../services/PropertyViewsService';
import RoutesHelper from './RoutesHelper';

const getViewIdFromParams = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get('viewId');
};

const findViewInViewsList = (viewsList: IPropertyView[], viewId: string): IPropertyView =>
	viewsList.find((view) => view.id === viewId);

export const getCurrentView = (
	location,
	propertyViews: IPropertyView[],
	savedSelectedView: IPropertyView
): [IPropertyView, boolean] => {
	let currentView: IPropertyView;
	let invalidView = false;

	// 1. Get View from URL
	const viewId = getViewIdFromParams(location);

	if (viewId) {
		currentView = findViewInViewsList(propertyViews, viewId);
		if (!currentView && viewId === ALL_CONTENT_VIEW_ID) {
			currentView = ALL_CONTENT_VIEW;
		}
		invalidView = !currentView;
	}

	// 2. No permissions for selectedView - get user-selected-view
	if (!currentView && savedSelectedView?.id && savedSelectedView?.id !== ALL_CONTENT_VIEW_ID) {
		currentView = findViewInViewsList(propertyViews, savedSelectedView.id);
		invalidView = invalidView || !currentView;
	}

	// 3. No user-selected-view - display All Content
	if (!currentView) {
		currentView = ALL_CONTENT_VIEW;
	}

	return [currentView, invalidView];
};

export const getSearchParams = (location, viewId: string = null): string => {
	const params = location.search;
	const urlParams = new URLSearchParams(params);

	if (viewId) {
		urlParams.set('viewId', viewId);
	} else {
		urlParams.delete('viewId');
	}
	return urlParams ? `${urlParams.toString()}` : '';
};

const getCurrentUrlWithViewSearchParams = (location, viewId: string = null): string => {
	const url: string = location.pathname;
	const searchParams = getSearchParams(location, viewId);

	return `${url}${searchParams.length > 0 ? `?${searchParams}` : ''}`;
};

const getViewsOptionsList = (
	views: IPropertyView[],
	onOptionChange: (view: IPropertyView) => void
): IDropdownMenuOption[] => {
	const viewsOptions: IDropdownMenuOption[] = views.map((view) => ({
		key: `${view.id}${view.name}`,
		id: view.id,
		type: DropdownOptionType.BUTTON,
		title: view.name,
		buttonProps: {
			title: view.name,
			type: BUTTON_TYPES.ICON,
			children: view.name,
			onClick: () => {
				onOptionChange(view);
			}
		}
	}));
	return viewsOptions;
};

export const getSelectedViewIdFromServer = async (propertyId: string): Promise<string> => {
	const selectedView: ISelectedViewResponse[] = await PropertyViewsService.getSelectedView(
		propertyId
	);

	// No Selected View => All Content
	if (!selectedView.length || !selectedView[0].viewId) {
		return ALL_CONTENT_VIEW.id;
	}
	return selectedView[0].viewId;
};

export const createOrUpdateSelectedView = async (
	propertyId: string,
	selectedViewId: string
): Promise<ISelectedViewResponse> => {
	if (selectedViewId === ALL_CONTENT_VIEW_ID) {
		selectedViewId = null;
	}

	const selectedView: ISelectedViewResponse[] = await PropertyViewsService.getSelectedView(
		propertyId
	);

	// Empty Array - Call POST
	if (!selectedView.length) {
		return PropertyViewsService.createSelectedView(propertyId, selectedViewId);
	}
	// Not Empty - call PUT
	const { id }: ISelectedViewResponse = selectedView[0];
	return PropertyViewsService.updateSelectedView(propertyId, selectedViewId, id);
};

// TODO: once task is done and working - remove getUrlWithViewId (And rename to getUrlWithViewId )
export const generateUrlWithViewId = (url: string, viewId: string): string => {
	if (viewId === ALL_CONTENT_VIEW_ID) {
		return url;
	}
	const append: string = url.includes('?') ? '&' : '?';
	const viewIdParam: string = RoutesHelper.getViewIdParam(viewId, append);
	return `${url}${viewIdParam}`;
};

export default {
	getViewIdFromParams,
	getCurrentView,
	getCurrentUrlWithViewSearchParams,
	getViewsOptionsList,
	generateUrlWithViewId
};
