import { EVINCED_SEVERITIES, SORT_ORDER } from '@evinced-private/ui-common';

const severitiesOrder = [
	EVINCED_SEVERITIES.CRITICAL,
	EVINCED_SEVERITIES.SERIOUS,
	EVINCED_SEVERITIES.MODERATE,
	EVINCED_SEVERITIES.MINOR,
	EVINCED_SEVERITIES.NEEDS_REVIEW,
	EVINCED_SEVERITIES.BEST_PRACTICE
];

const sortSeverities = (severities: string[]): string[] => {
	const newSeveritites = [];
	// add all known severities by order
	severitiesOrder.forEach((severity) => {
		if (severities.includes(severity)) {
			newSeveritites.push(severity);
		}
	});
	// add all unknown severities in the end
	severities.forEach((severity) => {
		if (!newSeveritites.includes(severity)) {
			newSeveritites.push(severity);
		}
	});
	return newSeveritites;
};

const sortBySeverity = (a: string, b: string, order: SORT_ORDER): number => {
	const aSeverityIndex = severitiesOrder.findIndex((severity) => severity === a);
	const bSeverityIndex = severitiesOrder.findIndex((severity) => severity === b);

	if (order === SORT_ORDER.ASC) {
		return bSeverityIndex - aSeverityIndex;
	}
	return aSeverityIndex - bSeverityIndex;
};

export default {
	sortSeverities,
	sortBySeverity
};
