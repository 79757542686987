import { SORT_ORDER } from '@evinced-private/ui-common';
import { IScan } from '../../interfaces/Scan';
import { TScore } from '../../interfaces/ScanScore';
import SCAN_STATE from '../../interfaces/ScanState';
import { PROPERTIES_INFO_TYPES, SCORE_LEVELS } from './DashboardConsts';

export type TAverageScoreKpi = TScore;

export type TTenantCriticalIssuesKpi = {
	totalIssues: {
		totalIssuesCount: number;
		lift?: number;
		liftPercentage?: number;
	};
	criticalIssues: {
		totalCriticalIssuesCount: number;
		lift?: number;
		liftPercentage?: number;
	};
};

export type TPagesMonitoredKpi = {
	lift: number;
	liftPercentage: number;
	totalPages: number;
};

type TPropertyView = {
	id: string;
	name: string;
};

type TProperty = {
	id: string;
	name: string;
	views?: TPropertyView[];
	lastSuccessfulScan?: IScan;
};

export type TActiveProperty = {
	property: TProperty;
	view?: TPropertyView;
	score?: TScore;
	scoreResult?: TScore;
	criticalIssuesCount?: number;
	pagesCount?: number;
};

export type TActiveProperties = {
	properties: TActiveProperty[];
};

export type TActivePropertiesList = TActiveProperty[];

export enum COMPARE_TYPE {
	COMPARE_LAST_SCANS = 'COMPARE_LAST_SCANS',
	COMPARE_DATE_RANGE = 'COMPARE_DATE_RANGE'
}

export const COMPARE_TYPE_OPTIONS_LABELS = {
	[COMPARE_TYPE.COMPARE_LAST_SCANS]: 'Previous Scans',
	[COMPARE_TYPE.COMPARE_DATE_RANGE]: 'Previous Month'
};

export const COMPARE_TYPE_AVG_SCORE_KPI_TOOLTIP = {
	[COMPARE_TYPE.COMPARE_LAST_SCANS]:
		'The average percentage change in scores across all active properties for their latest scans, compared to their previous scans within the last 90 days.',
	[COMPARE_TYPE.COMPARE_DATE_RANGE]:
		'The average percentage change in scores across all active properties for their latest scans, compared to the scans from at least 30 days prior, within the last 90 days.'
};

export const DEFAULT_COMPARE_TYPE = COMPARE_TYPE.COMPARE_LAST_SCANS;

export type TActivePropertyDataParams = {
	views?: boolean;
	sortField?: PROPERTIES_INFO_TYPES;
	sortDirection?: SORT_ORDER;
	pageNumber?: number;
	pageSize?: number;
	compareType?: COMPARE_TYPE;
	collectionIds?: string[];
	showOnlyPropertiesInMyCollection?: boolean;
};

export type TGroupedProperty = { [key in SCORE_LEVELS]: TActiveProperty[] };

export type TSortOption = {
	sortBy: PROPERTIES_INFO_TYPES;
	orderBy: SORT_ORDER;
	value: string;
	label: string;
};

export type TPropertiesInfoSortOptions = TSortOption[];

export type TQuickWin = {
	property: TProperty;
	criticalIssuesPercentage: number;
	componentsCount: number;
	screenshot: {
		pageScreenshotUrl: string;
		componentBoundingBox?: number[];
	};
};

export type TQuickWins = TQuickWin[];

export type TScansParams = {
	states?: SCAN_STATE[];
	propertyId?: string;
	viewId?: string;
	collectionIds?: string[];
	showOnlyPropertiesInMyCollection?: boolean;
};

export type TView = {
	id: string;
	name: string;
	propertyId: string;
	propertyName: string;
};

export type TScansWithStatus = { scans: IScan[]; failed?: boolean };
