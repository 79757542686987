import React, { FC } from 'react';
import LoadErrorMsgLayout from '../../../pages/property/LoadErrorMsgLayout';
import ErrorLoadPageIcon from '../../icons/ErrorLoadPageIcon.svg';
import EvPropertyViews from './EvPropertyViews';
import './PropertyViewLoadErrorPage.scss';

const mainTitle = 'The selected view isn’t available';
const secondaryMsg = 'Try switching to a different view.';

interface IPropertyViewLoadErrorPage {
	propertyId: string;
}

const PropertyViewLoadErrorPage: FC<IPropertyViewLoadErrorPage> = ({ propertyId }) => {
	return (
		<div className="wrapper">
			<div className="header">
				<EvPropertyViews propertyId={propertyId} />
			</div>
			<LoadErrorMsgLayout
				mainTitle={mainTitle}
				secondaryMsg={secondaryMsg}
				icon={ErrorLoadPageIcon}
			/>
		</div>
	);
};

export default PropertyViewLoadErrorPage;
