import { EvLink, IEvLinkProps } from '@evinced-private/ui-common';
import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { usePageScrollPositionState } from '../../../providers/PageScrollPositionProvider';

interface IEvLinkLocal extends IEvLinkProps {
	pageId?: string;
	pagePosition?: number;
	id?: string;
	customLink?: JSX.Element;
}
const EvLinkLocal: FC<IEvLinkLocal> = (props: IEvLinkLocal) => {
	const history = useHistory();
	const { setLastClickedElementScrollPosition } = usePageScrollPositionState();
	const evLinkRef = useRef<HTMLElement>();

	return (
		<EvLink
			{...props}
			refs={evLinkRef}
			id={props.id}
			onClick={() => {
				history.push(props.url);
				if (props.pageId) {
					setLastClickedElementScrollPosition(props.pageId, `[id="${props.id}"]`);
				}
			}}
		/>
	);
};
export default EvLinkLocal;
