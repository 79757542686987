const GA_CATEGORIES = {
	PROPERTIES: 'properties',
	SCANS: 'scans',
	CRAWLS: 'crawl'
};

const GA_ACTIONS = {
	CREATED: 'created',
	UPDATED: 'updated'
};

export default {
	GA_CATEGORIES,
	GA_ACTIONS
};
