export default {
	WRONG_SCREEN_READING_ORDER: 'Screen Reading Order',
	WRONG_TAB_ORDER: 'Tab Order',
	BROKEN_SKIP_LINK: 'Broken Skip links',
	FOCUS_INDICATION: 'Focus Indication',
	WRONG_EXPANDED_INDICATION: 'Relationship',
	NO_INVISIBLE_INDICATION: 'Off-Screen Elements',
	NOT_FOCUSABLE: 'Keyboard Accessible',
	NO_DESCRIPTIVE_TEXT: 'Accessible Name',
	WRONG_SEMANTIC_ROLE: 'Interactable Role',
	MODAL_LAUNCHER_WRONG_SEMANTIC_ROLE: 'Modal Launcher Interactable Role',
	MODAL_WRONG_SEMANTIC_ROLE: 'Modal Interactable Role',
	MODAL_NO_DESCRIPTIVE_TEXT: 'Modal Accessible Name',
	MODAL_LAUNCHER_NO_DESCRIPTIVE_TEXT: 'Modal Launcher Accessible Name',
	MODAL_LAUNCHER_NOT_FOCUSABLE: 'Modal Launcher Keyboard Accessible',
	MISSING_SEMANTICS_HEADER: 'Missing Header Semantics',
	MISSING_SEMANTICS_FOOTER: 'Missing Footer Semantics',
	MISSING_FOCUS_INDICATION: 'Missing Focus Indication',
	REDUNDANT_HEADING: 'Heading Wrong Semantic Role',
	HEADING_WRONG_SEMANTIC_ROLE: 'Heading Wrong Semantic Role',
	NOT_MEANINGFUL_PAGE_TITLE: 'Not Meaningful Page Title',
	LONG_ALT_TEXT: 'Alt Text Is Too Long',
	LOCKED_TEXT_SPACING: 'Locked Text Spacing',
	LANGUAGE_ATTRIBUTE_MISMATCH: 'Language Attribute Mismatch',
	TEXT_CONTRAST_ON_COMPOUND_BACKGROUND: 'Text On Compound Background'
};
