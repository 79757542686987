import { EvErrorCard } from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import IssueDetailsBlock from '../issue-details-block/IssueDetailsBlock';
import './IssueDetailsPopup.scss';

interface IIssueDetailsPopupProps {
	issueId: string;
	scanId?: string;
	onClose?: () => void;
}
const enableAzureIntegration = TogglesService.getToggle(
	DevelopmentToggles.ENABLE_AZURE_INTEGRATION
);

const IssueDetailsPopup: FC<IIssueDetailsPopupProps> = ({
	issueId,
	onClose,
	scanId
}: IIssueDetailsPopupProps) => {
	const [error, setError] = useState(null);
	const [title, setTitle] = useState(null);
	const classes: string = classNames(error ? 'error-popup' : 'issue-details-popup', {
		'azure-popup': enableAzureIntegration
	});

	const issueDetailsBlockComponent = useMemo((): JSX.Element => {
		return (
			<IssueDetailsBlock
				issueId={issueId}
				scanId={scanId}
				setError={setError}
				setTitle={setTitle}
			/>
		);
	}, [scanId, issueId]);

	return (
		<div>
			<SiteScannerPopup
				isOpen
				title={error ? 'Error' : title}
				className={classes}
				isBgTransparent
				isCentered={!!error}
				isControlled
				onClose={() => {
					setError(false);
					onClose();
				}}
				popupWidth="unset"
			>
				{error ? (
					<EvErrorCard title="Error" contactUsMessage={error?.error?.message} />
				) : (
					issueDetailsBlockComponent
				)}
			</SiteScannerPopup>
		</div>
	);
};

export default IssueDetailsPopup;
