// This file contains the patterns definitions that are used in all Nivo charts
// Check out the Nivo patterns guide to learn more https://nivo.rocks/guides/patterns

import { EvincedColors } from '@evinced-private/ui-common';

const {
	criticalColor,
	seriousColor,
	moderateColor,
	minorColor,
	needsReviewColor,
	newIssuesColor,
	bestPracticeColor,
	baselineColor,
	reocurringColor,
	newSeverityColors
} = EvincedColors;

const resolvedColor = '#26bfb5';

const defaultTheme = {
	textColor: '#607085',
	axis: {
		ticks: {
			text: {
				fontSize: 13,
				fill: '#607085'
			}
		}
	},
	grid: {
		line: {
			stroke: 'black',
			strokeWidth: 0.5
		}
	}
};

export default {
	resolvedColor,
	criticalColor,
	seriousColor,
	moderateColor,
	minorColor,
	needsReviewColor,
	newIssuesColor,
	baselineColor,
	bestPracticeColor,
	reocurringColor,
	defaultTheme,
	newSeverityColors
};
