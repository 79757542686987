import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import FileHelper from '../helpers/FilesHelper';
import RoutesHelper from '../helpers/RoutesHelper';
import { IServerError } from '../interfaces/ServerError';
import authenticationService from '../services/AuthenticationService';
import Logger from '../services/Logger';

function apiCall(
	method: Method,
	url: string,
	options?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data?: any;
		isAbsoluteUrl?: boolean;
		noExtraHeaders?: boolean;
		isFile?: boolean;
		/**
		 * when true we won't send the authentication headers
		 */
		publicAPI?: boolean;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
	const checkStatus = (response): AxiosResponse => {
		// Raises an error in case response status is not a success
		if (response.status >= 200 && response.status < 300) {
			// Success status lies between 200 to 300
			return response;
		}

		const errorObject: IServerError = {
			error: new Error(response.statusText),
			response
		};
		throw errorObject;
	};

	const cleanError = (
		error: AxiosError<{ message?: string }>
	): AxiosError<{ message?: string }> => {
		delete error?.config?.headers;
		delete error?.response?.headers;
		return error;
	};

	const checkError = async (error: AxiosError): Promise<void> => {
		const errorWithoutAuthDetails = cleanError(error);
		Logger.error('Error from server', errorWithoutAuthDetails);
		// Log user out in case the API returned unauthorized (401)
		if (error.response && error.response.status === 401) {
			await authenticationService.logout();
		} else if (error.response && error.response.status === 403) {
			// redirect occurs. flow ends here
			window.location.href = RoutesHelper.getUnauthorized403Page();
		} else {
			// throws error
			const errorObject: IServerError = {
				error: new Error(
					errorWithoutAuthDetails?.response?.data
						? errorWithoutAuthDetails.response.data.message
						: errorWithoutAuthDetails.message
				),
				response: errorWithoutAuthDetails.response
			};
			throw errorObject;
		}
	};

	const downloadFile = (response): void => {
		// eslint-disable-next-line prefer-regex-literals
		const filenameRegex = new RegExp('filename="(.*)"');
		const contentDisposition = response.headers['content-disposition'];
		const matches = contentDisposition.match(filenameRegex);

		FileHelper.createFileAndMakeBrowserDownloadIt(matches[1], response.data);
	};

	// Performs api calls sending the required authentication headers
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let headers: any = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-ID': 'UI' // custom field to know a request was generated by ui
	};

	// Setting Authorization header if needed
	// Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
	if (!options?.publicAPI) {
		headers.Authorization = `Bearer ${authenticationService.getAccessToken()}`;
	}

	// support case where the URL is not under /api
	const baseURL = options && options.isAbsoluteUrl ? '' : process.env.API_URL;

	// support case where there shouldn't be authentication and content type headers
	headers = options && options.noExtraHeaders ? {} : headers;
	const data = options && options.data ? options.data : null;

	return axios({
		method,
		url,
		baseURL,
		headers,
		data
	})
		.then(checkStatus)
		.then((response) => {
			if (options && options.isFile) {
				downloadFile(response);
			}
			return response.data;
		})
		.catch(checkError);
}

export default { apiCall };
