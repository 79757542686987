import URL_SET_SOURCES from '../types/UrlSetSources';

const UPLOAD_DISPLAY_TEXT = 'List';
const DISCOVERY_DISPLAY_TEXT = 'Crawl';

const isListMode = (source: URL_SET_SOURCES): boolean => {
	return source === URL_SET_SOURCES.UPLOAD;
};

const getSourceDisplayText = (
	source: URL_SET_SOURCES
): {
	currentMode: string;
	otherMode: string;
} => {
	switch (source) {
		case URL_SET_SOURCES.UPLOAD:
			return {
				currentMode: UPLOAD_DISPLAY_TEXT,
				otherMode: DISCOVERY_DISPLAY_TEXT
			};
		case URL_SET_SOURCES.URL_DISCOVERY:
			return {
				currentMode: DISCOVERY_DISPLAY_TEXT,
				otherMode: UPLOAD_DISPLAY_TEXT
			};
		default:
			return null;
	}
};

export default {
	isListMode,
	getSourceDisplayText
};
