import { useState, useEffect, useCallback } from 'react';
import { useDashboard } from '../../../../../providers/dashboard/DashboardProvider';
import DashboardHelper from '../../../../../helpers/dashboard/DashboardHelper';
import { TScansWithStatus, TView } from '../../../DashboardTypes';
import { PERFORMANCE_OVER_TIME_CHART_TYPE } from '../../../DashboardConsts';
import { ALL_CONTENT_VIEW_ID } from '../../../../../components/common/ev-property-views/consts/PropertyViews';

interface IPerformanceOverTimeData {
	chartType: string;
	setChartType: (chartType: string) => void;
	scansMap: Map<string, TScansWithStatus>;
	allViewsMap: Map<string, TView>;
	isLoadingScans: boolean;
	errorMessage?: string | null;
}

export const usePerformanceOverTimeData = (
	appliedViewsIdsSet: Set<string>
): IPerformanceOverTimeData => {
	const [chartType, setChartType] = useState<string>(PERFORMANCE_OVER_TIME_CHART_TYPE.SCORE);
	const [allViewsMap, setAllViewsMap] = useState<Map<string, TView>>(new Map());
	const [scansMap, setScansMap] = useState<Map<string, TScansWithStatus>>(new Map());
	const [isLoadingScans, setLoadingScans] = useState<boolean>(true);

	const { activePropertiesList, getScansByPropertyView } = useDashboard();

	const updateAllViewsMap = useCallback(() => {
		if (activePropertiesList) {
			const views = DashboardHelper.getViewsFromProperties(activePropertiesList);
			setAllViewsMap(views);
		}
	}, [activePropertiesList]);

	useEffect(() => {
		updateAllViewsMap();
	}, [updateAllViewsMap]);

	const removeUnappliedScans = useCallback(() => {
		setScansMap((prevState) => {
			const newScansMap = new Map(prevState);
			prevState.forEach((scans, key) => {
				if (!appliedViewsIdsSet.has(key)) {
					newScansMap.delete(key);
				}
			});
			return newScansMap;
		});
	}, [appliedViewsIdsSet]);

	useEffect(() => {
		removeUnappliedScans();
	}, [removeUnappliedScans]);

	// fetch scans of selected views and update scans map
	const updateScansMap = useCallback(
		async (viewsParamsToFetch) => {
			setLoadingScans(true);
			const promises = viewsParamsToFetch?.map((viewParams) => {
				const viewId = viewParams.view?.id || ALL_CONTENT_VIEW_ID;
				return getScansByPropertyView({
					viewId,
					propertyId: viewParams.property?.id
				});
			});

			const multiScans: TScansWithStatus[] = await Promise.all(promises);

			setScansMap((prevState) => {
				const newScansMap = new Map(prevState);
				multiScans.forEach((item, index) => {
					const key = viewsParamsToFetch[index]?.key;
					if (key) {
						newScansMap.set(key, item);
					}
				});
				return newScansMap;
			});
			setLoadingScans(false);
		},
		[getScansByPropertyView]
	);

	const prepareToFetchScans = useCallback(() => {
		if (allViewsMap.size) {
			const viewsParamsToFetch = [];
			appliedViewsIdsSet.forEach((key) => {
				// if view scans are not fetched yet - add params for fetching
				if (!scansMap.has(key)) {
					const viewData = allViewsMap.get(key as string);
					let viewId = viewData?.id;
					if ((viewId as string)?.startsWith(`${ALL_CONTENT_VIEW_ID} `)) {
						viewId = ALL_CONTENT_VIEW_ID;
					}
					viewsParamsToFetch.push({
						key,
						property: {
							id: viewData?.propertyId,
							name: viewData?.propertyName
						},
						view: {
							id: viewId,
							name: viewData?.name
						}
					});
				}
			});
			if (viewsParamsToFetch.length) {
				updateScansMap(viewsParamsToFetch);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateScansMap, allViewsMap, appliedViewsIdsSet]);

	useEffect(() => {
		prepareToFetchScans();
	}, [prepareToFetchScans]);

	return {
		chartType,
		setChartType,
		scansMap,
		allViewsMap,
		isLoadingScans
	};
};
