import { BUTTON_TYPES, EvButton, EvConfirm } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SITE_SCANNER_APP_ID } from '../../../consts/dom-consts';
import RoutesHelper from '../../../helpers/RoutesHelper';
import { IProperty } from '../../../interfaces/Property';
import propertiesService from '../../../services/PropertiesService';
import './PropertySettingsActions.scss';

interface IPropertySettingsActionsProps {
	property: IProperty;
	isListMode: boolean;
	isSaveInProgress: boolean;
	onSaveChanges: (shouldCrawl?: boolean) => void;
	disableSaveButton?: boolean;
	showDone?: boolean;
	onDone?: () => void;
}

const PropertySettingsActions: FC<IPropertySettingsActionsProps> = ({
	isListMode,
	property,
	isSaveInProgress,
	onSaveChanges,
	disableSaveButton = false,
	showDone,
	onDone
}: IPropertySettingsActionsProps) => {
	const [isSaveAndCrawlConfirmPopupOpen, setSaveAndCrawlConfirmPopupOpen] = useState(false);
	const history = useHistory();
	const location = useLocation<{ from: string }>();

	const getBackUrl = (): string => {
		if (location?.state) {
			return location.state.from;
		}
		return RoutesHelper.getPropertiesPagePath();
	};

	const onCancel = (): void => {
		history.push(getBackUrl());
	};

	const onSave = (): void => {
		onSaveChanges();
	};

	const onSaveAndCrawlClick = async (): Promise<void> => {
		const isCrawlRunning = await propertiesService.isCrawlCurrentlyRunning(property.id);

		if (isCrawlRunning) {
			setSaveAndCrawlConfirmPopupOpen(true);
		} else {
			onSaveChanges(true);
		}
	};

	const onConfirmSaveAndCrawl = async (closeModal): Promise<void> => {
		onSaveChanges(true);
		setSaveAndCrawlConfirmPopupOpen(false);
		closeModal();
	};

	return (
		<div className="property-settings-actions">
			<EvButton
				type={BUTTON_TYPES.SECONDARY}
				title="Cancel"
				onClick={onCancel}
				className="property-settings-action-button"
			>
				Cancel
			</EvButton>
			{!showDone ? (
				<EvButton
					title="Save"
					loading={isSaveInProgress}
					onClick={onSave}
					disabled={disableSaveButton}
					className="property-settings-action-button"
				>
					Save
				</EvButton>
			) : (
				<EvButton title="Done" onClick={onDone} className="property-settings-action-button">
					Done
				</EvButton>
			)}
			{!isListMode && (
				<>
					<EvButton title="Save & Crawl" loading={isSaveInProgress} onClick={onSaveAndCrawlClick}>
						Save & Crawl
					</EvButton>
					<EvConfirm
						appElement={SITE_SCANNER_APP_ID}
						title="Confirm Save and Crawl"
						approveButtonText="Confirm"
						promptMessage="Saving while a crawl is running will cancel the crawl, are you sure you want to save?"
						isOpen={isSaveAndCrawlConfirmPopupOpen}
						isControlled={true}
						onConfirm={onConfirmSaveAndCrawl}
						onClose={() => setSaveAndCrawlConfirmPopupOpen(false)}
					/>
				</>
			)}
		</div>
	);
};

export default PropertySettingsActions;
