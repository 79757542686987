import _ from 'lodash';
import psl from 'psl';
import defaultGroupingRuleUrl from '../consts/defaultGroupingRuleUrl';
import queryParamsOptions from '../consts/queryParamsOptions';
import { IGroupingRule } from '../interfaces/GroupingRule';
import { IProperty } from '../interfaces/Property';
import { IUrlSeed } from '../interfaces/UrlSeed';
import { IUrlSeedDisplay } from '../interfaces/UrlSeedDisplay';
import LimitsService from '../services/LimitsService';
import Logger from '../services/Logger';
import URL_SET_SOURCES from '../types/UrlSetSources';
import UrlDiscoveryScopeHelper from './UrlDiscoveryScopeHelper';
import UrlNormalizingHelper from './UrlNormalizingHelper';

const PDF_FILE_TYPE = 'application/pdf';

export const newGroupingRule: IGroupingRule = {
	urlPattern: '',
	urlError: '',
	maxSamplesCount: null,
	ignoreQueryParameters: queryParamsOptions.IGNORE_ALL,
	excludedQueryParameters: [],
	ignoreFragment: true,
	caseSensitive: true
};

export default class PropertyHelper {
	public static getPropertyDiscoverySeeds = (property: IProperty): IUrlSeedDisplay[] => {
		const seeds = property?.urlSetConfiguration?.discoveryConfiguration?.seeds || [];
		return seeds.map((seed, index) => ({ url: seed.url, id: index }));
	};

	public static getPropertyListModeSeeds = (property: IProperty): IUrlSeedDisplay[] => {
		return property?.urls?.map((url, index) => ({ url, id: index })) || [];
	};

	public static setPropertyDiscoverySeeds = (property: IProperty, seeds: IUrlSeed[]): IProperty => {
		const propertyClone = { ...property };
		_.merge(propertyClone, {
			urlSetConfiguration: { discoveryConfiguration: {} }
		});
		propertyClone.urlSetConfiguration.discoveryConfiguration.seeds = seeds;
		return propertyClone;
	};

	public static isPropertyEmpty = (property: IProperty, isListMode: boolean): boolean => {
		if (isListMode) {
			return !property.urls || property.urls.length === 0;
		}

		const propertyHasContent =
			PropertyHelper.getPropertyDiscoverySeeds(property).length > 0 ||
			PropertyHelper.getPropertyDiscoveryGroupingRules(property).filter(
				(g) => g.urlPattern !== defaultGroupingRuleUrl
			).length > 0 ||
			UrlDiscoveryScopeHelper.getAllRules(property?.urlSetConfiguration?.discoveryConfiguration)
				.length > 0;

		return !propertyHasContent;
	};

	public static getPropertyDiscoveryGroupingRules = (property: IProperty): IGroupingRule[] => {
		return property?.urlSetConfiguration?.discoveryConfiguration?.groupingRules || [];
	};

	public static setPropertyDiscoveryGroupingRules = (
		property: IProperty,
		rules: IGroupingRule[]
	): IProperty => {
		const propertyClone = { ...property };
		_.merge(propertyClone, {
			urlSetConfiguration: { discoveryConfiguration: {} }
		});
		propertyClone.urlSetConfiguration.discoveryConfiguration.groupingRules = rules;
		return propertyClone;
	};

	public static getMaxPagesCount(property: IProperty): number {
		return property?.urlSetConfiguration?.discoveryConfiguration?.maxPagesCount;
	}

	public static setMaxPagesCount(property: IProperty, maxPagesCount: number): IProperty {
		const propertyClone = { ...property };
		_.merge(propertyClone, {
			urlSetConfiguration: { discoveryConfiguration: {} }
		});
		propertyClone.urlSetConfiguration.discoveryConfiguration.maxPagesCount = maxPagesCount;
		return propertyClone;
	}

	public static isPropertyInDraftMode(propertyId: string): boolean {
		return propertyId === 'new';
	}

	/**
	 *
	 * @param baseUrl - a url entered by the user
	 * returns a list of allowed domains to crawl based on the url
	 */
	public static getDefaultDomains(baseUrl: string): string[] {
		const host = UrlNormalizingHelper.getDomain(baseUrl);
		const domains = [host];
		// return IP as is
		if (UrlNormalizingHelper.isIpAddress(host)) {
			return domains;
		}

		// if schema is included, no need to add subdomains
		if (UrlNormalizingHelper.hasHTTPSchema(baseUrl)) {
			return domains;
		}

		try {
			const parsed = psl.parse(host);
			const domainWithoutSubDomain = parsed.domain;
			const { subdomain } = parsed;
			// adding subdomain in case the user entered only the domain,
			// i.e.: google.com, will return these domains: ['google.com','www.google.com']
			if (!subdomain) {
				domains.push(`www.${domainWithoutSubDomain}`);
			}
			return domains;
		} catch (error) {
			Logger.error(`Error parsing host: ${host}`, error);
			return null;
		}
	}

	public static getDefaultPropertyFromTemplate(baseUrl?: string): IProperty {
		let seeds: IUrlSeed[] = [];
		let name = '';
		let allowedDomains: string[] = [];
		if (baseUrl) {
			seeds = [{ url: UrlNormalizingHelper.normalizeUrlFromUserInput(baseUrl) }];
			name = UrlNormalizingHelper.getDomain(baseUrl);
			allowedDomains = this.getDefaultDomains(baseUrl);
		}
		const { maxPagesCount } = LimitsService.getLimits();

		return {
			name,
			urlSetConfiguration: {
				source: URL_SET_SOURCES.URL_DISCOVERY,
				discoveryConfiguration: {
					seeds,
					domainFilter: {
						allowed: allowedDomains
					},
					allowedContentTypes: ['text/html', 'text/plain'],
					groupingRules: [
						{
							urlPattern: '.*',
							maxSamplesCount: null,
							ignoreQueryParameters: true,
							ignoreFragment: true,
							caseSensitive: true
						}
					],
					languageFilter: {
						allowed: ['.*']
					},
					maxPagesCount
				}
			}
		};
	}

	public static addOrEditPropertyRules = (
		rule: IGroupingRule,
		ruleForEdit: IGroupingRule,
		property: IProperty
	): IProperty => {
		const groupingRules = [...PropertyHelper.getPropertyDiscoveryGroupingRules(property)];
		const isIgnoreQueryParams = rule.ignoreQueryParameters !== queryParamsOptions.INCLUDE_ALL;
		const newRule: IGroupingRule = {
			urlPattern: rule.urlPattern,
			ignoreQueryParameters: isIgnoreQueryParams,
			excludedQueryParameters: [],
			caseSensitive: rule.caseSensitive,
			ignoreFragment: rule.ignoreFragment,
			maxSamplesCount: rule.maxSamplesCount
		};

		if (rule.ignoreQueryParameters === queryParamsOptions.LIST) {
			newRule.excludedQueryParameters = rule.excludedQueryParameters;
		}

		const isEditMode = ruleForEdit && ruleForEdit.urlPattern;

		if (isEditMode) {
			const ruleIndex = groupingRules.findIndex(
				(groupingRule) => groupingRule.urlPattern === ruleForEdit.urlPattern
			);
			groupingRules[ruleIndex] = newRule;
		} else {
			groupingRules.push(newRule);
		}
		return PropertyHelper.setPropertyDiscoveryGroupingRules(property, groupingRules);
	};

	public static ensurePropertyHasDefaultRule = (property: IProperty): IProperty => {
		const newRules = [...PropertyHelper.getPropertyDiscoveryGroupingRules(property)];
		const lastRule = newRules[newRules.length - 1];

		// if last rule is the default rule we can pass
		if (lastRule && lastRule.urlPattern === defaultGroupingRuleUrl) {
			return property;
		}

		let defaultRule = newRules.find((rule) => rule.urlPattern === defaultGroupingRuleUrl);

		// if default rule exists but is not last, make it last
		if (defaultRule) {
			// remove default Rule from list
			property = PropertyHelper.setPropertyDiscoveryGroupingRules(
				property,
				newRules.filter((rule) => rule.urlPattern !== defaultGroupingRuleUrl)
			);
		} else {
			// if default rule doesn't exist create one
			defaultRule = { ...newGroupingRule };
			defaultRule.urlPattern = defaultGroupingRuleUrl;
		}

		const newProperty = PropertyHelper.addOrEditPropertyRules(defaultRule, null, property);
		return newProperty;
	};

	public static isPdfMode = (property: IProperty): boolean => {
		const discoveryConfiguration = property?.urlSetConfiguration?.discoveryConfiguration;
		return (
			discoveryConfiguration?.allowedContentTypes &&
			discoveryConfiguration.allowedContentTypes.includes(PDF_FILE_TYPE)
		);
	};

	public static getSearchParamsByKey = (location, key: string = null): string => {
		const params = location.search;
		const urlParams = new URLSearchParams(params);
		return key ? urlParams.get(key) : null;
	};
}
