import React from 'react';
import FormatHelper from '../FormatHelper';

// renders the yAxis line labels with 10K instead of 10,000
const renderTickInKFormat = (yAxisValues, axisPosition) => (tick) => {
	const { tickIndex, opacity, textAnchor, textBaseline, textX, textY, value, x, y } = tick;
	const axisValueObject = {
		axisLeft: value,
		axisRight: yAxisValues[tickIndex]
	};
	if (!value && value !== 0) {
		return null;
	}
	return (
		<g transform={`translate(${x},${y})`} style={{ opacity }}>
			<text
				alignmentBaseline={textBaseline}
				textAnchor={textAnchor}
				transform={`translate(${textX},${textY})`}
				style={{
					fontSize: 13,
					fill: '#607085',
					fontWeight: 500
				}}
			>
				{FormatHelper.nFormatter(axisValueObject[axisPosition], 2)}
			</text>
		</g>
	);
};

export default { renderTickInKFormat };
