import {
	BUTTON_TYPES,
	EvButton,
	EvSection,
	EvTextInput,
	EvTitle,
	TEXT_INPUT_TYPES
} from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { reorder } from '../../../../helpers/DnDHelper';
import groupingHelper from '../../../../helpers/GroupingHelper';
import propertyHelper from '../../../../helpers/PropertyHelper';
import { IGroupingRule } from '../../../../interfaces/GroupingRule';
import { IProperty } from '../../../../interfaces/Property';
import { IPropertyError } from '../../../../interfaces/PropertyError';
import { SetPropertyType } from '../../../../pages/property-settings/PropertySettingsPageTypes';
import GroupingRulesPopup from '../../../grouping-rules-popup/GroupingRuleLine';
import AddGroupingRulePopup from './AddGroupingRulePopup';
import GroupingRulesList from './GroupingRulesList';
import './GroupingSettings.scss';

const GROUPING_RULES_TITLE = 'grouping-settings-title';
const GROUPING_RULES_DESCRIPTION = 'grouping-settings-description';

interface IGroupingSettingsProps {
	property: IProperty;
	setProperty: SetPropertyType;
	propertyError?: IPropertyError;
	title?: string;
	description?: string;
	showSampleSize?: boolean;
}

const GroupingSettings: FC<IGroupingSettingsProps> = ({
	property,
	setProperty,
	propertyError,
	title = '',
	description = '',
	showSampleSize = true
}: IGroupingSettingsProps) => {
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [currentRuleForEdit, setCurrentRuleForEdit] = useState<IGroupingRule>(null);

	const groupingRules = propertyHelper.getPropertyDiscoveryGroupingRules(property);

	const removeRuleFromProperty = (url): void => {
		const newProperty = groupingHelper.removeRule(property, url);
		setProperty(newProperty);
	};

	const setRuleForEdit = (rule: IGroupingRule): void => {
		setCurrentRuleForEdit(rule);
		setPopupOpen(true);
	};

	const onGroupingRulesUpdated = (newProperty): void => {
		newProperty = propertyHelper.ensurePropertyHasDefaultRule(newProperty);
		setProperty(newProperty);
	};

	const onDragEnd = (rule): void => {
		// dropped outside the list
		if (!rule.destination) {
			return;
		}

		const newRules = reorder(groupingRules, rule.source.index, rule.destination.index);
		const newProperty = propertyHelper.setPropertyDiscoveryGroupingRules(property, newRules);
		setProperty(newProperty);
	};

	const renderAddGroupingRule = (): JSX.Element => {
		return (
			<div className="single-action">
				<EvButton
					title="Add rule"
					onClick={() => {
						setRuleForEdit(null);
					}}
					type={BUTTON_TYPES.ACTION}
				>
					Add rule
				</EvButton>
				<AddGroupingRulePopup
					property={property}
					onGroupingRulesUpdated={onGroupingRulesUpdated}
					ruleForEdit={currentRuleForEdit}
					isOpen={isPopupOpen}
					showSampleSize={showSampleSize}
					onClosePopup={() => {
						setCurrentRuleForEdit(null);
						setPopupOpen(false);
					}}
				/>
			</div>
		);
	};

	const renderMoreInfo = (): JSX.Element => {
		return (
			<>
				Grouping rules serve two main purposes: <br />
				<ol>
					<li>
						Mapping URLs to unique pages. <br />
						For example, do differences in query parameters, case sensitivity etc. map to one page
						or different pages?
					</li>
					<li>
						Sample similar pages to reduce the time/size of the scan. <br />
						For example in e-commerce sites, with hundreds of thousands of pages, it may be enough
						to analyze a smaller sample.
					</li>
				</ol>
			</>
		);
	};

	const renderError = (): JSX.Element => {
		return propertyError && <div className="tab-error">{propertyError.message}</div>;
	};

	const updateMaxPagesCount = (value: number): void => {
		setProperty(propertyHelper.setMaxPagesCount(property, value));
	};

	const renderGlobalMaxPagesRule = (): JSX.Element => {
		const maxPages = propertyHelper.getMaxPagesCount(property);
		const maxPagesLabel = 'Max pages to crawl';
		// TODO: will be added with server support https://evinced.atlassian.net/browse/EP-1232
		// const { maxPagesCount } = LimitsService.getLimits();
		return (
			<div className="max-pages-count-rule">
				<div className="text">{`${maxPagesLabel}:`}</div>
				<EvTextInput
					ariaLabel={maxPagesLabel}
					value={maxPages}
					// maxNumber={maxPagesCount}
					onChange={(value) => {
						updateMaxPagesCount(parseInt(value));
					}}
					type={TEXT_INPUT_TYPES.NUMBER}
					className="max-pages-count-input"
				/>
			</div>
		);
	};
	const renderSectionBody = (): JSX.Element => {
		return (
			<div className="section-body">
				{/* Hide the default rule if there are other rules */}
				{groupingRules?.length > 1 ? (
					<GroupingRulesList
						groupingRules={groupingRules}
						onRemoveRule={removeRuleFromProperty}
						onEditRule={setRuleForEdit}
						onDragEnd={onDragEnd}
					/>
				) : (
					<GroupingRulesPopup property={property} setRuleForEdit={setRuleForEdit} />
				)}
				{showSampleSize && renderGlobalMaxPagesRule()}
				{renderError()}
			</div>
		);
	};

	const renderSectionHeader = (): JSX.Element => {
		return (
			<div className="section-header">
				<div className="title">
					<EvTitle
						titleText={title || 'Grouping rules'}
						MoreInfoContent={renderMoreInfo()}
						id={GROUPING_RULES_TITLE}
						descriptionId={GROUPING_RULES_DESCRIPTION}
					/>
					<div className="actions">{renderAddGroupingRule()}</div>
				</div>
			</div>
		);
	};

	return (
		<EvSection
			className="grouping-settings-section"
			ariaLabelledby={GROUPING_RULES_TITLE}
			ariaDescribedby={GROUPING_RULES_DESCRIPTION}
		>
			<div className="grouping-settings-content">
				{renderSectionHeader()}
				{description && <div className="description">{description}</div>}
				{renderSectionBody()}
			</div>
		</EvSection>
	);
};

export default GroupingSettings;
