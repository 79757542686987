import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { EvMainTitle } from '@evinced-private/ui-common';
import IssueTypeDrilldownViewV2 from '../../pages/scan-analysis/views/issuetype-drilldown/IssueTypeDrilldownViewV2';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import {
	INavigationRoute,
	useNavigation
} from '../../providers/navigationProvider/NavigationProvider';
import EvPropertyViews from '../common/ev-property-views/EvPropertyViews';
import PropertyViewLoadErrorPage from '../common/ev-property-views/PropertyViewLoadErrorPage';
import './DrilldownPage.scss';

const IssueTypeDrilldownPage: FC = () => {
	const { propertyId, scanId, issueType } = useParams<{ [key: string]: string }>();
	const { setCurrentRoute } = useNavigation();

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const viewId: string = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);
	const viewLoadErr: boolean = useMemo(() => properyViewContext?.loadViewErr, [properyViewContext]);
	const title = `${issueType} type details`;

	const currentRoute: INavigationRoute = useMemo(() => {
		if (!propertyId || !issueType) {
			return null;
		}

		return {
			id: propertyId,
			title
		};
	}, [propertyId, title, issueType]);

	useEffect(() => {
		if (currentRoute) {
			setCurrentRoute(currentRoute);
		}
		return () => {
			setCurrentRoute(null);
		};
	}, [currentRoute, setCurrentRoute]);

	if (viewLoadErr) {
		return <PropertyViewLoadErrorPage propertyId={propertyId} />;
	}

	return (
		<div className="drilldown-page">
			<div className="drilldown-page-content">
				<div className="header">
					<EvMainTitle className="page-heading">{title}</EvMainTitle>
					<div className="filtered-views-controller">
						<EvPropertyViews propertyId={propertyId} />
					</div>
				</div>
				<IssueTypeDrilldownViewV2
					viewId={viewId}
					scanId={scanId}
					issueType={issueType}
					propertyId={propertyId}
				/>
			</div>
		</div>
	);
};

export default IssueTypeDrilldownPage;
