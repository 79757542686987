/* eslint-disable react/jsx-no-useless-fragment */
import React, { ComponentProps, FC } from 'react';

// source: https://javascript.plainenglish.io/how-to-combine-context-providers-for-cleaner-react-code-9ed24f20225e
export const combineComponents = (...components: { Component: FC; value?: object }[]): FC => {
	return components.reduce(
		(AccumulatedComponents, { Component, value }) => {
			return ({ children }: ComponentProps<FC>): JSX.Element => {
				return (
					<AccumulatedComponents>
						<Component {...value}>{children}</Component>
					</AccumulatedComponents>
				);
			};
		},
		({ children }) => <>{children}</>
	);
};
