import {
	BUTTON_TYPES,
	EvButton,
	EvIcon,
	EvLink,
	EvMarkdownViewer,
	EvSnippetView,
	EvSpinner,
	EvTitle,
	IssueDetailsHelper,
	TITLE_MODES
} from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotGroupedComponent from '../../consts/NotGroupedComponent';
import RoutesHelper from '../../helpers/RoutesHelper';
import UrlNormalizingHelper from '../../helpers/UrlNormalizingHelper';
import logger from '../../services/Logger';
import ScreenshotServiceV2 from '../../services/ScreenshotServiceV2';
import scanService from '../../services/scan/ScansService';
import { Report, ReportElement } from '../../types/ReportModel';
import CopyToClipboard from '../common/copy-to-clipboard/CopyToClipboard';
import NoScreenShotImage from '../icons/NoScreenShotImage.svg';
import IssueTextBlock, { renderPart } from '../issue-text-block/IssueTextBlock';
import TicketsSystems from '../tickets-systems/TicketsSystems';

import './IssueDetailsBlock.scss';

interface IIssueDetailsProps {
	issueId: string;
	scanId?: string;
	setError: (e: Error) => void;
	setTitle?: (title: string) => void;
	isPage?: boolean;
}

const IssueDetailsBlock: FC<IIssueDetailsProps> = ({
	issueId,
	scanId,
	setError,
	setTitle,
	isPage
}: IIssueDetailsProps) => {
	const [issue, setIssue] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [image, setImage] = useState<string>(null);
	const { propertyId } = useParams<{ propertyId: string }>();

	const subheadingsLevel = isPage ? 2 : 3;

	const { type, severity } = issue || {};

	const openIssueInNewTab = useCallback((): void => {
		window.open(RoutesHelper.getIssueDetailsPath(propertyId, scanId, issueId), '_blank');
	}, [propertyId, scanId, issueId]);

	const resolveImage = useCallback(async (issueData: Report): Promise<void> => {
		const boundingBoxes = issueData.elements.map((element) => element.boundingBox);
		try {
			const screenshotImageData = await ScreenshotServiceV2.getFullPageScreenshotWithIssues({
				pageScreenshotUrl: issueData.pageScreenshot,
				boundingBoxes
			});
			setImage(screenshotImageData);
		} catch (e) {
			logger.warn(e);
			setImage(null);
		}
	}, []);

	const fetchIssue = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);
			const issueData: Report = await scanService.getScanIssue(scanId, issueId);
			setIssue(issueData);
			if (setTitle) {
				setTitle(issueData?.type?.name);
			}
			await resolveImage(issueData);
		} catch (e) {
			setError(e);
			logger.warn(e);
		}
		setLoading(false);
	}, [scanId, issueId, resolveImage, setError, setTitle]);

	useEffect(() => {
		if (!issue) {
			fetchIssue();
		}
	}, [issue, fetchIssue]);

	const renderImage = (): JSX.Element => {
		if (image) {
			return <img className="screenshot-img" src={image} alt="Issue Screenshot" />;
		}
		return (
			<EvIcon className="image-not-found-icon" icon={NoScreenShotImage} title="not available" />
		);
	};

	const renderComponentDetails = (element: ReportElement): JSX.Element => {
		return (
			<div className="component-details" key={element.id}>
				{renderPart(
					'Component:',
					element.componentId === NotGroupedComponent.ID
						? NotGroupedComponent.DISPLAY_NAME
						: element.componentId,
					subheadingsLevel
				)}
				{renderPart(
					'Selector:',
					<div className="selector-with-copy">
						{element.selector}
						<CopyToClipboard renderJustIcon textToCopy={element.selector} />
					</div>,
					subheadingsLevel
				)}
				<div className="snippet" aria-label="DOM Snippet">
					<EvSnippetView title="" snippet={element.domSnippet} codeType="html" />
				</div>
			</div>
		);
	};

	const renderKnowledgeBaseLink = (issue: Report): JSX.Element => {
		return (
			<EvLink
				url={issue.knowledgeBaseLink}
				linkText="Read more in Evinced Knowledge Base"
				showIcon={false}
				openInNewTab={true}
			/>
		);
	};

	const generateWCAGLinks = (issue: Report): JSX.Element => {
		if (issue.tags.length) {
			return (
				<div className="wcag-part">
					<div className="part">
						<EvTitle
							className="name"
							headingLevel={subheadingsLevel}
							titleText="WCAG Violations:"
						/>
					</div>
					<ul className="wcag-links">
						{issue?.tags
							.filter((tag) => tag.description && tag.link)
							.map((tag) => {
								return (
									<li className="ev-how-to-fix-option" key={tag.id}>
										<EvLink
											url={tag.link}
											linkText={tag.description}
											showIcon={false}
											openInNewTab={true}
										/>
									</li>
								);
							})}
					</ul>
				</div>
			);
		}
		return null;
	};

	const renderIssueCause = (issue: Report): JSX.Element => {
		const causeStr = IssueDetailsHelper.getCauseString(issue?.description);

		return (
			<IssueTextBlock
				sectionName="Cause:"
				stringToRender={causeStr}
				headingLevel={subheadingsLevel}
			/>
		);
	};

	const renderIssueEffect = (issue: Report): JSX.Element => {
		const effectStr = IssueDetailsHelper.getEffectString(issue?.description);

		return (
			<IssueTextBlock
				sectionName="Effect:"
				stringToRender={effectStr}
				headingLevel={subheadingsLevel}
			/>
		);
	};

	const renderHowToFix = (issue: Report): JSX.Element => {
		return (
			<div className="how-to-fix">
				<div className="part">
					<EvTitle headingLevel={subheadingsLevel} className="name" titleText="How To Fix:" />
				</div>
				<EvMarkdownViewer initialValue={IssueDetailsHelper.getHowToString(issue?.description)} />
			</div>
		);
	};

	const urlToCopy = `${UrlNormalizingHelper.getBaseURL()}${RoutesHelper.getIssueDetailsPath(
		propertyId,
		scanId,
		issueId
	)}`;

	if (isLoading) {
		return (
			<div className="spinner-wrapper">
				<EvSpinner />
			</div>
		);
	}

	return issue ? (
		<div className="issue-details-content">
			<div className="section-header">
				<div className={classNames('title-block', { 'with-hidden-title': !isPage })}>
					{isPage && (
						<EvTitle titleText={type.name || ''} headingLevel={1} mode={TITLE_MODES.MAIN} />
					)}
					<div className="action-buttons">
						<CopyToClipboard textToCopy={urlToCopy} buttonType={BUTTON_TYPES.SECONDARY} />
						{!isPage && (
							<EvButton
								type={BUTTON_TYPES.SECONDARY}
								title="Open in a New Tab"
								onClick={openIssueInNewTab}
							>
								Open in a New Tab
							</EvButton>
						)}
						<TicketsSystems issue={issue} />
					</div>
				</div>
				<div className="sub-title">{issue.summary}</div>
			</div>

			<div className="element-details">
				<div className="details-block">
					{renderPart(
						'URL:',
						<EvLink url={issue.elements[0].pageUrl} showIcon={true} openInNewTab={true} />,
						subheadingsLevel
					)}
				</div>
				<div className="details-block">
					{issue.elements.map((element) => renderComponentDetails(element))}
				</div>
				<div className="details-block dual">
					<div className="half-block">
						<div className="severity-level">
							{renderPart('Severity:', severity?.name, subheadingsLevel)}
							{renderIssueCause(issue)}
							{renderIssueEffect(issue)}
							{renderKnowledgeBaseLink(issue)}
						</div>
					</div>
					<div className="half-block">
						{renderHowToFix(issue)}
						{generateWCAGLinks(issue)}
					</div>
				</div>
			</div>
			{image && <div className="screenshot-picture">{renderImage()}</div>}
		</div>
	) : null;
};

export default IssueDetailsBlock;
