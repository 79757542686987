import { BUTTON_TYPES, EvButton, EvLoader, EvSelect, OptionType } from '@evinced-private/ui-common';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	ALL_CONTENT_VIEW_ID,
	ALL_CONTENT_VIEW_LABEL
} from '../../../../components/common/ev-property-views/consts/PropertyViews';
import EvTabs from '../../../../components/common/ev-tabs/EvTabs';
import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import { PERFORMANCE_OVER_TIME_CHART_TYPE } from '../../DashboardConsts';
import './PerformanceOverTime.scss';
import PerformanceOverTimeChart from './PerformanceOverTimeChart';
import { usePerformanceOverTimeData } from './hook/usePerformanceOverTimeData';

const tabOptions = [
	{ key: PERFORMANCE_OVER_TIME_CHART_TYPE.SCORE, label: 'Score' },
	{ key: PERFORMANCE_OVER_TIME_CHART_TYPE.CRITICAL_ISSUES, label: 'Critical Issues' },
	{ key: PERFORMANCE_OVER_TIME_CHART_TYPE.TOTAL_ISSUES, label: 'Total Issues' }
];

const tabs = tabOptions.reduce((obj, { key, label }) => {
	obj[key] = label;
	return obj;
}, {});

const MAX_SELECTED_VIEWS = 3;

const PerformanceOverTimeSection: FC = () => {
	const [viewsOptions, setViewsOptions] = useState<OptionType[]>([]);
	const [selectedViews, setSelectedViews] = useState<OptionType[]>([]);
	const [appliedViews, setAppliedViews] = useState<OptionType[]>([]);
	const [appliedViewsIdsSet, setAppliedViewsIdsSet] = useState<Set<string>>(new Set());
	const [optionsDisabled, setOptionsDisabled] = useState(false);
	const {
		defaultActivePropertiesViews,
		performanceOverTimeViewMode,
		setPerformanceOverTimeViewMode
	} = useDashboard();
	const { chartType, setChartType, allViewsMap, scansMap, isLoadingScans } =
		usePerformanceOverTimeData(appliedViewsIdsSet);

	const updateAllViewsOptions = useCallback(() => {
		const options = Array.from(allViewsMap.values()).map((view) => ({
			value: view?.id,
			label: `${view?.propertyName} / ${view?.name}`
		}));
		setViewsOptions(options);
	}, [allViewsMap]);

	const initializeSelectedAndAppliedViewsOptions = useCallback(() => {
		if (defaultActivePropertiesViews?.length) {
			const selectOptions = defaultActivePropertiesViews.map((activeProperty) => ({
				value: activeProperty.view?.id || `${ALL_CONTENT_VIEW_ID} ${activeProperty.property?.id}`,
				label: `${activeProperty.property.name} / ${
					activeProperty.view?.name || ALL_CONTENT_VIEW_LABEL
				}`
			}));
			setSelectedViews(selectOptions);
			setAppliedViews(selectOptions);
		}
	}, [defaultActivePropertiesViews]);

	useEffect(() => {
		initializeSelectedAndAppliedViewsOptions();
	}, [initializeSelectedAndAppliedViewsOptions]);

	useEffect(() => {
		// disable options if max selected views reached
		if (selectedViews.length === MAX_SELECTED_VIEWS && !optionsDisabled) {
			const options = viewsOptions.map((option) => {
				const selectedIdIndex = selectedViews.findIndex((view) => view.value === option.value);
				if (selectedIdIndex === -1) {
					return {
						...option,
						isDisabled: true
					};
				}
				return option;
			});
			setViewsOptions(options);
			setOptionsDisabled(true);
		} else if (selectedViews.length < MAX_SELECTED_VIEWS && optionsDisabled) {
			const options = viewsOptions.map((option) => ({
				...option,
				isDisabled: false
			}));
			setViewsOptions(options);
			setOptionsDisabled(false);
		}
	}, [selectedViews, viewsOptions, optionsDisabled]);

	useEffect(() => {
		updateAllViewsOptions();
	}, [updateAllViewsOptions]);

	// update set of applied keys
	useEffect(() => {
		const keys = appliedViews.map((view) => view?.value as string).filter(Boolean);
		setAppliedViewsIdsSet(new Set(keys));
	}, [appliedViews]);

	const onApplyView = useCallback((): void => {
		setAppliedViewsIdsSet(() => {
			const newSet: Set<string> = new Set();
			selectedViews.forEach((view) => newSet.add(view.value as string));
			return newSet;
		});
		setAppliedViews(selectedViews);
	}, [selectedViews]);

	const onMultiViewSelect = (values: OptionType[]): void => {
		if (values.length < 4) {
			setSelectedViews(values);
		}
	};

	const performanceChart = useMemo(() => {
		return tabOptions.map((option) => (
			<PerformanceOverTimeChart
				key={option.key}
				scansMap={scansMap}
				allViewsMap={allViewsMap}
				chartType={option}
				yAxisLabels={tabs}
				isNotSelected={appliedViews.length === 0}
				performanceOverTimeViewMode={performanceOverTimeViewMode}
				setPerformanceOverTimeViewMode={setPerformanceOverTimeViewMode}
			/>
		));
	}, [
		scansMap,
		allViewsMap,
		appliedViews,
		performanceOverTimeViewMode,
		setPerformanceOverTimeViewMode
	]);

	const renderTabsSelection = useCallback(
		() => (
			<EvTabs
				className={classNames('performance-charts-tabs', 'performance-charts-tabs-multichart')}
				options={tabOptions}
				selected={chartType}
				onSelect={setChartType}
				tabPanels={performanceChart}
			/>
		),
		[chartType, setChartType, performanceChart]
	);

	const renderChartSection = useCallback(() => {
		if (isLoadingScans && !scansMap.size) {
			return <EvLoader message="Loading..." />;
		}
		return renderTabsSelection();
	}, [isLoadingScans, scansMap, renderTabsSelection]);

	const isApplyButtonDisabled = useMemo(() => {
		return isLoadingScans || isEqual(selectedViews, appliedViews);
	}, [selectedViews, appliedViews, isLoadingScans]);

	return (
		<div className="performance-over-time">
			{renderChartSection()}
			<div
				className={classNames('performance-chart-details', 'performance-chart-details-multichart')}
			>
				{viewsOptions.length ? (
					<div className="select-group">
						<EvSelect
							value={selectedViews}
							onChange={onMultiViewSelect}
							options={viewsOptions}
							placeholder="Select views"
							isMulti
						/>
						<EvButton
							disabled={isApplyButtonDisabled}
							type={BUTTON_TYPES.ACTION}
							onClick={onApplyView}
						>
							Apply
						</EvButton>
					</div>
				) : (
					<EvLoader message="Loading..." />
				)}
			</div>
		</div>
	);
};

export default PerformanceOverTimeSection;
