import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC } from 'react';
import './QuickWinsPagination.scss';

interface IQuickWinsPagination {
	currentPage: number;
	setCurrentPage: (currPage: number) => void;
	className?: string;
	numberOfPages?: number;
}

const QuickWinsPagination: FC<IQuickWinsPagination> = ({
	currentPage,
	setCurrentPage,
	className,
	numberOfPages = 2
}) => {
	const onNext = (): void => {
		setCurrentPage(currentPage + 1);
	};

	const onPrev = (): void => {
		setCurrentPage(currentPage - 1);
	};

	return (
		<div className={classNames('quick-wins-pagination', { [className]: !!className })}>
			<EvButton
				className="prev-button"
				onClick={onPrev}
				type={BUTTON_TYPES.LINK}
				title="Prev button: Show previous elements"
				disabled={currentPage === 1}
			>
				{'< Prev'}
			</EvButton>
			<div className="page-position">{`${currentPage} of ${numberOfPages}`}</div>
			<EvButton
				className="next-button"
				onClick={onNext}
				type={BUTTON_TYPES.LINK}
				title="Next: Show next elements"
				disabled={currentPage >= numberOfPages}
			>
				{'Next >'}
			</EvButton>
		</div>
	);
};

export default QuickWinsPagination;
