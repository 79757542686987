import { IPropertyView } from '../../../../interfaces/PropertyViews';

export const manageViewsButtonTitle = 'Manage Views';

export const ALL_CONTENT_VIEW_LABEL = 'All content';
export const ALL_CONTENT_VIEW_ID = 'all';

export const ALL_CONTENT_VIEW: IPropertyView = {
	id: ALL_CONTENT_VIEW_ID,
	name: ALL_CONTENT_VIEW_LABEL,
	propertyId: '',
	configuration: null
};
