import ServerApi from '../api/ServerApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { IValidationResult } from '../interfaces/ValidationResult';

const { VALIDATORS_URL_PATH } = API_URL_PATHS;

async function getUrlValidationInfo(url: string): Promise<IValidationResult> {
	return ServerApi.apiCall('GET', `${VALIDATORS_URL_PATH}?url=${url}`);
}

export default { getUrlValidationInfo };
