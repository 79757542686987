import AppInitService from '../services/AppInitService';
import { AuthenticationResult } from '../services/AuthenticationService';
import { TOKEN_QUERY_PARAM } from './InvitationTokenHelper';
import RoutesHelper from './RoutesHelper';

export const handleSuccessfulAuthentication = async (
	authResult: AuthenticationResult,
	history
): Promise<void> => {
	await AppInitService.initAppOnAuthentication(authResult);
	let redirectedPath;

	// handle invitaiton activation flow
	if (authResult?.appState?.invitationToken) {
		redirectedPath = `${RoutesHelper.getHomepagePath()}?${TOKEN_QUERY_PARAM}=${
			authResult.appState.invitationToken
		}`;
		history.push(redirectedPath);
	}
};
