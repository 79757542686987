import { EVINCED_SEVERITIES } from '@evinced-private/ui-common';
import { IIssuesOverviewResponse } from '../interfaces/IssuesOverviewResponse';

const getIssuesCount = (
	issuesOverviewReponse: IIssuesOverviewResponse,
	severity: EVINCED_SEVERITIES
): number => {
	return issuesOverviewReponse?.severities?.find((s) => s.severity === severity)?.issuesCount || 0;
};

export default { getIssuesCount };
