import { EvLinkTypes } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import EvLinkLocal from '../../../../components/common/ev-link-local/EvLinkLocal';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import './PropertViewLink.scss';

interface IPropertViewLink {
	propertyId: string;
	viewId: string;
	propertyName: string;
	viewName: string;
	withViews: boolean;
}

const PropertViewLink: FC<IPropertViewLink> = ({
	propertyId,
	viewId,
	propertyName,
	viewName,
	withViews
}) => {
	const customLink = (
		<div className="property-view-link">
			<div className="property-link">{propertyName}</div>
			<div className="view-name">{withViews ? viewName : null}</div>
		</div>
	);
	return (
		<EvLinkLocal
			url={RoutesHelper.getPropertyPagePath(propertyId, viewId)}
			linkText={`${propertyName} ${viewName}`}
			type={EvLinkTypes.DARK}
			className="property-link"
			customLink={customLink}
		/>
	);
};

export default PropertViewLink;
