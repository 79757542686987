import { BUTTON_TYPES, EvButton, EvIcon } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import ComponentDetailsPopup from '../component-details-popup/ComponentDetailsPopup';
import IssueInfoIcon from '../icons/IssueInfoIcon.svg';
import IssueDetailsPopup from '../issue-details-popup/IssueDetailsPopup';
import './ShowDetailsCellFormatter.scss';

interface IShowDetailsCellFormatterCell {
	issue: { id: string; url?: string };
	rowId: string;
	scanId?: string;
	componentDetails?: { componentId: string; totalPages: number; totalIssues: number };
}
const ShowDetailsCellFormatter: FC<IShowDetailsCellFormatterCell> = ({
	issue,
	rowId,
	scanId,
	componentDetails
}: IShowDetailsCellFormatterCell) => {
	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = async (): Promise<void> => {
		setModalOpen(true);
	};

	const title = componentDetails ? 'Open component details modal' : 'Open issue details modal';

	let componentsPopupComponent;
	if (componentDetails) {
		componentsPopupComponent = (
			<ComponentDetailsPopup
				componentId={componentDetails.componentId}
				issueId={issue.id}
				onClose={() => setModalOpen(false)}
				scanId={scanId}
				totalPages={componentDetails.totalPages}
				totalIssues={componentDetails.totalIssues}
			/>
		);
	}

	return (
		<div className="show-details-cell">
			<EvButton
				title={title}
				customTooltip={{ renderTooltip: true }}
				type={BUTTON_TYPES.ICON}
				className="show-details-button"
				onClick={openModal}
				aria-label={`${rowId} show details`}
				ariaHaspopup="dialog"
			>
				<EvIcon icon={IssueInfoIcon} />
			</EvButton>
			{isModalOpen && !componentDetails ? (
				<IssueDetailsPopup issueId={issue.id} onClose={() => setModalOpen(false)} scanId={scanId} />
			) : null}
			{isModalOpen && componentDetails ? componentsPopupComponent : null}
		</div>
	);
};

export default ShowDetailsCellFormatter;
