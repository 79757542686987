import {
	EvButton,
	EvSpinner,
	EvSection,
	TABLE_SR_SUMMARY_IDS,
	useSkipLinks,
	EvMainTitle
} from '@evinced-private/ui-common';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import EllipsedTextWithTooltip from '../../components/common/ellipsed-text-with-tooltip/EllipsedTextWithTooltip';
import CrawlStatusLine, {
	PROPERTY_PAGE_CRAWL_INDICATION_ID
} from '../../components/crawl-status-line/CrawlStatusLine';
import ScansTable from '../../components/scans-table/ScansTable';
import SchedulingStatusLine from '../../components/scheduling-status-line/SchedulingStatusLine';
import StartCrawlPopup from '../../components/start-crawl-popup/StartCrawlPopup';
import StartScanPopup from '../../components/start-scan-popup/StartScanPopup';
import PropertyHelper from '../../helpers/PropertyHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { COMPARABLE_SCANS_COUNT } from '../../helpers/ScanHelper';
import useInterval from '../../hooks/useInterval';
import { IProperty } from '../../interfaces/Property';
import { IScan } from '../../interfaces/Scan';
import { IUrlSet } from '../../interfaces/UrlSet';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import urlSetService from '../../services/UrlSetService';
import scansService from '../../services/scan/ScansService';
import URL_SET_SOURCES from '../../types/UrlSetSources';
import './PropertyPage.scss';

interface IPropertyPageProps {
	propertyId: string;
	disableTrends: (val: boolean) => void;
}

const PropertyPage: FC<IPropertyPageProps> = ({
	propertyId,
	disableTrends
}: IPropertyPageProps) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [property, setProperty] = useState<IProperty>(null);
	const [urlSet, setUrlSet] = useState<IUrlSet>(null);
	const [isLoadingScans, setLoadingScans] = useState<boolean>(true);
	const [scans, setScans] = useState<IScan[]>(null);
	const [runInterval, setRunInterval] = useState(true);
	const [selectedScans, setSelectedScans] = useState<string[]>([]);
	const { isReadOnlyUser } = useUserTenant();

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId = useMemo(() => properyViewContext?.currentView?.id, [properyViewContext]);

	const canCompareScans = useMemo(() => {
		return TogglesService.getToggle(DevelopmentToggles.SHOW_COMPARE_SCANS);
	}, []);

	const history = useHistory();
	const notificationsContext = useNotifications();

	const REFRESH_TABLE_INTERVAL = 20000;

	const getUrlSetByProperty = useCallback(async (): Promise<void> => {
		try {
			const urlSet = await propertiesService.getUrlSetByPropertyId(propertyId);
			setUrlSet(urlSet);
		} catch (err) {
			logger.error('Error getting urlset', err);
			setRunInterval(false);
		}
	}, [propertyId]);

	const getPropertyDetails = useCallback(async (): Promise<void> => {
		logger.info('Getting info for property with ID: ', propertyId);
		try {
			const property = await propertiesService.getProperty(propertyId);
			setProperty(property);
			// sometimes there's no urlSetId (for example - after a user deleted all the urlsets)
			if (!property.scanConfiguration.urlSetId) {
				setLoading(false);
			} else {
				const urlSet = await urlSetService.getUrlSet(property.scanConfiguration.urlSetId);
				setUrlSet(urlSet);
			}
		} catch (err) {
			setRunInterval(false);
			logger.error('error while getting property', err);
			notificationsContext.alert({
				errorMessage: 'Error loading this property, redirecting to properties page',
				serverError: err,
				onOK: () => history.push(RoutesHelper.getPropertiesPagePath())
			});
		} finally {
			setLoading(false);
		}
	}, [propertyId, notificationsContext, history]);

	useEffect(() => {
		setLoading(true);
		getPropertyDetails();
	}, [getPropertyDetails]);

	useEffect(() => {
		setLoadingScans(true);
	}, [currentViewId]);

	useSkipLinks(!loading && !isLoadingScans);

	const getAllScans = useCallback(async (): Promise<void> => {
		try {
			const scansList = await scansService.getAllScansSortedByTimeDesc(property.id, currentViewId);
			setScans(scansList);
		} catch (err) {
			logger.error(`Error when getting all scans for property ${property.id}`, err);
			setRunInterval(false);
		} finally {
			setLoadingScans(false);
		}
	}, [property?.id, currentViewId]);

	useInterval(
		() =>
			Promise.all([getUrlSetByProperty(), getAllScans()]).catch((err) => {
				logger.error('Error getting data', err);
				setRunInterval(false);
			}),
		runInterval ? REFRESH_TABLE_INTERVAL : null
	);

	useEffect(() => {
		if (property) {
			setRunInterval(true);
			getAllScans();
		}
	}, [property, getAllScans]);

	useEffect(() => {
		if (scans) {
			const successScansExist = scans.some((scan) =>
				ScanCrawlStateHelper.isScanResultsReady(scan.state)
			);
			disableTrends(!successScansExist);
		}
	}, [scans, disableTrends]);

	const openEditingScreen = useCallback(() => {
		history.push(RoutesHelper.getPropertySettingsPagePath(property.id), {
			from: history.location.pathname
		});
	}, [history, property]);

	const renderCreateCrawlPopup = (): JSX.Element => {
		const { urlSetConfiguration } = property;
		if (urlSetConfiguration.source === URL_SET_SOURCES.UPLOAD) {
			return (
				<EvButton
					customTooltip={{ renderTooltip: true }}
					disabled
					title="Crawl is disabled in List Mode. The property is configured with a predefined list of URLs."
				>
					Crawl Now
				</EvButton>
			);
		}

		return (
			<StartCrawlPopup
				triggerButtonProps={{
					title: 'Crawl Now',
					children: 'Crawl'
				}}
				onCrawlCreated={() => {
					getUrlSetByProperty();
				}}
				propertyId={property.id}
			/>
		);
	};

	const renderCreateScanPopup = (): JSX.Element => {
		return (
			<StartScanPopup
				triggerButtonProps={{
					title: 'Scan Now',
					children: 'Scan Now',
					disabled: PropertyHelper.isPdfMode(property),
					disabledReason: 'Scans are disabled in PDF mode'
				}}
				onScanCreated={() => {
					logger.info(`Scan created for property ${property.id}`);
					setLoadingScans(true);
					getAllScans();
				}}
				propertyId={property.id}
				urlSet={urlSet}
			/>
		);
	};

	const renderEditPropertyButton = (): JSX.Element => {
		return (
			<EvButton title="Settings" ariaHaspopup="dialog" onClick={openEditingScreen}>
				Settings
			</EvButton>
		);
	};

	const renderPageActions = (): JSX.Element => {
		return (
			<div className="page-actions">
				{renderCreateCrawlPopup()}
				{renderCreateScanPopup()}
				{renderEditPropertyButton()}
			</div>
		);
	};

	const renderCompareScans = useCallback(
		(isSelectionValid: boolean): JSX.Element => {
			return (
				canCompareScans && (
					<div className="property-actions-section">
						<EvButton
							title="Compare scans"
							className="compare-scans-button"
							onClick={() => {
								const comparableScans = scans.filter((s) => selectedScans.includes(s.id));
								const scansSortedByTime = _.sortBy(comparableScans, 'createdTime');
								history.push(
									RoutesHelper.getScanComparisonPagePath(
										propertyId,
										scansSortedByTime[0].id,
										scansSortedByTime[1].id,
										currentViewId
									)
								);
							}}
							disabled={!isSelectionValid}
						>
							Compare Scans
						</EvButton>
						{!isSelectionValid && scans?.length > 1 && (
							<div className="compare-scans-hint">Select two scans for comparison</div>
						)}
					</div>
				)
			);
		},
		[scans, selectedScans, history, propertyId, canCompareScans, currentViewId]
	);

	const renderPropertyDetails = (): JSX.Element => {
		return (
			<div className="property-details">
				{canCompareScans ? (
					renderCompareScans(selectedScans.length === COMPARABLE_SCANS_COUNT)
				) : (
					<EvMainTitle className="property-name">
						<EllipsedTextWithTooltip text={property.name} maxWidth="600px" />
					</EvMainTitle>
				)}
				<EvSection
					ariaLabel="Property actions"
					className="property-actions-section"
					skipLinkId="property-actions"
					ariaDescribedby={PROPERTY_PAGE_CRAWL_INDICATION_ID}
				>
					<div className="property-status">
						<CrawlStatusLine
							hasScans={!!scans.length}
							onDelete={getUrlSetByProperty}
							property={property}
							urlSet={urlSet}
						/>
						{!isReadOnlyUser() && (
							<SchedulingStatusLine
								isSchedulingEnabled={property.scheduledJobConfiguration?.active}
								jobType={property.scheduledJobConfiguration?.jobType}
								nextScheduledJobDate={property.nextScheduleJobTime}
								onEditClicked={openEditingScreen}
							/>
						)}
					</div>
					{!isReadOnlyUser() && renderPageActions()}
				</EvSection>
			</div>
		);
	};

	if (loading || isLoadingScans) {
		return <EvSpinner />;
	}

	return (
		<div className="property-page">
			{renderPropertyDetails()}
			<EvSection
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				ariaLive="polite"
				ariaLabel={`${property.name} scans table`}
				skipLinkId="property-scans-table"
			>
				<ScansTable
					selectedScans={selectedScans}
					setSelectedScans={setSelectedScans}
					scans={scans}
					property={property}
					urlSet={urlSet}
					onScanDeleted={getAllScans}
					renderCreateScanPopup={renderCreateScanPopup}
				/>
			</EvSection>
		</div>
	);
};

export default PropertyPage;
