import {
	EvTable,
	IEvTableProps,
	SORT_ORDER,
	TableChangeState,
	TableChangeType,
	TableSortOption
} from '@evinced-private/ui-common';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useLayoutEffect } from 'react';
import { usePageScrollPositionState } from '../../../providers/PageScrollPositionProvider';
import { useTablesStore } from '../../../providers/tablesProvider/TablesProvider';

type ISiteScannerTableProps = Omit<IEvTableProps, 'rootApplicationSelector'> & {
	tableId: string;
	persistTableState?: boolean;
	totalPaginationableResults?: number;
	isTableLoading?: boolean;
	withUrlSwitcher?: boolean;
};

const SiteScannerTable: FC<ISiteScannerTableProps> = ({
	tableId,
	persistTableState = true,
	totalPaginationableResults,
	isTableLoading,
	...props
}: ISiteScannerTableProps) => {
	const tablesStore = useTablesStore();
	const { scrollToSavedPosition, pagesScrollPositionState } = usePageScrollPositionState();
	const { options } = props;

	useLayoutEffect(() => {
		const pagesPositionSettings = pagesScrollPositionState?.[tableId];
		if (pagesPositionSettings) {
			const { elementSelector } = pagesPositionSettings;
			if (elementSelector) {
				scrollToSavedPosition(tableId, !isTableLoading);
			}
		}
	}, [tableId, pagesScrollPositionState, scrollToSavedPosition, isTableLoading]);

	const onPageChange = useCallback(
		(page: number, sizePerPage: number): void => {
			tablesStore.updatePagination(tableId, { page, sizePerPage });
		},
		[tableId, tablesStore]
	);

	const onSizePerPageChange = useCallback(
		(sizePerPage: number, page: number): void => {
			tablesStore.updatePagination(tableId, { page, sizePerPage });
		},
		[tableId, tablesStore]
	);

	const onSort = useCallback(
		(sortDetails: TableSortOption[]): void => {
			tablesStore.updateSortOrder(tableId, sortDetails);
		},
		[tableId, tablesStore]
	);

	const onFilter = useCallback(
		(dataField: string, value: string): void => {
			const { sizePerPage, page } = tablesStore.getPagination(tableId);
			tablesStore.updateFilter(tableId, dataField, value);
			if (page !== 1) {
				tablesStore.updatePagination(tableId, { page: 1, sizePerPage });
			}
		},
		[tableId, tablesStore]
	);

	const getFilterValue = useCallback(
		(dataField: string) => {
			return tablesStore.getFilterValue(tableId, dataField);
		},
		[tableId, tablesStore]
	);
	const paginationState = tablesStore.getPagination(tableId);
	const { page, sizePerPage } = paginationState;
	const sort = tablesStore.getSortOrder(tableId) || options.defaultSorted;
	if (options.remote) {
		options.remoteProps = {
			onTableChange: (
				type: TableChangeType,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ page, sizePerPage, sortField, sortOrder }: TableChangeState<any>
			): void => {
				if (type === 'sort') {
					// the first time a table is loaded the onTableChange
					// function is called with the same sort
					// we want to ignore its changing on the page size
					const currentSort: TableSortOption = sort?.[0] || { order: null, dataField: null };
					if (currentSort.order === sortOrder && currentSort.dataField === sortField) {
						return;
					}
					tablesStore.updateTableState(
						tableId,
						[{ dataField: sortField, order: sortOrder as SORT_ORDER }],
						{ page: 1, sizePerPage }
					);
				} else if (type === 'pagination') {
					tablesStore.updateTableState(tableId, null, { page, sizePerPage });
				}
			},
			page,
			sizePerPage,
			sort,
			totalSize: totalPaginationableResults // todo: add a totalSize prop in state
		};
		options.isLoading = isTableLoading;
	} else if (persistTableState) {
		if (options?.pagination) {
			options.localProps = {
				defaultPage: page,
				defaultSizePerPage: sizePerPage,
				defaultSort: sort,
				onSort,
				getFilterValue,
				onFilter,
				onPageChange,
				onSizePerPageChange
			};
		}
	}

	return <EvTable key={tableId} {...props} rootApplicationSelector=".app" />;
};

export default observer(SiteScannerTable);
