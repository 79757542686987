import { EvSpinner, EvErrorCard } from '@evinced-private/ui-common';
import React, { useEffect, useState } from 'react';
import { splitArrayToChunks } from '../../helpers/ArrayHelper';
import ScreenshotServiceV2 from '../../services/ScreenshotServiceV2';
import { safeJsonParse } from './ScreenshotWithBoundingBoxesHelper';
import './ScreenshotWithBoundingBoxesPage.scss';

const PARSING_BOUNDING_BOX_ERR_MSG = 'Error parsing bounding box parameter';
interface IScreenshotInfo {
	scanId: string;
	imgId: string;
	boundingBox: number[] | null;
}

const ScreenshotWithBoundingBoxesPage: React.FC = () => {
	const baseUrl = 'https://evinced-temp-screenshots-bucket.storage.googleapis.com';

	const [screenshot, setScreenshot] = useState<string>('');
	const [error, setError] = useState<string>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const getScreenShotInfo = (): IScreenshotInfo => {
		const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
		return {
			scanId: searchParams.get('scanId'),
			imgId: searchParams.get('imgId'),
			boundingBox: safeJsonParse(searchParams.get('boundingBox'), PARSING_BOUNDING_BOX_ERR_MSG)
		};
	};

	const onScreenshotReady = (imgSrc: string): void => {
		setScreenshot(imgSrc);
		setError(null);
	};

	const onErrorLoadingImg = (): void => {
		setError('An error occurred while trying to load the screenshot');
	};

	useEffect(() => {
		const displayScreenshotWithBoundingBox = async (): Promise<void> => {
			const screenshotInfo: IScreenshotInfo = getScreenShotInfo();
			const finalUrl = `${baseUrl}/${screenshotInfo.scanId}/${screenshotInfo.imgId}.png`;

			try {
				let imgSrc = '';
				if (!screenshotInfo.boundingBox) {
					imgSrc = ScreenshotServiceV2.getFullPageScreenshot(finalUrl);
				} else {
					const boundingBoxesList = splitArrayToChunks(screenshotInfo.boundingBox, 4);
					imgSrc = await ScreenshotServiceV2.getFullPageScreenshotWithIssues({
						pageScreenshotUrl: finalUrl,
						boundingBoxes: boundingBoxesList
					});
				}
				onScreenshotReady(imgSrc);
			} catch (e) {
				onErrorLoadingImg();
			} finally {
				setIsLoading(false);
			}
		};

		displayScreenshotWithBoundingBox();
	}, []);

	return isLoading ? (
		<EvSpinner />
	) : (
		<div className="screenshot-with-bounding-box-page">
			{error ? (
				<EvErrorCard title="Image not found" contactUsMessage={error} />
			) : (
				<img
					className="screenshot-img"
					src={screenshot}
					alt="Issue Screenshot"
					onError={onErrorLoadingImg}
				/>
			)}
		</div>
	);
};

export default ScreenshotWithBoundingBoxesPage;
