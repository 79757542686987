import SCAN_STATE from '../interfaces/ScanState';

export default class ScanCrawlStateHelper {
	static isInProgress(state: string): boolean {
		return state === SCAN_STATE.NEW || state === SCAN_STATE.RUNNING;
	}

	static isDone(state: string): boolean {
		return state === SCAN_STATE.DONE;
	}

	static isIngesting(state: string): boolean {
		return state === SCAN_STATE.INGESTING;
	}

	static isCanceling(state: string): boolean {
		return state === SCAN_STATE.CANCELING;
	}

	static isFailed(state: string): boolean {
		return state === SCAN_STATE.FAILED;
	}

	static isCanceled(state: string): boolean {
		return state === SCAN_STATE.CANCELED;
	}

	static isPagesCsvReady(state: string): boolean {
		return state === SCAN_STATE.DONE || state === SCAN_STATE.DONE_WITHOUT_CSV;
	}

	static isIssuesCsvReady(state: string): boolean {
		return state === SCAN_STATE.DONE || state === SCAN_STATE.DONE_WITHOUT_PAGES_CSV;
	}

	static isAnyCsvReadyForDownload(state: string): boolean {
		return this.isIssuesCsvReady(state) || this.isPagesCsvReady(state);
	}

	static isScanResultsReady(state: string): boolean {
		return (
			this.isIngesting(state) ||
			this.isDone(state) ||
			this.isIssuesCsvReady(state) ||
			this.isPagesCsvReady(state)
		);
	}
}
