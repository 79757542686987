import { cropImage, getEscapedUrl, getScreenshotAndMarkIssues } from './ScreenshotService';

export type ScreenshotDetails = { pageScreenshotUrl: string; boundingBoxes: number[][] };

const calculateKey = (
	pageScreenshotUrl: string,
	renderedWidth: number,
	boundingBoxes: number[][]
): string => {
	return `url=${pageScreenshotUrl}+width=${renderedWidth}+boundingBoxes=${JSON.stringify(
		boundingBoxes
	)}`;
};

const screenshotCache: Map<string, string> = new Map();

const getFullPageScreenshot = (pageScreenshotUrl: string): string => {
	// In some cases (bug with scan or other scan issues) we may miss the page picture
	// Don't fail in this case, this is valid (we will show "no screenshot")
	if (!pageScreenshotUrl) {
		return null;
	}
	return getEscapedUrl(pageScreenshotUrl);
};

const getFullPageScreenshotWithIssues = async ({
	pageScreenshotUrl,
	boundingBoxes
}: ScreenshotDetails): Promise<string> => {
	if (!pageScreenshotUrl) {
		return null;
	}
	const origScreenshotPath = getFullPageScreenshot(pageScreenshotUrl);
	const screenshotWithIssuesBase64Image = await getScreenshotAndMarkIssues(
		origScreenshotPath,
		boundingBoxes
	);
	return screenshotWithIssuesBase64Image;
};

const getIssueOnlyScreenshot = async (
	{ pageScreenshotUrl, boundingBoxes }: ScreenshotDetails,
	renderedWidth,
	noCrop = false
): Promise<string> => {
	if (!pageScreenshotUrl) {
		return null;
	}
	const cacheKey = calculateKey(pageScreenshotUrl, renderedWidth, boundingBoxes);
	const cachedImage = screenshotCache.get(cacheKey);
	if (cachedImage) {
		return cachedImage;
	}
	const screenshotWithIssues = await getFullPageScreenshotWithIssues({
		pageScreenshotUrl,
		boundingBoxes
	});

	const croppedBase64Image = noCrop
		? screenshotWithIssues
		: await cropImage(screenshotWithIssues, boundingBoxes[0], renderedWidth);

	screenshotCache.set(cacheKey, croppedBase64Image);
	return croppedBase64Image;
};

export default { getIssueOnlyScreenshot, getFullPageScreenshotWithIssues, getFullPageScreenshot };
