import { ErrorIcon, EvIcon, EvSpinner, SuccessIcon } from '@evinced-private/ui-common';
import * as React from 'react';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import SCAN_STATE from '../../interfaces/ScanState';

interface IStatusIconProps {
	scanState: SCAN_STATE;
}

const StatusIcon: React.FC<IStatusIconProps> = ({ scanState }: IStatusIconProps) => {
	let icon;
	let iconText: string;
	const isScanResultsReady = ScanCrawlStateHelper.isScanResultsReady(scanState);
	const isFailed = ScanCrawlStateHelper.isFailed(scanState);
	if (isScanResultsReady) {
		icon = SuccessIcon;
		iconText = 'Done';
	} else if (isFailed) {
		icon = ErrorIcon;
		iconText = 'Failed';
	}
	const isLoading = !isScanResultsReady && !isFailed;
	return (
		<div className="status">
			{isLoading ? <EvSpinner small /> : <EvIcon icon={icon} title={iconText} small />}
		</div>
	);
};

export default StatusIcon;
