import { OptionType } from '@evinced-private/ui-common';

type Option = {
	id?: string;
	label?: string;
	name?: string;
	value?: string;
	displayName?: string;
	accountId?: string;
}

export const adjustOptions = (options: Option[]): OptionType[] => {
	return options?.map((option) => {
		if (option.displayName) {
			return {
				label: option.displayName,
				value: option.accountId,
				item: option
			};
		}
		return {
			label: option.label || option.name || option.value,
			value: option.id || option.label,
			item: option
		};
	});
};
