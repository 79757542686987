export enum EVINCED_SEVERITIES_BY_ID {
	critical = 'CRITICAL',
	serious = 'SERIOUS',
	moderate = 'MODERATE',
	minor = 'MINOR',
	needs_review = 'NEEDS_REVIEW',
	best_practice = 'BEST_PRACTICE'
}

export enum EVINCED_SEVERITIES_BY_LABEL {
	'Critical' = 'CRITICAL',
	'Serious' = 'SERIOUS',
	'Moderate' = 'MODERATE',
	'Minor' = 'MINOR',
	'Needs Review' = 'NEEDS_REVIEW',
	'Best Practice' = 'BEST_PRACTICE'
}
