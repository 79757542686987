export enum JobTypeDto {
	CRAWL = 'CRAWL',
	SCAN = 'SCAN',
	CRAWL_AND_SCAN = 'CRAWL_AND_SCAN'
}

export enum FrequencyDto {
	WEEKLY = 'WEEKLY',
	EVERY_OTHER_WEEK = 'EVERY_OTHER_WEEK',
	MONTHLY = 'MONTHLY'
}
export interface IScheduledJobConfiguration {
	createdTime?: Date;

	active?: boolean;

	frequency?: FrequencyDto;

	jobType?: JobTypeDto;

	timezone?: string;

	hours?: number[];

	minutes?: number[];

	mdays?: number[];

	months?: number[];

	wdays?: number[];
}
