import {
	EvInfoTooltip,
	EvSection,
	EvSpinner,
	EvTitle,
	EvToggle,
	FormatHelper,
	useSkipLinks
} from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EvPropertyViews from '../../components/common/ev-property-views/EvPropertyViews';
import PropertyViewLoadErrorPage from '../../components/common/ev-property-views/PropertyViewLoadErrorPage';
import ScanComparisonIssueStatusBreakdown, {
	IIssueStatusBreakdown
} from '../../components/scan-comparison-issue-status-breakdown/ScanComparisonIssueStatusBreakdown';
import ScanComparisonSummaryPane, {
	IIssuesSummaryItem
} from '../../components/scan-comparison-summary-pane/ScanComparisonSummaryPane';
import TrendsNoDataIndication from '../../components/trends-no-data-indication/TrendsNoDataIndication';
import { formatShortMonthDatetime } from '../../helpers/DateFormatHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IScan } from '../../interfaces/Scan';
import { SummaryItem } from '../../interfaces/SummaryItem';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';
import logger from '../../services/Logger';
import ScansComparisonService, {
	IIssueComponentCounts,
	IIssuesOverview,
	IPagesOverview
} from '../../services/scan/ScansComparisonService';
import scansService from '../../services/scan/ScansService';
import ISSUE_COMPARISON_TYPE from './IssueComparisonType';
import './ScansComparisonPages.scss';
import CompareTablesSection from './components/CompareTablesSection';

const getAriaLabelForTriggerButton = (commonOnly: boolean): string => {
	const [current, other] = commonOnly ? ['Common', 'All Pages'] : ['All Pages', 'Common'];
	const action = commonOnly ? 'uncheck' : 'check';
	return `Comparison mode: ${current}, ${action} this to change to ${other}`;
};

const formatCounts = (
	counts: IIssueComponentCounts
): { issueCount: string; componentCount: string } => ({
	...counts,
	issueCount: FormatHelper.formatNumber(counts.issueCount),
	componentCount: FormatHelper.formatNumber(counts.componentCount)
});

const initialPagesOverviewContent: SummaryItem[] = [
	{ value: <EvSpinner small />, label: 'Base scan' },
	{ value: <EvSpinner small />, label: 'Both scans (Common)' },
	{ value: <EvSpinner small />, label: 'Compared scan' }
];

const initialIssuesOverviewContent: IIssuesSummaryItem[] = [
	{
		issueCount: <EvSpinner small />,
		componentCount: <EvSpinner small />,
		label: ISSUE_COMPARISON_TYPE.NEW_ISSUES
	},
	{
		issueCount: <EvSpinner small />,
		componentCount: <EvSpinner small />,
		label: ISSUE_COMPARISON_TYPE.RECURRING_ISSUES
	},
	{
		issueCount: <EvSpinner small />,
		componentCount: <EvSpinner small />,
		label: ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES
	}
];

const ScansComparisonPage: FC = () => {
	const history = useHistory();
	const [commonOnly, setCommonOnly] = useState(false);
	const [isPageLoading, setPageLoading] = useState(true);
	const [error, setError] = useState(false);
	const [baseScan, setBaseScan] = useState<IScan>();
	const [comparableScan, setComparableScan] = useState<IScan>();
	const [pagesOverview, setPagesOverview] = useState<IPagesOverview>();
	const [issuesOverview, setIssuesOverview] = useState<IIssuesOverview>();
	const { setCurrentRoute } = useNavigation();
	const queryParams: URLSearchParams = useMemo(
		() => new URLSearchParams(history.location.search),
		[history]
	);
	const baseScanId: string = queryParams.get(RoutesHelper.COMPARE_SCANS_PARAMS.BASE_SCAN_ID);
	const comparableScanId: string = queryParams.get(
		RoutesHelper.COMPARE_SCANS_PARAMS.COMPARABLE_SCAN_ID
	);

	const { propertyId } = useParams<{ propertyId: string }>();

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id,
		[properyViewContext]
	);
	const viewLoadErr: boolean = useMemo(() => properyViewContext?.loadViewErr, [properyViewContext]);

	const loadingCounter = useRef(0);

	const pagesOverviewItems: SummaryItem[] = useMemo(() => {
		if (!pagesOverview) {
			return initialPagesOverviewContent;
		}
		return [
			{
				value: `${FormatHelper.formatNumber(pagesOverview.baseScan.pageCount)} pages`,
				label: formatShortMonthDatetime(pagesOverview.baseScan.creationTime)
			},
			{
				value: `${FormatHelper.formatNumber(pagesOverview.commonPages)} pages`,
				label: 'Both scans (Common)',
				link: RoutesHelper.getCommonPagesPagePath(
					propertyId,
					baseScanId,
					comparableScanId,
					currentViewId
				)
			},
			{
				value: `${FormatHelper.formatNumber(pagesOverview.comparedScan.pageCount)} pages`,
				label: formatShortMonthDatetime(pagesOverview.comparedScan.creationTime)
			}
		];
	}, [pagesOverview, propertyId, baseScanId, comparableScanId, currentViewId]);

	const [issuesBreakdown, setIssuesBreakdown] = useState<IIssueStatusBreakdown>(null);

	const issuesOverviewItems: IIssuesSummaryItem[] = useMemo(() => {
		if (!issuesOverview) {
			return initialIssuesOverviewContent;
		}
		return [
			{ ...formatCounts(issuesOverview.new), label: ISSUE_COMPARISON_TYPE.NEW_ISSUES },
			{ ...formatCounts(issuesOverview.recurring), label: ISSUE_COMPARISON_TYPE.RECURRING_ISSUES },
			{ ...formatCounts(issuesOverview.resolved), label: ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES }
		];
	}, [issuesOverview]);

	const fetchComparisonScans = useCallback(async () => {
		setPageLoading(true);
		if (!baseScanId || !comparableScanId) {
			setError(true);
			logger.error(
				`Scan comparison page - baseScanId and/or comparableScanId parameters werent available in the url ${history.location.pathname}`
			);
			return;
		}
		try {
			const scans: IScan[] = await scansService.getCachedScansMetadata(propertyId);
			const base: IScan = scans.find((scan) => scan.id === baseScanId);
			const comparable: IScan = scans.find((scan) => scan.id === comparableScanId);
			setBaseScan(base);
			setComparableScan(comparable);
			setPageLoading(false);
		} catch (err) {
			setError(true);
			logger.error(
				`Scan comparison page - error loading scans metadata for property ${propertyId}`,
				err
			);
		}
	}, [baseScanId, comparableScanId, propertyId, history.location.pathname]);

	useEffect(() => {
		fetchComparisonScans();
	}, [fetchComparisonScans]);

	const currentRoute = useMemo(() => {
		if (!propertyId || !baseScan || !comparableScan) {
			return null;
		}

		return {
			id: propertyId,
			title: 'Scan Comparison'
		};
	}, [propertyId, baseScan, comparableScan]);

	useEffect(() => {
		if (currentRoute) {
			setCurrentRoute(currentRoute);
		}
		return () => {
			setCurrentRoute(null);
		};
	}, [currentRoute, setCurrentRoute]);

	const initIssuesOverview = useCallback(async () => {
		setIssuesOverview(null);
		if (!baseScanId || !comparableScanId) {
			return;
		}
		try {
			loadingCounter.current++;
			const issuesOverviewResponse: IIssuesOverview =
				await ScansComparisonService.getScanComparisonIssuesOverview(
					baseScanId,
					comparableScanId,
					commonOnly,
					currentViewId
				);
			loadingCounter.current--;
			setIssuesOverview(issuesOverviewResponse);
		} catch (err) {
			setError(true);
			logger.error(
				`Scan comparison page - error loading issues overview for base: ${baseScanId}, comparable: ${comparableScanId}`,
				err
			);
		}
	}, [baseScanId, comparableScanId, commonOnly, currentViewId]);

	const initPagesOverview = useCallback(async () => {
		if (!baseScanId || !comparableScanId) {
			return;
		}
		try {
			loadingCounter.current++;
			const pagesOverviewResponse: IPagesOverview =
				await ScansComparisonService.getScanComparisonPagesOverview(
					baseScanId,
					comparableScanId,
					currentViewId
				);
			loadingCounter.current--;
			setPagesOverview(pagesOverviewResponse);
		} catch (err) {
			setError(true);
			logger.error(
				`Scan comparison page - error loading pages overview for base: ${baseScanId}, comparable: ${comparableScanId}`,
				err
			);
		}
	}, [baseScanId, comparableScanId, currentViewId]);

	const initIssuesBreakdown = useCallback(async () => {
		if (!baseScanId || !comparableScanId) {
			return;
		}
		try {
			setIssuesBreakdown(null);
			loadingCounter.current++;
			const issuesBreakdownResponse = await ScansComparisonService.getScanComparisonIssuesBreakdown(
				baseScanId,
				comparableScanId,
				commonOnly,
				currentViewId
			);
			loadingCounter.current--;
			setIssuesBreakdown({
				newIssues: issuesBreakdownResponse.newIssues,
				reoccurringIssues: issuesBreakdownResponse.recurringIssues,
				resolvedIssues: issuesBreakdownResponse.resolvedIssues,
				baseDate: issuesBreakdownResponse.baseScan.creationTime,
				comparedDate: issuesBreakdownResponse.comparedScan.creationTime
			});
		} catch (err) {
			setError(true);
			logger.error(
				`Scan comparison page - error loading issues breakdown for base: ${baseScanId}, comparable: ${comparableScanId}`,
				err
			);
		}
	}, [baseScanId, comparableScanId, commonOnly, currentViewId]);

	useEffect(() => {
		initPagesOverview();
	}, [initPagesOverview]);

	useEffect(() => {
		initIssuesOverview();
	}, [initIssuesOverview]);

	useEffect(() => {
		initIssuesBreakdown();
	}, [initIssuesBreakdown]);

	useSkipLinks(!isPageLoading);

	const renderNavigationHeader = useMemo(
		(): JSX.Element => (
			<div className="page-navigation-headers">
				<EvTitle titleText="Scan Comparison" className="title" />
				{baseScan && comparableScan && (
					<div className={classNames('scan-dates')}>
						<span className="scan-date">{formatShortMonthDatetime(baseScan.createdTime)}</span>
						<span className="vs">&nbsp;vs.&nbsp;</span>
						<span className="scan-date">
							{formatShortMonthDatetime(comparableScan.createdTime)}
						</span>
					</div>
				)}
			</div>
		),
		[baseScan, comparableScan]
	);

	if (viewLoadErr) {
		return <PropertyViewLoadErrorPage propertyId={propertyId} />;
	}

	if (error) {
		return (
			<div className="page-content">
				<TrendsNoDataIndication />
			</div>
		);
	}

	if (isPageLoading) {
		return (
			<div className="page-content">
				<EvSpinner />
			</div>
		);
	}

	const toggleComparisonMode = (): void => {
		if (loadingCounter.current === 0) {
			setIssuesOverview(null);
			setCommonOnly((mode) => !mode);
		}
	};

	return (
		<div className="page-content">
			<EvSection
				ariaLabel="Scan comparison details"
				skipLinkId="scan-comparison-details"
				className="page-top-row"
			>
				<div className="header-main">
					{renderNavigationHeader}
					<div>
						<div className="filtered-views-controller">
							<EvPropertyViews propertyId={propertyId} />
						</div>
						<div className="comparison-pages-toggle">
							<EvToggle
								ariaLabel={getAriaLabelForTriggerButton(commonOnly)}
								checked={!commonOnly}
								onChange={toggleComparisonMode}
								options={['All Pages', 'Common']}
							/>
							<div className="info-balloon">
								<EvInfoTooltip title="Common pages only" tooltipPlace="bottom">
									Click on &apos;Common&apos; to focus your <br />
									comparison and include only the pages <br />
									that were analyzed in both scans.
								</EvInfoTooltip>
							</div>
						</div>
					</div>
				</div>
			</EvSection>
			<div className="main-content">
				<div className="scan-comparison-overview">
					<ScanComparisonIssueStatusBreakdown {...issuesBreakdown} isLoading={!issuesBreakdown} />
					<ScanComparisonSummaryPane
						pages={pagesOverviewItems}
						issues={issuesOverviewItems}
						commonOnly={commonOnly}
					/>
				</div>
				<EvSection ariaLabel="Scan comparison table" skipLinkId="scan-comparison-table">
					<EvTitle titleText="Compared Issues" />
					<CompareTablesSection
						baseScanId={baseScanId}
						comparableScanId={comparableScanId}
						viewId={currentViewId}
						issuesOverview={issuesOverview}
						propertyId={propertyId}
						commonOnly={commonOnly}
					/>
				</EvSection>
			</div>
		</div>
	);
};

export default ScansComparisonPage;
