import { EvTitle, TITLE_MODES } from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC } from 'react';
import LoadErrorMsgLayout from '../../../../pages/property/LoadErrorMsgLayout';
import NoPagesMatchIcon from '../../../icons/NoPagesMatchIcon.svg';
import ChartWrapper from '../chart-wrapper/ChartWrapper';

interface INoDataChartWrapper {
	children?: JSX.Element | JSX.Element[];
	headerTitle: string;
	mainTitle: string;
	secondaryTitle: string;
	className?: string;
}

const NoDataChartWrapper: FC<INoDataChartWrapper> = ({
	children,
	headerTitle,
	className,
	mainTitle,
	secondaryTitle
}) => {
	return (
		<div className={classNames('no-data-chart-wrapper', { [className]: !!className })}>
			<ChartWrapper title={headerTitle}>
				<EvTitle
					titleText={headerTitle}
					className="chart-title-no-match-pages"
					mode={TITLE_MODES.NORMAL}
					headingLevel={3}
				/>
				<div className="no-pages-match-wrapper">
					<LoadErrorMsgLayout
						mainTitle={mainTitle}
						secondaryMsg={secondaryTitle}
						icon={NoPagesMatchIcon}
					/>
					{children}
				</div>
			</ChartWrapper>
		</div>
	);
};

export default NoDataChartWrapper;
