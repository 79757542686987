import {
	BUTTON_TYPES,
	EvButton,
	EvConfirm,
	EvIcon,
	EvSection,
	EvTextInput,
	EvTitle,
	EvToast,
	TABLE_SR_SUMMARY_IDS,
	TOAST_STATUS
} from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { SITE_SCANNER_APP_ID } from '../../../../consts/dom-consts';
import domainInputPlaceholder from '../../../../consts/domainPlaceholderText';
import PropertyHelper from '../../../../helpers/PropertyHelper';
import SeedsListHelper from '../../../../helpers/SeedsListHelper';
import UrlNormalizingHelper from '../../../../helpers/UrlNormalizingHelper';
import UrlValidationHelper from '../../../../helpers/UrlValidationHelper';
import { IProperty } from '../../../../interfaces/Property';
import { IPropertyError } from '../../../../interfaces/PropertyError';
import { IUrlSeed } from '../../../../interfaces/UrlSeed';
import { IUrlSeedDisplay } from '../../../../interfaces/UrlSeedDisplay';
import { SetPropertyType } from '../../../../pages/property-settings/PropertySettingsPageTypes';
import SECTION_TYPES from '../../../../types/SettingsSectionTypes';
import PlusIcon from '../../../icons/PlusIcon.svg';
import PropertyImportUrlsButton from '../../propery-import-urls-button/PropertyImportUrlsButton';
import './SeedsList.scss';
import SeedsTable from './SeedsTable';

const SEED_LIST_TITLE = 'seeds-list-title';
const SEED_LIST_DESCRIPTION = 'seeds-list-description';

interface ISeedsListProps {
	title?: string;
	property: IProperty;
	propertyError?: IPropertyError;
	removePropertyError: (sectionId: SECTION_TYPES) => void;
	isListMode: boolean;
	setProperty: SetPropertyType;
}

const SeedsList: FC<ISeedsListProps> = ({
	title = 'Seeds list',
	property,
	propertyError,
	removePropertyError,
	isListMode,
	setProperty
}: ISeedsListProps) => {
	const [seedEntry, setSeedEntry] = useState('');
	const [seedError, setSeedError] = useState('');
	const [selectedSeeds, setSelectedSeeds] = useState([]);
	const [isImportInProgress, setIsImportInProgress] = useState(false);
	const [toastStatus, setToastStatus] = useState(TOAST_STATUS.SUCCESS);
	const [importAnnouncement, setImportAnnouncement] = useState<string | JSX.Element>('');
	const [showImportToast, setShowImportToast] = useState(false);

	let seeds: IUrlSeedDisplay[] = PropertyHelper.getPropertyDiscoverySeeds(property);

	if (isListMode) {
		seeds = PropertyHelper.getPropertyListModeSeeds(property);
	}

	const setUrlRegex = (value): void => {
		setSeedEntry(value);
		if (seedError) {
			setSeedError('');
		}
	};

	const getPropertySeeds = (): { url: string; id: number }[] => {
		let seeds = [];

		if (isListMode && property.urls) {
			seeds = property.urls.map((url) => ({ url }));
		} else if (!isListMode) {
			seeds = [...PropertyHelper.getPropertyDiscoverySeeds(property)];
		}
		return seeds;
	};

	const addSeedToProperty = (seed: string): void => {
		const newSeed = UrlNormalizingHelper.formatURL(seed);
		const isValidUrl = UrlValidationHelper.validateUrlFormat(newSeed);

		if (!isValidUrl.isValid) {
			setSeedError(isValidUrl.errorMessages[0]); // show one error at a time
			return;
		}

		const seeds = getPropertySeeds();

		const seedAlreadyExists = seeds.find((s) => UrlNormalizingHelper.formatURL(s.url) === newSeed);
		if (!seedAlreadyExists) {
			let newProperty: IProperty = null;
			if (isListMode) {
				const urls = seeds.map(({ url }) => url);
				newProperty = { ...property };
				newProperty.urls = [...urls, newSeed];
			} else {
				newProperty = PropertyHelper.setPropertyDiscoverySeeds(property, [
					...seeds,
					{ url: newSeed }
				]);
			}

			setProperty(newProperty);
			setSeedEntry('');
			setSeedError('');
			removePropertyError(SECTION_TYPES.SEEDS);
		} else {
			setSeedError(`This ${isListMode ? 'URL' : 'seed'} is already in the list`);
		}
	};

	const updatePropertySeeds = (seedsList, closeModal): void => {
		let newProperty: IProperty = null;
		if (isListMode) {
			newProperty = { ...property };
			newProperty.urls = seedsList.map((s) => s.url);
		} else {
			const newSeedsList: IUrlSeed[] = seedsList.map((s) => ({ url: s.url }));
			newProperty = PropertyHelper.setPropertyDiscoverySeeds(property, newSeedsList);
		}

		setProperty(newProperty);
		setSelectedSeeds([]);
		closeModal();
		setIsImportInProgress(false);
	};

	const renderMoreInfo = (): JSX.Element => {
		return isListMode ? (
			<>
				List mode allows you to skip the discovery phase (crawling) and provide a static list of
				URLs for the system to scan.
			</>
		) : (
			<>Seed URLs are the entry point for the crawler.</>
		);
	};

	const onToastClose = (): void => {
		setShowImportToast(false);
	};

	const onImport = (closeModal: () => void, browsedFileContent: []): void => {
		setIsImportInProgress(true);
		closeModal();
		setTimeout(() => {
			const { validUrls } = SeedsListHelper.getUrlsFromCSV([...browsedFileContent]);

			const browsedCount = browsedFileContent.length;
			/* error count = total browsed from minus valid new seeds */
			const errCount = browsedCount - validUrls.length;

			const seeds = getPropertySeeds();
			let mergedSeeds;
			let newCount = validUrls.length || 0;
			if (validUrls.length > 0) {
				mergedSeeds = SeedsListHelper.removeDuplications([...seeds, ...validUrls]);

				/* new urls count = total after merge minus number of existing seeds */
				newCount = mergedSeeds.length - seeds.length;
			} else {
				mergedSeeds = seeds;
			}
			/* duplicates count = all valid minus new */
			const dupCount = validUrls.length - newCount;

			setImportAnnouncement(SeedsListHelper.buildAnnouncement(newCount, errCount, dupCount));
			if (newCount > 0 && errCount === 0) {
				// there are no error and there is success
				setToastStatus(TOAST_STATUS.SUCCESS);
			} else if (newCount > 0 && errCount > 0) {
				// imported urls and errors
				setToastStatus(TOAST_STATUS.INFO);
			} else if (newCount === 0 && errCount > 0) {
				// no imported urls but there are errors
				setToastStatus(TOAST_STATUS.FAIL);
			} else {
				setToastStatus(TOAST_STATUS.INFO);
			}
			setShowImportToast(true);
			updatePropertySeeds(mergedSeeds, closeModal);
		}, 1500);
	};

	const tableHeaderActions = (): JSX.Element => {
		const selectedLength = selectedSeeds.length;
		const isSelectedEmpty = selectedLength === 0;
		const isSelectedMultiple = selectedLength > 1;
		const isAllSelected = selectedLength === seeds.length;
		const urlText = `${
			isSelectedMultiple || (isAllSelected && isSelectedMultiple) ? 'URLs' : 'URL'
		}`;
		const selectedCountText = isSelectedMultiple ? selectedLength : '';
		const buttonTitle = `Remove ${
			isAllSelected && isSelectedMultiple ? 'All' : selectedCountText
		} ${urlText}`;

		return (
			<div className="table-actions-header">
				{showImportToast && (
					<EvToast
						announcement={importAnnouncement}
						status={toastStatus}
						show={showImportToast}
						onToastClose={onToastClose}
					/>
				)}
				{isListMode && (
					<PropertyImportUrlsButton
						importing={isImportInProgress}
						validHeader="urls"
						onConfirm={onImport}
					/>
				)}
				<EvConfirm
					appElement={SITE_SCANNER_APP_ID}
					title="Confirm removing urls"
					promptMessage={`Are you sure you want to ${buttonTitle.toLocaleLowerCase()}?`}
					triggerButtonProps={{
						type: BUTTON_TYPES.SECONDARY,
						title: buttonTitle,
						disabled: isSelectedEmpty,
						customTooltip: isSelectedEmpty ? { renderTooltip: true } : null,
						disabledReason: 'Check URLs to enable this button',
						children: buttonTitle
					}}
					onConfirm={(closeModal) => {
						let newSeeds = [...seeds];
						newSeeds = newSeeds.filter((s) => {
							return !selectedSeeds.find((selected) => selected.url === s.url);
						});
						updatePropertySeeds(newSeeds, closeModal);
					}}
				/>
				<EvConfirm
					appElement={SITE_SCANNER_APP_ID}
					title="Confirm removing all urls"
					promptMessage={`Are you sure you want to clear all URLs? (${seeds.length})`}
					triggerButtonProps={{
						type: BUTTON_TYPES.ACTION,
						title: 'Clear list',
						children: 'Clear list'
					}}
					onConfirm={(closeModal) => {
						updatePropertySeeds([], closeModal);
					}}
				/>
			</div>
		);
	};

	const renderAddSeed = (): JSX.Element => {
		const placeHolder = `Add URL (${domainInputPlaceholder})`;
		return (
			<div className="add-seed">
				<div className="seed-input">
					<EvTextInput
						ariaLabel={placeHolder}
						error={seedError}
						placeholder={placeHolder}
						value={seedEntry}
						onChange={setUrlRegex}
						onEnter={() => {
							addSeedToProperty(seedEntry);
						}}
					/>
					{propertyError ? <div className="seed-error">{propertyError.message}</div> : null}
				</div>
				<EvButton
					className="add-seed-button"
					type={BUTTON_TYPES.ICON}
					title="Add seed"
					onClick={() => {
						addSeedToProperty(seedEntry);
					}}
				>
					<EvIcon icon={PlusIcon} />
				</EvButton>
				{tableHeaderActions()}
			</div>
		);
	};

	return (
		<section
			className="seeds-list-section"
			aria-labelledby={SEED_LIST_TITLE}
			aria-describedby={SEED_LIST_DESCRIPTION}
		>
			<div className="section-header">
				<div className="title">
					<EvTitle
						id={SEED_LIST_TITLE}
						descriptionId={SEED_LIST_DESCRIPTION}
						titleText={title}
						MoreInfoContent={renderMoreInfo()}
					/>
				</div>
				{renderAddSeed()}
			</div>
			<EvSection
				className="section-body"
				ariaLabel="Seeds table"
				ariaLive="polite"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
			>
				<SeedsTable
					seeds={seeds}
					selectedSeeds={selectedSeeds}
					updatePropertySeeds={updatePropertySeeds}
					setSelectedSeeds={setSelectedSeeds}
					propertyId={property.id}
					isImporting={isImportInProgress}
				/>
			</EvSection>
		</section>
	);
};

export default SeedsList;
