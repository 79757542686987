// copied from: https://github.com/vixnguyen/timezone-select/blob/main/src/tz.helper.js
// only change are the TS types
import { OptionType } from '@evinced-private/ui-common';
import { Timezone, TIMEZONES } from './Timezones';

type ProcessedTimezone = {
	value: string;
	label: string;
	included: string[];
	country: string;
	offset: string;
	offsetValue: number;
};

const findTzByKey = (key: string): Timezone => {
	return TIMEZONES.find((item) => item.name === key);
};

const newTzItem = ({ data, displayName, offset }): ProcessedTimezone => {
	return {
		value: data.name,
		label: `(GMT${data.offset}) ${displayName}`,
		included: [data.name],
		country: data.country,
		offset: data.offset,
		offsetValue: offset
	};
};

const clientTz = (): string => {
	const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const clientTzObj = findTzByKey(clientTz);
	return clientTzObj.status === 'Deprecated' ? clientTzObj.link : clientTz;
};

const proceedDependences = (item: Timezone): { key; displayName; offset } => {
	// proceed name offset
	const offsetParts = item.offset.split(/\d+/g);
	let offsetPrefix;
	if (offsetParts[0] === '+') {
		offsetPrefix = '+';
	} else {
		offsetPrefix = '-';
	}
	item.offset = item.offset.replace(offsetParts[0], offsetPrefix);
	const offset = +item.offset.replace(':', '');

	// proceed name
	const nameArr = item.name.split('/').slice(1);
	const displayName = nameArr.join('-').replace(/_/g, ' ');

	// proceed key
	const key = `${item.country}_${item.offset}`;
	return { key, offset, displayName };
};

const listTz = (): ProcessedTimezone[] => {
	const newTz: { [key: string]: ProcessedTimezone } = TIMEZONES.reduce((obj, item) => {
		if (item.status === 'Deprecated') {
			if (item.link) {
				// proceed tz item by using linking item
				const linkingItem = findTzByKey(item.link);
				const { key, displayName, offset } = proceedDependences(linkingItem);
				if (obj[key]) {
					obj[key].included.push(item.name);
				} else {
					obj[key] = newTzItem({ data: linkingItem, displayName, offset });
				}
			}
		} else if (item.country === '') {
			// todo
		} else {
			// proceed tz item
			const { key, displayName, offset } = proceedDependences(item);
			if (obj[key]) {
				if (obj[key].included && !obj[key].included.includes(item.name)) {
					obj[key].label += `, ${displayName}`;
					obj[key].included.push(item.name);
				}
			} else {
				obj[key] = newTzItem({ data: item, displayName, offset });
			}
		}
		return obj;
	}, {});
	return Object.values(newTz).sort((a, b) => {
		return a.offsetValue - b.offsetValue;
	});
};

// Additions to the copied file - these functions should be used by Evinced
const getTimezoneOptions = (): OptionType[] => {
	const options = listTz();
	return options.map((o) => ({ label: o.label, value: o.value }));
};

const getClientTimezoneValue = (): string => {
	return clientTz();
};

const getGMTTimezoneValue = (): string => {
	return 'Etc/GMT';
};

export default { getClientTimezoneValue, getTimezoneOptions, getGMTTimezoneValue };
