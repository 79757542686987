import {
	EvLinkTypes,
	EvTableColumn,
	EvTableOptions,
	SORT_ORDER,
	TableSortOption,
	TypesCellFormatter
} from '@evinced-private/ui-common';
import React from 'react';
import renderNumberCellFormatter from '../../../../components/common/ev-table/cell-formatters/numberCellFormatter';
import { ScanTableDefinition } from '../../../../components/scan-analysis/base/BaseScanViewV2';
import EvLinkLocal from '../../../../components/common/ev-link-local/EvLinkLocal';
import ScreenshotCellFormatter from '../../../../components/scan-analysis/table-formatters/screenshot-cell-formatter/ScreenshotCellFormatter';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import {
	ComponentData,
	ComponentDataSampleIssue
} from '../../../../interfaces/ComponentsPaginationResponse';
import ShowDetailsCellFormatter from '../../../../components/show-details-cell-formatter/ShowDetailsCellFormatter';

const getDefaultSort = (): TableSortOption => ({
	dataField: 'totalIssues',
	order: SORT_ORDER.DESC
});

const getColumns = (propertyId: string, scanId: string, pageId: string): EvTableColumn[] => {
	return [
		{
			dataField: 'id',
			text: 'Component',
			style: { width: '300px' },
			headerStyle: { width: '300px' },
			formatter: (componentId: string) => {
				return (
					<EvLinkLocal
						id={componentId}
						url={RoutesHelper.getComponentDrilldownPath(propertyId, scanId, componentId)}
						ariaLabel={`${componentId} Show details`}
						linkText={componentId}
						type={EvLinkTypes.DARK}
						pageId={pageId}
					/>
				);
			}
		},
		{
			dataField: 'totalIssues',
			text: 'Total issues',
			style: { width: '130px' },
			headerStyle: { width: '130px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'totalPages',
			text: 'Pages impacted',
			style: { width: '130px' },
			headerStyle: { width: '130px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'issueTypes',
			text: 'Issue types',
			style: { width: '130px' },
			headerStyle: { width: '130px' },
			formatter: (issueTypes: string[]) => {
				return <TypesCellFormatter validationTypes={issueTypes} />;
			}
		},
		{
			dataField: 'sample',
			text: 'Example',
			style: { width: '250px' },
			headerStyle: { width: '250px' },
			classes: 'image-cell-td',
			formatter: (sample: ComponentDataSampleIssue, row: ComponentData) => {
				return (
					<ScreenshotCellFormatter
						cellDescriptor={`Component ${row.id} sample issue`}
						screenshotDetails={{
							pageScreenshotUrl: sample.pageScreenshotUrl,
							boundingBoxes: [sample.boundingBox]
						}}
					/>
				);
			}
		},
		{
			dataField: '',
			text: '',
			style: { width: '70px', paddingRight: '10px', paddingLeft: '20px' },
			headerStyle: { width: '70px' },
			formatter: (cell, component: ComponentData) => {
				const sampleIssue = component.sample;
				return (
					<ShowDetailsCellFormatter
						componentDetails={{
							componentId: component.id,
							totalIssues: component.totalIssues,
							totalPages: component.totalPages
						}}
						issue={{ id: sampleIssue.issueId }}
						rowId={component.id}
						scanId={scanId}
					/>
				);
			}
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Scan components',
		caption: 'Scan components table',
		pagination: true,
		dataType: 'components',
		remote: true,
		defaultSorted: [getDefaultSort()]
	};
};

const getTableDefinition = (
	components: ComponentData[],
	propertyId: string,
	scanId: string,
	totalCount: number,
	totalPaginationableResults: number,
	pageId?: string
): ScanTableDefinition => {
	const options = getOptions();
	const result: ScanTableDefinition = {
		columns: getColumns(propertyId, scanId, pageId),
		options,
		data: components,
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition };
