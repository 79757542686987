import ServerApi from '../api/ServerApi';
import { callGETAndCache } from '../api/ServerCacheApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { INewView, IPropertyView, ISelectedViewResponse } from '../interfaces/PropertyViews';
import Logger from './Logger';

const { PROPERTY_VIEWS_PATH, PROPERTIES_URL_PATH } = API_URL_PATHS;

const getPropertyViewsPath = (propertyId: string): string => {
	return `${PROPERTY_VIEWS_PATH}?propertyId=${propertyId}`;
};

const getIssueTypesList = async (): Promise<string[]> => {
	return callGETAndCache('issue-types');
};

const getViews = async (propertyId: string): Promise<IPropertyView[]> => {
	try {
		const url = `${PROPERTY_VIEWS_PATH}?propertyId=${propertyId}&transformOnlyMetadata=false`;
		return ServerApi.apiCall('GET', url);
	} catch (err) {
		Logger.error('Failed to get Views from server', err);
		throw new Error(`Failed to get Views from server. Error ${err}`);
	}
};

const getViewById = async (viewId: string): Promise<IPropertyView> => {
	try {
		const res = await ServerApi.apiCall('GET', `${PROPERTY_VIEWS_PATH}/${viewId}`);
		return res;
	} catch (err) {
		Logger.error('Failed to get View by Id from server', err);
		throw new Error(`Failed to get View by Id from server. Error: ${err}`);
	}
};

const createSelectedView = async (
	proeprtyId: string,
	viewId: string
): Promise<ISelectedViewResponse> => {
	const body = { viewId };
	try {
		const res: ISelectedViewResponse = await ServerApi.apiCall(
			'POST',
			`${PROPERTIES_URL_PATH}/${proeprtyId}/view-selection`,
			{ data: body }
		);
		return res;
	} catch (err) {
		Logger.error('Failed to create selected View', err);
		throw new Error('Failed to create selected View');
	}
};

const updateSelectedView = async (
	proeprtyId: string,
	viewId: string,
	id: string
): Promise<ISelectedViewResponse> => {
	const body = { proeprtyId, viewId, id };
	const res: ISelectedViewResponse = await ServerApi.apiCall(
		'PUT',
		`${PROPERTIES_URL_PATH}/${proeprtyId}/view-selection/${id}`,
		{
			data: body
		}
	);
	return res;
};

/**
 * @param propertyId
 * @returns:
 * Empty Array - No Selected was created ever
 * [{id, propertyId}] - No viewId (null) = All Content
 * [{id, viewId, propertyId}] - There's selected view
 */
const getSelectedView = async (propertyId: string): Promise<ISelectedViewResponse[]> => {
	const res: ISelectedViewResponse[] = await ServerApi.apiCall(
		'GET',
		`${PROPERTIES_URL_PATH}/${propertyId}/view-selection`
	);
	return res;
};

const deleteView = async (viewId: string): Promise<IPropertyView> => {
	return ServerApi.apiCall('DELETE', `${PROPERTY_VIEWS_PATH}/${viewId}`);
};

export const createView = async (view: INewView): Promise<IPropertyView> => {
	const res = await ServerApi.apiCall('POST', PROPERTY_VIEWS_PATH, { data: view });
	return res;
};

export const updateView = async (view: IPropertyView): Promise<IPropertyView> => {
	const res = await ServerApi.apiCall('PUT', `${PROPERTY_VIEWS_PATH}/${view.id}`, {
		data: view
	});
	return res;
};

export default {
	getPropertyViewsPath,
	getViews,
	getSelectedView,
	createSelectedView,
	deleteView,
	createView,
	updateView,
	updateSelectedView,
	getViewById,
	getIssueTypesList
};
