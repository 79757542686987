import { BUTTON_TYPES, IEvButtonProps } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { formatDate } from '../../helpers/DateFormatHelper';
import { IProperty } from '../../interfaces/Property';
import { IUrlSet } from '../../interfaces/UrlSet';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import logger from '../../services/Logger';

import ScanCrawlTextHelper from '../../helpers/ScanCrawlTextHelper';
import urlSetService from '../../services/UrlSetService';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import './DeleteCrawlPopup.scss';

interface IDeleteCrawlPopup {
	/** urlset id */
	id: string;
	/** property */
	property: IProperty;
	/** the button that this popup will open upon click */
	triggerButtonProps: IEvButtonProps;
	/* callback to be used after deletion */
	afterUrlSetDeleted: (id: string) => Promise<void>;
}

const DeleteCrawlPopup: FC<IDeleteCrawlPopup> = ({
	id,
	property,
	triggerButtonProps,
	afterUrlSetDeleted
}: IDeleteCrawlPopup) => {
	const [rollbackUrlSet, setRollbackUrlSet] = useState<IUrlSet>(null);
	const [isLoading, setLoading] = useState(true);
	const [isDeleteInProgress, setDeleteInProgress] = useState(false);
	const notificationsContext = useNotifications();

	const loadPropertyUrlSets = (): void => {
		setLoading(true);
		setDeleteInProgress(false);
		urlSetService
			.getLatestSuccessfulUrlSet(property.id)
			.then((urlSet: IUrlSet) => {
				setRollbackUrlSet(urlSet || null);
			})
			.catch((err) => {
				logger.error('Could not get last successfull urlset', err);
				notificationsContext.alert({ serverError: err });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onApplyClick = async (onClose): Promise<void> => {
		setDeleteInProgress(true);
		try {
			await urlSetService.deleteUrlSet(id);
			await afterUrlSetDeleted(id);
			onClose();
		} catch (err) {
			logger.error(`Error while deleting UrlSet ${id}`, err);
			notificationsContext.alert({ serverError: err });
			onClose();
		} finally {
			setDeleteInProgress(false);
		}
	};

	const buttons = [
		{
			disabled: isLoading,
			title: 'Close',
			type: BUTTON_TYPES.SECONDARY,
			onClick: (onClose) => {
				logger.info('Confirm delete canceled');
				onClose();
			}
		},
		{
			disabled: isLoading,
			title: ScanCrawlTextHelper.getConfirmButtonText('crawl'),
			onClick: onApplyClick,
			loading: isDeleteInProgress
		}
	];

	const renderBody = (): string => {
		if (rollbackUrlSet) {
			const formattedDate = formatDate(rollbackUrlSet.createdTime);
			return `Are you sure you want to cancel this crawl and revert to the crawl results from ${formattedDate}?`;
		}

		return 'Are you sure you want to cancel this crawl?';
	};

	return (
		<SiteScannerPopup
			triggerButtonProps={triggerButtonProps}
			title="Confirm crawl cancellation"
			isCentered
			isPopupLoading={isLoading}
			buttons={buttons}
			onOpen={loadPropertyUrlSets}
		>
			<div className="delete-crawl-popup-content">
				<span className="popup-message">{renderBody()}</span>
			</div>
		</SiteScannerPopup>
	);
};

export default DeleteCrawlPopup;
