import { EvSpinner, EvTitle, TableSortOption, useSkipLinks } from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CommonPagesBreakdownTableHelper from '../../components/common-pages-breakdown-table/CommonPagesBreakdownTableHelper';
import EvPropertyViews from '../../components/common/ev-property-views/EvPropertyViews';
import { ScanTableDefinition } from '../../components/scan-analysis/base/BaseScanViewV2';
import TrendsNoDataIndication from '../../components/trends-no-data-indication/TrendsNoDataIndication';
import { formatShortMonthDatetime } from '../../helpers/DateFormatHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IScan } from '../../interfaces/Scan';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import {
	INavigationRoute,
	useNavigation
} from '../../providers/navigationProvider/NavigationProvider';
import logger from '../../services/Logger';
import ScansComparisonService, {
	ICompareScansPagesPaginationResponse
} from '../../services/scan/ScansComparisonService';
import scansService from '../../services/scan/ScansService';
import { FilterParams } from '../../types/FilterParams';
import { PaginationParams } from '../../types/PaginationParams';
import './ScansComparisonPages.scss';
import TableWithFilters from './components/TableWithFilters';

const defaultSort: TableSortOption = CommonPagesBreakdownTableHelper.getDefaultSort();

const CommonPagesPage: FC = () => {
	const history = useHistory();
	const [isPageLoading, setPageLoading] = useState(true);
	const [commonPagesResponse, setCommonPagesResponse] =
		useState<ICompareScansPagesPaginationResponse>(null);
	const [error, setError] = useState(false);
	const [totalCountWithoutFilter, setTotalCountWithoutFilter] = useState<number>();
	const [baseScan, setBaseScan] = useState<IScan>();
	const [comparableScan, setComparableScan] = useState<IScan>();
	const { setCurrentRoute } = useNavigation();
	const queryParams: URLSearchParams = useMemo(
		() => new URLSearchParams(history.location.search),
		[history]
	);
	const baseScanId: string = queryParams.get(RoutesHelper.COMPARE_SCANS_PARAMS.BASE_SCAN_ID);
	const comparableScanId: string = queryParams.get(
		RoutesHelper.COMPARE_SCANS_PARAMS.COMPARABLE_SCAN_ID
	);
	const { propertyId } = useParams<{ propertyId: string }>();

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id,
		[properyViewContext]
	);

	const screenId: string = useMemo(() => {
		return `scan-compare-view-${currentViewId}-${baseScanId}-${comparableScanId}-common-pages`;
	}, [baseScanId, comparableScanId, currentViewId]);

	useSkipLinks(false);

	const initialLoad = useCallback(async () => {
		setPageLoading(true);
		if (!baseScanId || !comparableScanId) {
			setError(true);
			logger.error(
				`Scan comparison page - baseScanId and/or comparableScanId parameters werent available in the url ${history.location.pathname}`
			);
			return;
		}
		try {
			const [scans, responseWithoutFilters] = await Promise.all([
				scansService.getCachedScansMetadata(propertyId, currentViewId),
				ScansComparisonService.getCommonPagesIssues({
					baseScanId,
					comparableScanId,
					viewId: currentViewId
				})
			]);
			setTotalCountWithoutFilter(responseWithoutFilters.totalResults);
			const base: IScan = scans.find((scan) => scan.id === baseScanId);
			const comparable: IScan = scans.find((scan) => scan.id === comparableScanId);
			setBaseScan(base);
			setComparableScan(comparable);
			setPageLoading(false);
		} catch (err) {
			setError(true);
			logger.error(
				`Scan comparison page - error loading scans metadata for property ${propertyId}`,
				err
			);
		}
	}, [baseScanId, comparableScanId, propertyId, currentViewId, history.location.pathname]);

	useEffect(() => {
		initialLoad();
	}, [initialLoad]);

	const currentRoute: INavigationRoute = useMemo(() => {
		if (!propertyId || !baseScan || !comparableScan) {
			return null;
		}

		return {
			id: propertyId,
			title: 'Common Pages List'
		};
	}, [propertyId, baseScan, comparableScan]);

	useEffect(() => {
		if (currentRoute) {
			setCurrentRoute(currentRoute);
		}
		return () => {
			setCurrentRoute(null);
		};
	}, [currentRoute, setCurrentRoute]);

	const loadDataForTable = useCallback(
		async (
			baseScanId: string,
			comparableScanId: string,
			paginationParams: PaginationParams,
			filtersParams: FilterParams
		): Promise<void> => {
			try {
				const paginationResponse: ICompareScansPagesPaginationResponse =
					await ScansComparisonService.getCommonPagesIssues({
						baseScanId,
						comparableScanId,
						paginationParams,
						filtersParams,
						viewId: currentViewId
					});
				setCommonPagesResponse(paginationResponse);
			} catch (err) {
				setError(true);
				logger.error(
					`Scan comparison page - error loading issues for base: ${baseScanId}, comparable: ${comparableScanId}`,
					err
				);
			}
		},
		[currentViewId]
	);

	const navigationHeader: JSX.Element = useMemo(
		() => (
			<div className="page-navigation-headers">
				<EvTitle titleText="Common Pages List" className="title" />
				{baseScan && comparableScan && (
					<div className="scan-dates">
						<span className="scan-date">{formatShortMonthDatetime(baseScan.createdTime)}</span>
						<span className="vs">&nbsp;vs.&nbsp;</span>
						<span className="scan-date">
							{formatShortMonthDatetime(comparableScan.createdTime)}
						</span>
					</div>
				)}
			</div>
		),
		[baseScan, comparableScan]
	);

	const tableDefinition: ScanTableDefinition = useMemo(() => {
		return (
			commonPagesResponse &&
			CommonPagesBreakdownTableHelper.getTableDefinition(
				commonPagesResponse.pages,
				totalCountWithoutFilter,
				commonPagesResponse.totalResults
			)
		);
	}, [commonPagesResponse, totalCountWithoutFilter]);

	if (error) {
		return (
			<div className="page-content">
				<TrendsNoDataIndication />
			</div>
		);
	}

	if (isPageLoading) {
		return (
			<div className="page-content">
				<EvSpinner />
			</div>
		);
	}

	return (
		<div className="page-content">
			<div className="common-pages-header-container with-breadcrumbs">
				{navigationHeader}
				<EvPropertyViews propertyId={propertyId} />
			</div>

			<TableWithFilters
				defaultTableSort={defaultSort}
				tableId={screenId}
				filtersId={screenId}
				baseScanId={baseScanId}
				comparableScanId={comparableScanId}
				tableDefinition={tableDefinition}
				loadTableItems={loadDataForTable}
			/>
		</div>
	);
};

export default CommonPagesPage;
