import { EvLinkNavigationTabs } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getCurrentTab } from '../../helpers/dashboard/DashboardHelper';
import { HOME_PAGE_TABS } from './HomePageTypes';

const HomePageNavigation: FC = (): JSX.Element => {
	const history = useHistory();
	const location = useLocation();

	return (
		<nav className="home-page-navigation">
			<EvLinkNavigationTabs
				selectedTabId={getCurrentTab(location.pathname)}
				options={HOME_PAGE_TABS.map((page) => {
					return {
						onClick: () => history.push(page.route),
						title: page.label,
						id: page.key,
						url: page.route
					};
				})}
			/>
		</nav>
	);
};

export default HomePageNavigation;
