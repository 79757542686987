import { FormatHelper } from '@evinced-private/ui-common';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IIssuesPaginationResponse } from '../../../../interfaces/IssuesPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { IssueTypeDrilldownSummaryV2 } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';
import ScanBreakdownTableHelperV2 from '../breakdown/ScanBreakdownTableHelperV2';

interface IIssueTypeDrillDownView {
	viewId: string;
	scanId: string;
	propertyId: string;
	issueType: string;
}

const defaultTableSort = ScanBreakdownTableHelperV2.getDefaultSort();

const IssueTypeDrillDownView: FC<IIssueTypeDrillDownView> = ({
	viewId,
	scanId,
	propertyId,
	issueType
}: IIssueTypeDrillDownView) => {
	const [scanResults, setScanResults] = useState<IIssuesPaginationResponse>(null);
	const [totalCount, setTotalCount] = useState<number>(null);

	const [summaryPaneInfo, setSummaryPaneInfo] = useState<IssueTypeDrilldownSummaryV2>();

	const screenId = `scan-${scanId}-view-${viewId}-issue-type-${issueType}-drilldown-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams: FilterParams
		): Promise<void> => {
			if (!filtersParams.filterValues) {
				filtersParams.filterValues = {};
			}
			filtersParams.filterValues.types = [{ value: issueType, label: issueType }];
			const response: IIssuesPaginationResponse = await scanService.getScanIssuesV2(
				scanId,
				paginationParams,
				{ ...filtersParams },
				viewId
			);
			setScanResults(response);
		},
		[issueType, viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo = await scanService.getIssueTypeDrilldownSummaryV2(
			scanId,
			issueType,
			viewId
		);
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, issueType, viewId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		return [
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.pagesCount),
				label: 'Pages impacted'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.totalIssuesCount),
				label: 'Total issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.critical),
				label: 'Critical issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.serious),
				label: 'Serious issues'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.componentsCount),
				label: 'Components'
			}
		];
	}, [summaryPaneInfo]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { issues } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;
		return ScanBreakdownTableHelperV2.getTableDefinition(
			issues,
			totalCount,
			totalPaginationableResults
		);
	}, [scanResults, totalCount]);

	return (
		<BaseScanViewV2
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			filtersDefinition={{ types: false, freeTextSearch: true, severities: true }}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
			propertyId={propertyId}
			renderCsvExportButton={true}
			tableWithUrlSwitcher={true}
		/>
	);
};

export default observer(IssueTypeDrillDownView);
