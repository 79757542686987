import { useEffect, useState } from 'react';

// We had to add this because react-browser's prompt does not handle refresh
// code inspired by https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router/37937895
export const useBlockRefresh = (): {
	shouldBlockNavigation: boolean;
	setShouldBlockNavigation: (state: boolean) => void;
} => {
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

	useEffect(() => {
		if (shouldBlockNavigation) {
			window.onbeforeunload = () => true;
		} else {
			window.onbeforeunload = undefined;
		}
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [shouldBlockNavigation]);

	return {
		shouldBlockNavigation,
		setShouldBlockNavigation
	};
};
