import ServerApi from '../api/ServerApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { IUser } from '../interfaces/User';

const { USER_URL_PATH } = API_URL_PATHS;

async function getUserMetadata(): Promise<IUser> {
	return ServerApi.apiCall('GET', USER_URL_PATH);
}

export default { getUserMetadata };
