import {
	BarDatum,
	EvBarChart,
	EvChartTooltip,
	EVINCED_SEVERITIES,
	EVINCED_SEVERITIES_IDS,
	PatternData
} from '@evinced-private/ui-common';
import sumBy from 'lodash/sumBy';
import React, { FC, useMemo } from 'react';
import ColorsHelper from '../../../helpers/charts/ColorsHelper';
import ChartsHelper from '../../../helpers/ChartsHelper';
import ValidationHelper from '../../../helpers/ValidationHelper';
import { IssueSeverities } from '../../../interfaces/IssuesOverviewResponse';
import './EvincedAdvantageBar.scss';

interface IEvincedAdvantageBarProps {
	severitiesData: IssueSeverities[];
	totalCriticalIssues: number;
}

const DEFAULT_CHART_HEIGHT = 250;
const DEFAULT_CHART_WIDTH = 400;

const EvincedAdvantageBar: FC<IEvincedAdvantageBarProps> = ({
	severitiesData,
	totalCriticalIssues
}) => {
	const barData = useMemo((): BarDatum[] => {
		const criticalIssuesOverview = severitiesData.find(
			(issueSeverity) => issueSeverity.severity === EVINCED_SEVERITIES.CRITICAL
		);
		const nonEvincedCriticalIssues = criticalIssuesOverview.types.filter(
			({ type }) => !ValidationHelper.isValidationOfEvincedType(type)
		);
		const nonEvincedCriticalIssuesCount = sumBy(nonEvincedCriticalIssues, 'issuesCount');
		const advantage = ChartsHelper.getEvincedAdvantage(
			totalCriticalIssues,
			nonEvincedCriticalIssuesCount
		);

		return [
			{
				id: 'EVINCED',
				label: 'Evinced',
				count: totalCriticalIssues,
				advantage
			},
			{
				id: 'OTHER',
				label: 'Other tools',
				count: nonEvincedCriticalIssuesCount
			}
		];
	}, [totalCriticalIssues, severitiesData]);

	const formatLabel = (d): string => {
		const isEvincedBar = d.indexValue === 'Evinced' && d.data.advantage;
		return `${d.value} ${isEvincedBar ? `(${d.data.advantage})` : ''}`;
	};

	const tooltipRenderer = (d): JSX.Element => {
		return <EvChartTooltip label={`${d.value} critical issues`} value="" />;
	};

	const patterns = useMemo(() => {
		return barData.map(
			(bar) =>
				({
					id: bar.id,
					label: bar.label,
					color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_IDS.CRITICAL]
				} as PatternData)
		);
	}, [barData]);

	const customSrSummaryRenderer = (): JSX.Element => {
		const { advantage, count: evinced } = barData[0];
		const { count: others } = barData[1];
		const title = `Evinced has detected ${advantage} critical issues than other tools.`;
		return (
			<>
				{title}
				{`Evinced: ${evinced}`}
				{`OtherTools: ${others}`}
			</>
		);
	};

	return (
		<EvBarChart
			width={DEFAULT_CHART_WIDTH}
			height={DEFAULT_CHART_HEIGHT}
			title="Evinced advantage: auto detection of critical issues"
			data={barData}
			patterns={patterns}
			customSrSummaryRenderer={customSrSummaryRenderer}
			className="evinced-advantage"
			keys={['count']}
			indexBy="label"
			withLegend={false}
			tableProps={{
				dataType: 'Tool',
				columnsPattern: [
					{ name: 'Technology', field: 'label' },
					{ name: 'Total Critical Issues', field: 'count' }
				]
			}}
			barDesignProps={{
				margin: { top: 20, right: 80, bottom: 50, left: 80 },
				axisBottom: {
					tickSize: 0,
					tickPadding: 20
				},
				labelTextColor: '#fff',
				axisLeft: null,
				labelSkipHeight: 10,
				enableGridY: false,
				label: formatLabel,
				tooltip: tooltipRenderer
			}}
		/>
	);
};

export default EvincedAdvantageBar;
