import {
	BUTTON_TYPES,
	EvButton,
	EvIcon,
	EvSelect,
	EvTextInput,
	OptionType
} from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { IScopeRuleUIFormat } from '../../../../interfaces/ScopeRuleUIFormat';
import SCOPE_ACTIONS from '../../../../types/scope/ScopeActions';
import RemoveIcon from '../../../icons/RemoveIcon.svg';

const TEXT_INPUT_PLACEHOLDER = 'e.g.https?://.*.example.com';
const ARIA_LABEL = 'URL Rule';
const REMOVE_URL_BUTTON_TITLE = 'Remove Url Rule';

const URL_ACTIONS: OptionType[] = [
	{ label: 'Include URLs', value: SCOPE_ACTIONS.INCLUDE },
	{ label: 'Exclude URLs', value: SCOPE_ACTIONS.EXCLUDE }
];

interface IUrlRuleRowProps {
	rule: IScopeRuleUIFormat;
	onRemove?: () => void;
	onRuleChange: (rule: IScopeRuleUIFormat) => void;
}

const UrlRuleRow: FC<IUrlRuleRowProps> = ({ rule, onRemove, onRuleChange }) => {
	const [url, setUrl] = useState<string>(rule.url);
	const [selectedOption, setSelectedOption] = useState<OptionType>(
		URL_ACTIONS.find((option) => rule.scopeAction === option.value)
	);

	return (
		<div className="rule-row url-rule">
			<EvSelect
				className="url-rule-select"
				placeholder="Select URL"
				value={[selectedOption]}
				options={URL_ACTIONS}
				onChange={(selectedItem: OptionType) => {
					setSelectedOption(selectedItem);
					onRuleChange({
						...rule,
						scopeAction: selectedItem.value as SCOPE_ACTIONS
					});
				}}
			/>
			<EvTextInput
				ariaLabel={ARIA_LABEL}
				className="url-rule-input"
				value={url}
				placeholder={TEXT_INPUT_PLACEHOLDER}
				onChange={(val: string) => {
					setUrl(val);
					onRuleChange({ ...rule, url: val });
				}}
			/>
			<EvButton
				className="delete-rule-btn"
				key="Remove"
				title={REMOVE_URL_BUTTON_TITLE}
				type={BUTTON_TYPES.ICON}
				onClick={onRemove}
			>
				<EvIcon icon={RemoveIcon} />
			</EvButton>
		</div>
	);
};

export default UrlRuleRow;
