import { BUTTON_TYPES, EvSpinner } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import { IProperty } from '../../interfaces/Property';
import { IUrlSet } from '../../interfaces/UrlSet';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import DeleteCrawlPopup from '../delete-crawl-popup/DeleteCrawlPopup';
import './CrawlStatusLine.scss';

const PROPERTY_PAGE_CRAWL_INDICATION_ID = 'property-crawl-indication';

interface ICrawlProgressProps {
	property: IProperty;
	urlSet: IUrlSet;
	onDelete: (id: string) => Promise<void>;
}

const CrawlProgress: FC<ICrawlProgressProps> = ({
	property,
	urlSet,
	onDelete
}: ICrawlProgressProps) => {
	const { isReadOnlyUser } = useUserTenant();
	const renderCrawlProgress = (): JSX.Element => {
		return (
			<>
				<EvSpinner small />
				<div id={PROPERTY_PAGE_CRAWL_INDICATION_ID} aria-live="assertive">
					This property is currently being crawled.
				</div>
			</>
		);
	};

	return (
		<div className="scan-state">
			<div className="in-progress" id={PROPERTY_PAGE_CRAWL_INDICATION_ID} aria-live="assertive">
				{renderCrawlProgress()}
			</div>
			{!isReadOnlyUser() && (
				<DeleteCrawlPopup
					id={urlSet.id}
					afterUrlSetDeleted={onDelete}
					property={property}
					triggerButtonProps={{
						type: BUTTON_TYPES.LINK,
						children: 'Cancel',
						title: 'Cancel running crawl',
						className: 'cancel-action-button'
					}}
				/>
			)}
		</div>
	);
};

export default CrawlProgress;
