import { EVINCED_SEVERITIES, SORT_ORDER } from '@evinced-private/ui-common';
import _ from 'lodash';
import ServerApi from '../../api/ServerApi';
import { callGETAndCache } from '../../api/ServerCacheApi';
import { ALL_CONTENT_VIEW_ID } from '../../components/common/ev-property-views/consts/PropertyViews';
import API_URL_PATHS from '../../consts/ApiUrlPaths';
import CONFORMANCE_LEVELS from '../../consts/ConformanceLevels';
import PaginationRequestHelper from '../../helpers/PaginationRequestHelper';
import { generateUrlWithViewId } from '../../helpers/PropertyViewsHelper';
import ScanAndPropertyResultsHelper from '../../helpers/ScanAndPropertyResultsHelper';
import { IComponentsPaginationResponse } from '../../interfaces/ComponentsPaginationResponse';
import { IIssueTypesPaginationResponse } from '../../interfaces/IssueTypesPaginationResponse';
import { IIssuesOverviewResponse } from '../../interfaces/IssuesOverviewResponse';
import { IIssuesPaginationResponse } from '../../interfaces/IssuesPaginationResponse';
import { IPagesPaginationResponse } from '../../interfaces/PagesPaginationResponse';
import { IRequestedUrlsPaginationResponse } from '../../interfaces/RequestedUrlsPaginationResponse';
import { FieldOptionName } from '../../interfaces/Scan';
import { FilterParams } from '../../types/FilterParams';
import { FilterOptions } from '../../types/FilterTypes';
import {
	BreakdownSummary,
	ComponentDrilldownSummaryV2,
	ComponentOverviewResponse,
	ComponentsPaneSummary,
	IssueTypeDrilldownSummaryV2,
	IssueTypesSummary,
	PagesOverviewResponse,
	PagesSummary,
	ScanAndPropertyIssuesSummary
} from '../../types/OverviewTypes';
import { PaginationParams } from '../../types/PaginationParams';

const { SCAN_RESULTS_PATH } = API_URL_PATHS;

// v2 APIs

// helpers

function getScanResultsFieldOptions(
	scanId: string,
	fieldOption: FieldOptionName,
	viewId: string = null
): Promise<string[]> {
	const url = `${SCAN_RESULTS_PATH}/${scanId}/${fieldOption}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}

// todo: when transition is done, we should be able to delete all methods above
// filter API
const getScanFilterOptionsV2 = async (
	scanId: string,
	viewId: string = null
): Promise<FilterOptions> => {
	const [severities, types, tags] = await Promise.all([
		getScanResultsFieldOptions(scanId, FieldOptionName.SEVERITY, viewId),
		getScanResultsFieldOptions(scanId, FieldOptionName.TYPE, viewId),
		getScanResultsFieldOptions(scanId, FieldOptionName.TAGS, viewId)
	]);
	return {
		severities,
		types,
		tags: tags.filter((tag) => tag in CONFORMANCE_LEVELS)
	};
};

// pagination APIs

const getScanIssuesV2 = async (
	scanId: string,
	paginationParams: PaginationParams,
	filtersParams?: FilterParams,
	viewId: string = null
): Promise<IIssuesPaginationResponse> => {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${SCAN_RESULTS_PATH}/${scanId}/issues?${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const getScanComponentsV2 = async (
	scanId: string,
	paginationParams: PaginationParams,
	filtersParams?: FilterParams,
	viewId: string = null
): Promise<IComponentsPaginationResponse> => {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${SCAN_RESULTS_PATH}/${scanId}/components?${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const getScanPagesV2 = async (
	scanId: string,
	paginationParams: PaginationParams,
	viewId: string = null,
	filtersParams?: FilterParams
): Promise<IPagesPaginationResponse> => {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${SCAN_RESULTS_PATH}/${scanId}/pages?${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const getScanIssueTypes = async (
	scanId: string,
	paginationParams: PaginationParams,
	filtersParams?: FilterParams,
	viewId: string = null
): Promise<IIssueTypesPaginationResponse> => {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${SCAN_RESULTS_PATH}/${scanId}/issue-types?${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const getScanRequestedUrls = async (
	scanId: string,
	paginationParams: PaginationParams,
	filtersParams?: FilterParams,
	viewId: string = null
): Promise<IRequestedUrlsPaginationResponse> => {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${SCAN_RESULTS_PATH}/${scanId}/pages/requested?${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const getScanFilteredIssuesCsvRequest = async (
	scanId: string,
	filtersParams?: FilterParams,
	sortParams?: { sortField: string; sortOrder: SORT_ORDER },
	viewId: string = null
): Promise<IRequestedUrlsPaginationResponse> => {
	const filterParamsStr = PaginationRequestHelper.createFiltersUrlParams(filtersParams);
	const requestParams = {
		filters: PaginationRequestHelper.requestParamsToObject(filterParamsStr),
		sort: sortParams
	};
	const url = `/scan-results/${scanId}/issues/export-csv`;

	return ServerApi.apiCall('POST', generateUrlWithViewId(url, viewId), {
		data: requestParams
	});
};

// overview APIs

const getComponentsOverview = async ({
	scanId,
	viewId = null,
	useCache = false
}: IGetOverviewBaseArgs): Promise<ComponentOverviewResponse> => {
	const url = `${SCAN_RESULTS_PATH}/${scanId}/components/overview`;

	if (useCache || !viewId || viewId === ALL_CONTENT_VIEW_ID) {
		return callGETAndCache(generateUrlWithViewId(url, viewId));
	}
	return ServerApi.apiCall('GET', generateUrlWithViewId(url, viewId));
};

interface IGetIssuesOverviewArgs extends IGetOverviewBaseArgs {
	issueUrl?: string;
}

export type ScanOverviewResponse = {
	pages: PagesOverviewResponse;
	issues: IIssuesOverviewResponse;
	components: ComponentOverviewResponse;
};

const getIssuesOverview = async ({
	scanId,
	viewId,
	issueUrl,
	useCache = false
}: IGetIssuesOverviewArgs): Promise<IIssuesOverviewResponse> => {
	const urlFilter = issueUrl ? `?issueUrl=${encodeURIComponent(issueUrl)}` : '';
	const url = `${SCAN_RESULTS_PATH}/${scanId}/issues/overview${urlFilter}`;
	if (useCache || !viewId || viewId === ALL_CONTENT_VIEW_ID) {
		return callGETAndCache(generateUrlWithViewId(url, viewId));
	}
	return ServerApi.apiCall('GET', generateUrlWithViewId(url, viewId));
};

interface IGetOverviewBaseArgs {
	scanId: string;
	viewId?: string;
	useCache?: boolean;
}

const getPagesOverview = async ({
	scanId,
	viewId = null,
	useCache = false
}: IGetOverviewBaseArgs): Promise<PagesOverviewResponse> => {
	const url = `${SCAN_RESULTS_PATH}/${scanId}/pages/overview`;

	if (useCache || !viewId || viewId === ALL_CONTENT_VIEW_ID) {
		return callGETAndCache(generateUrlWithViewId(url, viewId));
	}
	return ServerApi.apiCall('GET', generateUrlWithViewId(url, viewId));
};

const getScanOverview = async (
	scanId: string,
	viewId: string = null
): Promise<ScanOverviewResponse> => {
	const [pages, issues, components] = await Promise.all([
		getPagesOverview({ scanId, viewId }),
		getIssuesOverview({ scanId, viewId }),
		getComponentsOverview({ scanId, viewId })
	]);
	return { pages, issues, components };
};

const getAllIssuesSummary = async (
	scanId: string,
	viewId: string = null
): Promise<BreakdownSummary> => {
	const [pagesOverview, issuesOverview, componentsOverview] = await Promise.all([
		getPagesOverview({ scanId, viewId }),
		getIssuesOverview({ scanId, viewId }),
		getComponentsOverview({ scanId, viewId })
	]);
	return {
		components: componentsOverview.componentsCount,
		pages: pagesOverview.overview.pagesWithIssues,
		seriousIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.SERIOUS
		),
		criticalIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.CRITICAL
		),
		issues: issuesOverview.issuesCount
	};
};

const getComponentsSummaryV2 = async (
	scanId: string,
	viewId: string = null
): Promise<ComponentsPaneSummary> => {
	const [issuesOverview, componentsOverview] = await Promise.all([
		getIssuesOverview({ scanId, viewId }),
		getComponentsOverview({ scanId, viewId })
	]);
	return {
		totalGroupedIssues: issuesOverview.groupedIssuesCount,
		totalIssues: issuesOverview.issuesCount,
		totalComponents: componentsOverview.componentsCount
	};
};

const getPagesSummaryV2 = async (scanId: string, viewId: string = null): Promise<PagesSummary> => {
	const [pagesOverview, issuesOverview] = await Promise.all([
		getPagesOverview({ scanId, viewId }),
		getIssuesOverview({ scanId, viewId })
	]);
	return {
		pages: pagesOverview.overview.scanned,
		pagesWithIssues: pagesOverview.overview.pagesWithIssues,
		issues: issuesOverview.issuesCount,
		seriousIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.SERIOUS
		),
		criticalIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.CRITICAL
		)
	};
};

type getIssuesSummaryV2Args = {
	scanId: string;
	issueUrl?: string;
	viewId?: string;
};
const getIssuesSummaryV2 = async ({
	scanId,
	issueUrl,
	viewId
}: getIssuesSummaryV2Args): Promise<ScanAndPropertyIssuesSummary> => {
	const issuesOverview = await getIssuesOverview({ scanId, viewId, issueUrl });
	return {
		totalNumberOfIssues: issuesOverview.issuesCount,
		seriousIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.SERIOUS
		),
		criticalIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.CRITICAL
		),
		minorIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.MINOR
		)
	};
};

const getIssueTypesSummary = async (
	scanId: string,
	viewId: string = null
): Promise<IssueTypesSummary> => {
	const issuesOverview = await getIssuesOverview({ scanId, viewId });
	const typesMatrix = issuesOverview?.severities.map((s) => s.types.map((t) => t.type));
	const typesCount = _.uniq(_.flatten(typesMatrix || [])).length;
	return {
		totalNumberOfIssues: issuesOverview.issuesCount,
		seriousIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.SERIOUS
		),
		criticalIssues: ScanAndPropertyResultsHelper.getIssuesCount(
			issuesOverview,
			EVINCED_SEVERITIES.CRITICAL
		),
		types: typesCount
	};
};

const getComponentDrilldownSummaryV2 = async (
	scanId: string,
	componentId: string,
	viewId: string = null
): Promise<ComponentDrilldownSummaryV2> => {
	const url = `${SCAN_RESULTS_PATH}/${scanId}/components/${componentId}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const getIssueTypeDrilldownSummaryV2 = async (
	scanId: string,
	issueType: string,
	viewId: string = null
): Promise<IssueTypeDrilldownSummaryV2> => {
	const url = `${SCAN_RESULTS_PATH}/${scanId}/issue-types/${issueType}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
};

const scanService = {
	// server table APIs
	getScanResultsFieldOptions,
	getScanFilterOptionsV2,

	getScanIssuesV2,
	getScanComponentsV2,
	getScanPagesV2,
	getScanIssueTypes,
	getScanRequestedUrls,
	getScanFilteredIssuesCsvRequest,

	// sevrver API for summary
	getAllIssuesSummary,
	getComponentsSummaryV2,
	getPagesSummaryV2,
	getIssuesSummaryV2,
	getIssueTypesSummary,
	getScanOverview,
	getPagesOverview,
	getComponentDrilldownSummaryV2,
	getIssueTypeDrilldownSummaryV2
};

export default scanService;
