import { EvSelect, OptionType } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { useDashboard } from '../../../../providers/dashboard/DashboardProvider';
import { COMPARE_TYPE, COMPARE_TYPE_OPTIONS_LABELS } from '../../DashboardTypes';
import './SelectLiftPriod.scss';

const SelectLiftPriod: FC = () => {
	const { liftPeriodCompare, setLiftPeriodCompare } = useDashboard();
	const [selectedLiftPeriod, setSelectedLiftPeriod] = useState<OptionType[]>([
		{ label: COMPARE_TYPE_OPTIONS_LABELS[liftPeriodCompare], value: liftPeriodCompare }
	]);

	const handleLiftPeriodChange = (selectedPeriod: OptionType): void => {
		setLiftPeriodCompare(selectedPeriod.value as COMPARE_TYPE);
		setSelectedLiftPeriod([selectedPeriod]);
	};
	return (
		<div className="select-lift-period">
			<div className="summary-range-label">Compared to</div>
			<EvSelect
				id="lift-period-select"
				options={[
					{
						label: COMPARE_TYPE_OPTIONS_LABELS[COMPARE_TYPE.COMPARE_LAST_SCANS],
						value: COMPARE_TYPE.COMPARE_LAST_SCANS
					},
					{
						label: COMPARE_TYPE_OPTIONS_LABELS[COMPARE_TYPE.COMPARE_DATE_RANGE],
						value: COMPARE_TYPE.COMPARE_DATE_RANGE
					}
				]}
				onChange={handleLiftPeriodChange}
				isSearchable
				value={selectedLiftPeriod}
				placeholder="select period to compare"
			/>
		</div>
	);
};

export default SelectLiftPriod;
