import {
	EvSection,
	EvSpinner,
	TABLE_SR_SUMMARY_IDS,
	TableSortOption,
	useSkipLinks
} from '@evinced-private/ui-common';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import SiteScannerTable from '../../../components/common/site-scanner-table/SiteScannerTable';
import ScanDataFilter from '../../../components/data-filter/DataFilter';
import { IFilterOptions } from '../../../components/data-filter/DataFilterHelper';
import GeneralError from '../../../components/general-error/GeneralError';
import { ScanTableDefinition } from '../../../components/scan-analysis/base/BaseScanViewV2';
import ApiErrorHelper from '../../../helpers/ApiErrorHelper';
import {
	IPropertyViewContext,
	useProperyViewContext
} from '../../../providers/PropertyViewProvider';
import { useCommonFiltersStore } from '../../../providers/commonFiltersProvider/CommonFiltersProvider';
import { useTablesStore } from '../../../providers/tablesProvider/TablesProvider';
import Logger from '../../../services/Logger';
import { getFilterOptions } from '../../../services/scan/ScansComparisonService';
import { FilterParams } from '../../../types/FilterParams';
import { PaginationParams } from '../../../types/PaginationParams';
import './TableWithFilters.scss';

const spacer = <div style={{ marginBottom: 25 }} />;

interface ITableWithFiltersProps {
	baseScanId: string;
	comparableScanId: string;
	tableDefinition: ScanTableDefinition;
	defaultTableSort: TableSortOption;
	loadTableItems: (
		baseScanId: string,
		comparableScanId: string,
		paginationParams: PaginationParams,
		filtersParams: FilterParams
	) => Promise<void>;
	filtersId: string;
	tableId: string;
	className?: string;
	mainArea?: ReactNode;
	componentNextToFilters?: ReactNode;
	tableWithUrlSwitcher?: boolean;
}

const TableWithFilters: FC<ITableWithFiltersProps> = ({
	className,
	baseScanId,
	comparableScanId,
	tableDefinition,
	defaultTableSort,
	loadTableItems,
	mainArea = spacer,
	componentNextToFilters,
	tableId,
	filtersId,
	tableWithUrlSwitcher = false
}: ITableWithFiltersProps) => {
	const [isLoading, setLoading] = useState(true);
	const [isTableLoading, setTableLoading] = useState(true);
	const [filterOptions, setFilterOptions] = useState<IFilterOptions>(null);
	const [error, setError] = useState(null);

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id || null,
		[properyViewContext]
	);

	// store definitions
	const tablesStore = useTablesStore();
	const commonFiltersStore = useCommonFiltersStore();

	// store actions and state
	const tablePaginationState = tablesStore.getPagination(tableId);
	const tableSortState = tablesStore.getSortOrder(tableId);
	const { page, sizePerPage } = tablePaginationState;
	const { dataField, order } = tableSortState ? tableSortState[0] : defaultTableSort;

	const appliedFilters = commonFiltersStore.getFilterValue(filtersId);
	const updateFilters = useCallback(
		(filters: IFilterOptions): void => {
			commonFiltersStore.updateSelectedFilters(filtersId, filters);
			// change page to 1 when applying a new filter
			tablesStore.updatePagination(tableId, { page: 1, sizePerPage });
		},
		[commonFiltersStore, tableId, filtersId, tablesStore, sizePerPage]
	);

	// wrapper functions around parent callbacks to fetch data
	const loadTableData = useCallback(async (): Promise<void> => {
		setTableLoading(true);
		try {
			await loadTableItems(
				baseScanId,
				comparableScanId,
				{ page, sizePerPage, sortField: dataField, sortOrder: order },
				{ filterValues: appliedFilters, filterOptions }
			);
		} catch (error) {
			const msg = `Error getting table items data for comparing base ${baseScanId} and comparable ${comparableScanId}`;
			Logger.error(msg, error);
			setError(`${msg} ${ApiErrorHelper.getErrorMessage(error) || ''}`);
		}
		setTableLoading(false);
	}, [
		appliedFilters,
		baseScanId,
		comparableScanId,
		filterOptions,
		page,
		sizePerPage,
		dataField,
		order,
		loadTableItems
	]);

	const initialLoad = useCallback(async (): Promise<void> => {
		setLoading(true);
		try {
			const initialFilterOptions = await getFilterOptions(
				baseScanId,
				comparableScanId,
				currentViewId
			);
			setFilterOptions(initialFilterOptions);
		} catch (error) {
			const msg = `Error getting filters and summary data for for comparing base ${baseScanId} and comparable ${comparableScanId}.`;
			Logger.error(msg, error);
			setError(`${msg} ${ApiErrorHelper.getErrorMessage(error) || ''}`);
		} finally {
			setLoading(false);
		}
	}, [baseScanId, comparableScanId, currentViewId]);

	useEffect(() => {
		if (!filterOptions) {
			initialLoad();
		}
	}, [initialLoad, filterOptions]);

	useEffect(() => {
		if (filterOptions) {
			loadTableData();
		}
	}, [appliedFilters, filterOptions, loadTableData]);

	const renderFiltersRow = (): JSX.Element => (
		<div className="filters-row">
			<ScanDataFilter
				filterOptions={filterOptions}
				filterValues={appliedFilters}
				onApply={updateFilters}
				textSearchEnabled={true}
				hideLabel
				disableButtons={isTableLoading}
			/>
			{componentNextToFilters}
		</div>
	);

	// updating skip links data when page is loaded
	useSkipLinks(!isLoading);

	if (isLoading) {
		return (
			<div className="base-scan-view">
				<EvSpinner />
			</div>
		);
	}

	if (error) {
		return (
			<div className="base-scan-view">
				<GeneralError title="Error Scan Comparison Data">{error}</GeneralError>
			</div>
		);
	}

	return (
		<div className={classNames('table-with-filters', className)}>
			{isLoading ? (
				<EvSpinner />
			) : (
				<EvSection
					ariaLabel={`${tableDefinition?.options?.title} Table`}
					ariaDescribedby={TABLE_SR_SUMMARY_IDS}
					ariaLive="polite"
					skipLinkId="main-section"
				>
					{renderFiltersRow()}
					{mainArea}
					{!tableDefinition ? (
						<EvSpinner />
					) : (
						<SiteScannerTable
							tableId={tableId}
							tableData={tableDefinition.data}
							isTableLoading={isTableLoading}
							// todo: when moving to entirely remote table - use only totalSize here
							totalCount={tableDefinition.totalCount || 0}
							totalPaginationableResults={tableDefinition.totalPaginationableResults}
							columns={tableDefinition.columns}
							options={tableDefinition.options}
							className="base-scan-analysis-table"
							withUrlSwitcher={tableWithUrlSwitcher}
						/>
					)}
				</EvSection>
			)}
		</div>
	);
};

export default observer(TableWithFilters);
