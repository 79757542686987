import { FormatHelper } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import { formatDate } from '../../helpers/DateFormatHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import scanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { IProperty } from '../../interfaces/Property';
import { IUrlSet } from '../../interfaces/UrlSet';
import EvLinkLocal from '../common/ev-link-local/EvLinkLocal';
import CrawlProgress from './CrawlProgress';
import './CrawlStatusLine.scss';

export const PROPERTY_PAGE_CRAWL_INDICATION_ID = 'property-crawl-indication';

interface ICrawlStatusLineProps {
	property: IProperty;
	urlSet: IUrlSet;
	onDelete: (id: string) => Promise<void>;
	hasScans: boolean;
}

const CrawlStatusLine: FC<ICrawlStatusLineProps> = ({
	property,
	urlSet,
	onDelete,
	hasScans
}: ICrawlStatusLineProps) => {
	if (!urlSet) {
		return <div>To start, map your property by initiating a Crawl</div>;
	}

	const isCrawlRunning = scanCrawlStateHelper.isInProgress(urlSet.state);
	const linkText = isCrawlRunning
		? ''
		: `${FormatHelper.formatNumber(urlSet.totalUrlsCount)} Pages`;

	return (
		<div className="scan-status">
			{linkText && (
				<EvLinkLocal
					linkText={linkText}
					url={RoutesHelper.getUrlSetPagePath(property.id, urlSet.id)}
					className="link-to-status"
				/>
			)}
			{isCrawlRunning ? (
				hasScans && <CrawlProgress property={property} urlSet={urlSet} onDelete={onDelete} />
			) : (
				<div className="scan-state">Crawled on {formatDate(urlSet.createdTime)}</div>
			)}
		</div>
	);
};

export default CrawlStatusLine;
