import { EvRadioGroup, EvSection, EvTitle } from '@evinced-private/ui-common';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import PropertyHelper from '../../../../helpers/PropertyHelper';
import { IProperty } from '../../../../interfaces/Property';
import { SetPropertyType } from '../../../../pages/property-settings/PropertySettingsPageTypes';
import './FileTypeSettings.scss';

const FILE_TYPE_SETTINGS_TITLE = 'file-type-settings-title';
const FILE_TYPE_DESCRIPTION = 'file-type-settings-description';

const PDF_FILE_TYPE = ['application/pdf'];
const HTML_FILE_TYPE = ['text/html', 'text/plain'];

interface IFileTypeSettingsProps {
	property: IProperty;
	setProperty: SetPropertyType;
}

const FileTypeSettings: FC<IFileTypeSettingsProps> = ({
	property,
	setProperty
}: IFileTypeSettingsProps) => {
	const initialFileType = PropertyHelper.isPdfMode(property) ? PDF_FILE_TYPE : HTML_FILE_TYPE;

	const [fileType, setFileType] = useState(initialFileType);

	useEffect(() => {
		if (property.urlSetConfiguration.discoveryConfiguration.allowedContentTypes !== fileType) {
			const propertyClone: IProperty = _.cloneDeep(property);
			propertyClone.urlSetConfiguration.discoveryConfiguration.allowedContentTypes = fileType;
			setProperty(propertyClone);
		}
	}, [fileType, property, setProperty]);

	const renderMoreInfo = (): JSX.Element => {
		return <>Define which page type the crawler should detect.</>;
	};

	const renderSectionHeader = (): JSX.Element => {
		return (
			<div className="section-header">
				<div className="title">
					<EvTitle
						titleText="Page Type"
						MoreInfoContent={renderMoreInfo()}
						id={FILE_TYPE_SETTINGS_TITLE}
						descriptionId={FILE_TYPE_DESCRIPTION}
					/>
				</div>
			</div>
		);
	};

	const renderSectionBody = (): JSX.Element => {
		const radioGroupOptions = [
			{
				label: 'HTML',
				isChecked: fileType === HTML_FILE_TYPE,
				onClick: () => setFileType(HTML_FILE_TYPE)
			},
			{
				label: 'PDF',
				isChecked: fileType === PDF_FILE_TYPE,
				onClick: () => setFileType(PDF_FILE_TYPE)
			}
		];
		return (
			<div className="section-body">
				<EvRadioGroup
					options={radioGroupOptions}
					name="file-settings-radio"
					ariaLabel="File Settings"
				/>
			</div>
		);
	};

	return (
		<EvSection
			className="file-type-settings-section"
			ariaLabelledby={FILE_TYPE_SETTINGS_TITLE}
			ariaDescribedby={FILE_TYPE_DESCRIPTION}
		>
			<div className="file-type-settings-content">
				{renderSectionHeader()}
				{renderSectionBody()}
			</div>
		</EvSection>
	);
};

export default FileTypeSettings;
