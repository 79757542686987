import { EvTitle, IEvButtonProps, TITLE_MODES } from '@evinced-private/ui-common';
import React, { useCallback, useEffect, useState } from 'react';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import './JsonPopup.scss';

interface IJsonPopupProps {
	title: string;
	triggerButtonProps: IEvButtonProps;
	initialValue: object;
	approveButtonText: string;
	errors?: string[];
	commentText?: string;
	isReadonly?: boolean;
	isLoading?: boolean;
	onApprove: (jsonContent: object, close: () => void) => void;
	onClose?: () => void;
	onOpen?: () => void;
}

const JsonPopup: React.FunctionComponent<IJsonPopupProps> = ({
	title,
	triggerButtonProps,
	initialValue,
	approveButtonText,
	errors = [],
	commentText,
	isReadonly = false,
	isLoading,
	onApprove,
	onClose,
	onOpen
}: IJsonPopupProps) => {
	const [contentJson, updateContentJson] = useState(JSON.stringify(initialValue, undefined, 4));

	const notificationsContext = useNotifications();

	const resetInitialValue = useCallback((): void => {
		updateContentJson(JSON.stringify(initialValue, undefined, 4));
	}, [initialValue]);

	useEffect(() => {
		resetInitialValue();
	}, [resetInitialValue]);

	const isJsonString = (strToTest: string): boolean => {
		try {
			const json = JSON.parse(strToTest);
			return typeof json === 'object';
		} catch (e) {
			return false;
		}
	};

	const onCloseClicked = (closeFunction: () => void): void => {
		if (onClose) {
			onClose();
		}
		closeFunction();
	};

	const onSave = (closeFunction: () => void): void => {
		const data = contentJson;
		if (!isJsonString(data)) {
			notificationsContext.alert({
				title: 'Could not save property',
				errorMessage: 'JSON is not valid. Please fix and try again.'
			});
			return;
		}
		const jsonResponse = JSON.parse(data);
		onApprove(jsonResponse, closeFunction);
	};

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
		updateContentJson(e.target.value);
	};

	const onOpenInner = (): void => {
		if (!onOpen) {
			resetInitialValue(); // handling reopening of the popup
		} else {
			onOpen();
		}
	};

	const renderBody = (): JSX.Element => {
		return (
			<div className="popup-form">
				{commentText && <div className="error-title">{commentText}</div>}
				<div className="popup-form-row">
					<EvTitle titleText="Configuration JSON" mode={TITLE_MODES.MAIN} />
				</div>
				<div className="popup-form-row texarea">
					<textarea
						aria-label="Configuration Json"
						onChange={onChange}
						value={contentJson}
						disabled={isReadonly}
					/>
				</div>
				<div className="errors">
					{errors.map((e) => (
						<div className="msg">{e}</div>
					))}
				</div>
			</div>
		);
	};

	const buttons = [
		{
			title: approveButtonText,
			disabled: isLoading,
			onClick: (onClose) => {
				onSave(() => {
					onCloseClicked(onClose);
				});
			}
		}
	];

	return (
		<SiteScannerPopup
			triggerButtonProps={triggerButtonProps}
			onOpen={onOpenInner}
			title={title}
			isSrOnly
			className="json-popup"
			isPopupLoading={isLoading}
			buttons={buttons}
			popupWidth="100%"
		>
			{renderBody()}
		</SiteScannerPopup>
	);
};

export default JsonPopup;
