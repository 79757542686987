import {
	EvincedColors,
	FormatHelper,
	PieDatum,
	SeriesLineOptions
} from '@evinced-private/ui-common';
import { get } from 'lodash';
import {
	ALL_CONTENT_VIEW_ID,
	ALL_CONTENT_VIEW_LABEL
} from '../../components/common/ev-property-views/consts/PropertyViews';
import { IScan } from '../../interfaces/Scan';
import { ScoreLineChartData } from '../../interfaces/ScanScore';
import {
	PERFORMANCE_OVER_TIME_CHART_TYPE,
	SCORE_LEVELS
} from '../../pages/dashboard/DashboardConsts';
import {
	TActivePropertiesList,
	TGroupedProperty,
	TView
} from '../../pages/dashboard/DashboardTypes';
import { HOME_PAGES } from '../../pages/dashboard/HomePageTypes';
import { formatShortMonthDatetime, isoDateToMilliseconds } from '../DateFormatHelper';
import MathHelper from '../MathHelper';
import RoutesHelper from '../RoutesHelper';
import ScanHelper from '../ScanHelper';

const { BEST_PRACTICE, CRITICAL, MODERATE, NEEDS_REVIEW } = EvincedColors.newSeverityColors;

export const hasEnoughActiveProperties = (numberOfActiveProperties: number): boolean => {
	return numberOfActiveProperties == null || numberOfActiveProperties > 0;
};

export const getCurrentTab = (pathname: string): HOME_PAGES => {
	switch (pathname) {
		case RoutesHelper.getPropertiesPagePath():
			return HOME_PAGES.ALL_PROPERTIES;
		case RoutesHelper.getDashboardPath():
			return HOME_PAGES.DASHBOARD;
		default:
			return HOME_PAGES.DASHBOARD;
	}
};

export const checkRunningScansStateChange = (
	runningScans: IScan[],
	formerRunningScans: IScan[],
	failedScans: IScan[]
): boolean => {
	const runningScansIds = runningScans.map((rs) => rs.id);
	const faileScansIds = failedScans.map((faileds) => faileds.id);

	return formerRunningScans.some(
		(fs) => !runningScansIds.includes(fs.id) && !faileScansIds.includes(fs.id)
	);
};

export const newRunningScans = (runningScans: IScan[], formerRunningScans: IScan[]): boolean => {
	const formerRunningScansIds = formerRunningScans.map((rs) => rs.id);

	return runningScans.some((rs) => !formerRunningScansIds.includes(rs.id));
};

const groupPropertiesByScore = (properties: TActivePropertiesList): TGroupedProperty => {
	const grouped = {
		[SCORE_LEVELS.EXCELLENT]: [],
		[SCORE_LEVELS.GOOD]: [],
		[SCORE_LEVELS.MODERATE]: [],
		[SCORE_LEVELS.POOR]: []
	};
	properties.forEach((property) => {
		const score = property.scoreResult?.score;
		if (score < 50) {
			grouped[SCORE_LEVELS.POOR].push(property);
		} else if (score >= 50 && score <= 70) {
			grouped[SCORE_LEVELS.MODERATE].push(property);
		} else if (score > 70 && score <= 90) {
			grouped[SCORE_LEVELS.GOOD].push(property);
		} else if (score > 90 && score <= 100) {
			grouped[SCORE_LEVELS.EXCELLENT].push(property);
		}
	});

	return grouped;
};

const getScoreChartData = (properties: TActivePropertiesList): PieDatum[] => {
	const groupedProperties = groupPropertiesByScore(properties);
	const propertiesCount = properties.length;
	const excellentValue = MathHelper.calcPercentage(
		groupedProperties[SCORE_LEVELS.EXCELLENT].length,
		propertiesCount
	);
	const goodValue = MathHelper.calcPercentage(
		groupedProperties[SCORE_LEVELS.GOOD].length,
		propertiesCount
	);
	const moderateValue = MathHelper.calcPercentage(
		groupedProperties[SCORE_LEVELS.MODERATE].length,
		propertiesCount
	);
	const fairValue = MathHelper.calcPercentage(
		groupedProperties[SCORE_LEVELS.POOR].length,
		propertiesCount
	);

	return [
		{
			id: SCORE_LEVELS.EXCELLENT,
			label: 'Excellent',
			value: excellentValue,
			percent: excellentValue,
			color: BEST_PRACTICE,
			score: '90-100'
		},
		{
			id: SCORE_LEVELS.GOOD,
			label: 'Good',
			value: goodValue,
			percent: goodValue,
			color: NEEDS_REVIEW,
			score: '70-90'
		},
		{
			id: SCORE_LEVELS.MODERATE,
			label: 'Moderate',
			value: moderateValue,
			percent: moderateValue,
			color: MODERATE,
			score: '50-70'
		},
		{
			id: SCORE_LEVELS.POOR,
			label: 'Poor',
			value: fairValue,
			percent: fairValue,
			color: CRITICAL,
			score: '< 50'
		}
	].filter((data) => data.value > 0);
};

// this function filters the scans and returns those created within the last 90 days
const filterByDate = (scans: IScan[]): IScan[] => {
	const cutoffDate = new Date();
	cutoffDate.setDate(cutoffDate.getDate() - 90);

	return scans.filter((item) => {
		const itemDate = new Date(item.createdTime);
		return itemDate >= cutoffDate;
	});
};

const getScoreLineChartData = (
	scans: IScan[],
	chartType: PERFORMANCE_OVER_TIME_CHART_TYPE
): ScoreLineChartData[] => {
	const fields = {
		[PERFORMANCE_OVER_TIME_CHART_TYPE.SCORE]: 'scoreResult.score',
		[PERFORMANCE_OVER_TIME_CHART_TYPE.TOTAL_ISSUES]: 'issuesCount',
		[PERFORMANCE_OVER_TIME_CHART_TYPE.CRITICAL_ISSUES]: 'criticalIssuesCount'
	};
	const filteredScans = filterByDate(scans);

	/**
	 * Sort  by date
	 */
	const sortedData = filteredScans
		.sort((scanA, scanB) => {
			const timeA = new Date(scanA.createdTime).getTime();
			const timeB = new Date(scanB.createdTime).getTime();
			return timeA - timeB;
		})
		.slice(-7);

	const data = sortedData.reduce((acc, scan) => {
		const value = get(scan, fields[chartType]);
		if (typeof value === 'number') {
			return [
				...acc,
				{
					x: formatShortMonthDatetime(scan.createdTime) || '',
					y: Math.round(value)
				}
			];
		}
		return acc;
	}, []);

	return [
		{
			id: 'Score',
			color: CRITICAL,
			data
		}
	];
};

const getSortedDataFromScans = (
	scans: IScan[],
	chartType: PERFORMANCE_OVER_TIME_CHART_TYPE
): IScan[] => {
	const fields = {
		[PERFORMANCE_OVER_TIME_CHART_TYPE.SCORE]: 'scoreResult.score',
		[PERFORMANCE_OVER_TIME_CHART_TYPE.TOTAL_ISSUES]: 'issuesCount',
		[PERFORMANCE_OVER_TIME_CHART_TYPE.CRITICAL_ISSUES]: 'criticalIssuesCount'
	};
	const filteredScans = filterByDate(scans);
	const sortedScans = ScanHelper.sortScansByDate(filteredScans);
	const lastScans = sortedScans.slice(-7);
	const uniqueScans = ScanHelper.removeScansWithSameDate(lastScans);

	return uniqueScans.reduce((acc, scan) => {
		const value = get(scan, fields[chartType]);
		// if scan has a value of the fields - we want to display it
		if (typeof value === 'number') {
			return [
				...acc,
				{
					x: isoDateToMilliseconds(scan.createdTime),
					y: Math.round(value),
					pages: scan.totalUrlsCount
				}
			];
		}
		return acc;
	}, []);
};

const getScoreMultiLineChartData = (
	scansMap: Map<string, IScan[]>,
	allViewsMap: Map<string, TView>,
	chartType: PERFORMANCE_OVER_TIME_CHART_TYPE,
	yAxisLabel: string
): SeriesLineOptions[] => {
	const linesData = [];

	scansMap.forEach((scans, key) => {
		const data = getSortedDataFromScans(scans, chartType);
		const view = allViewsMap.get(key);
		linesData.push({
			accessibility: {
				point: {
					descriptionFormatter(point) {
						const { x, y, pages } = point;
						return `View "${
							view?.name
						}". ${yAxisLabel} - ${y}. Scan date - ${formatShortMonthDatetime(
							x
						)}. Pages scanned - ${pages}.`;
					}
				}
			},
			id: key,
			type: 'line',
			name: view?.propertyName,
			description: view?.name,
			data
		});
	});

	return linesData;
};

const getViewsFromProperties = (activeProperties): Map<string, TView> => {
	const allViews = new Map<string, TView>(null);
	activeProperties.forEach((property) => {
		const views = [
			{ name: ALL_CONTENT_VIEW_LABEL, id: `${ALL_CONTENT_VIEW_ID} ${property.id}` },
			...property.views
		];
		views.forEach((view) => {
			allViews.set(view.id, {
				id: view.id,
				name: view.name,
				propertyId: property.id,
				propertyName: property.name
			});
		});
	});

	return allViews;
};

export const roundNumberSupportZero = (numVal: number): number => {
	return numVal ? Number(FormatHelper.nFormatter(numVal, 1)) : numVal;
};

export default {
	getCurrentTab,
	checkRunningScansStateChange,
	newRunningScans,
	getScoreChartData,
	getScoreLineChartData,
	getScoreMultiLineChartData,
	getViewsFromProperties,
	roundNumberSupportZero,
	hasEnoughActiveProperties
};
