import ReactGA, { EventArgs } from 'react-ga';
import localStorageApi from '../../api/LocalStorageApi';
import EnvironmentHelper from '../../helpers/EnvironmentHelper';
import logger from '../Logger';

interface IHasUserId {
	userId: string;
}

const analyticsCode = process.env.GA_CODE;

let analyticsInitialized = false;
let initializationFailed = false;
let currentUser = null;

const shouldNotRunAnalytics = EnvironmentHelper.isCI();

function initAnalytics(): void {
	try {
		if (shouldNotRunAnalytics) {
			return;
		}
		ReactGA.initialize(analyticsCode);
		// Once we have a user ID we should init it here: ReactGA.set({ userId });
		const user = localStorageApi.getUserFromLocalStorage();
		if (user) {
			const { userId } = user;
			ReactGA.set({ userId });
		}
		analyticsInitialized = true;
		logger.info('analytics was initialized');
	} catch (err) {
		logger.error('Could not initialize analytics service. Got error:', err);
		initializationFailed = true;
	}
}

function fireEvent(args: EventArgs): void {
	if (shouldNotRunAnalytics) {
		return;
	}
	if (!initializationFailed) {
		if (!analyticsInitialized) {
			throw new Error('Analytics service was called without initialization');
		}

		ReactGA.event(args);
		return;
	}
	logger.error('Couldnt fire analytics event, analytics initialization failed.');
}

function pageView(path): void {
	if (shouldNotRunAnalytics) {
		return;
	}
	if (!initializationFailed) {
		if (!analyticsInitialized) {
			throw new Error('Analytics service was called without initialization');
		}

		ReactGA.pageview(path);
		return;
	}
	logger.error('Couldnt call pageview, analytics initialization failed.');
}

function getCurrentUser(): IHasUserId {
	return currentUser;
}

function setCurrentUser(user: IHasUserId): void {
	currentUser = user;
	const { userId } = currentUser;
	localStorageApi.setGoogleAnalyticsUser(currentUser);
	ReactGA.set({ userId });
}

export default { initAnalytics, fireEvent, pageView, getCurrentUser, setCurrentUser };
