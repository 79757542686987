/**
 * @param arr - array to slice to smaller chunks
 * @param numOfChunks - number of smaller arrays
 * @returns single array of all small arrays
 */
export function splitArrayToChunks<T>(arr: T[], numOfChunks: number): T[][] {
	if (!arr) {
		return [[]];
	}

	const resArr = [];
	for (let i = 0; i < arr.length; i += numOfChunks) {
		resArr.push(arr.slice(i, i + numOfChunks));
	}
	return resArr;
}
