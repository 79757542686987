import { OptionType } from '@evinced-private/ui-common';
import JiraApi from '../../../api/JiraApi';
import { IIssueMeta } from '../../../types/JiraTypes';

type Field = {
	key: string;
	name: string;
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema?: any;
	required: boolean;
	options: OptionType[];
	value: OptionType | OptionType[] | string | number;
	isMultiple?: boolean;
	multiline?: boolean;
};

export const initializeIssueTypesOptions = (issueTypesOptions): IIssueMeta[] =>
	issueTypesOptions?.map((issueType) => ({
		...issueType,
		value: issueType.id,
		label: issueType.name
	}));

export const formatProjectsData = async (projects): Promise<[Field, Field]> => {
	const projectsOptions = projects.map((option) => ({
		...option,
		value: option.id,
		label: option.name,
		issuetypes: initializeIssueTypesOptions(option.issuetypes)
	}));
	const selectedProject = projectsOptions[0];
	const projectWithIssueTypes = await JiraApi.getProjectIssueTypes(selectedProject.value);
	const issueTypesOptions = initializeIssueTypesOptions(
		projectWithIssueTypes.issueTypes.filter((type) => !type.subtask)
	);
	selectedProject.issuetypes = issueTypesOptions;
	projectsOptions[0].issuetypes = issueTypesOptions;
	const projectsData = {
		key: 'project',
		name: 'Project',
		type: 'select',
		options: projectsOptions,
		value: selectedProject,
		required: true
	};
	const selectedIssueType = selectedProject.issuetypes[0];
	const issueTypesData = {
		key: 'issuetype',
		name: 'Type',
		type: 'select',
		options: issueTypesOptions,
		value: selectedIssueType,
		required: true
	};

	return [projectsData, issueTypesData];
};
