import ServerApi from '../api/ServerApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import { IUserInvitation } from '../interfaces/UserInvitation';
import INVITATION_STATE from '../interfaces/UserInvitationState';

const { TENANTS_URL_PATH, USER_INVITATIONS_URL_PATH } = API_URL_PATHS;

async function getInvitation(tenantId: string, invitationId: string): Promise<IUserInvitation> {
	return ServerApi.apiCall(
		'GET',
		`${TENANTS_URL_PATH}/${tenantId}/user-invitations/${invitationId}`
	);
}

export enum PUBLIC_INVITATON_STATE {
	SIGNED_UP = 'SIGNED_UP',
	NOT_SIGNED_UP = 'NOT_SIGNED_UP'
}
type PublicStateResponse = {
	state: PUBLIC_INVITATON_STATE;
};
async function getInvitationTokenPublicState(
	invitationToken: string
): Promise<PublicStateResponse> {
	return ServerApi.apiCall(
		'GET',
		`${USER_INVITATIONS_URL_PATH}/public-state?token=${invitationToken}`,
		{ publicAPI: true }
	);
}
async function getAllUserPendingInvitations(): Promise<IUserInvitation[]> {
	const invitations = await ServerApi.apiCall('GET', USER_INVITATIONS_URL_PATH);
	return invitations.filter((i: IUserInvitation) => i.state === INVITATION_STATE.PENDING);
}

async function acceptInvitation(invitationToken: string): Promise<IUserInvitation> {
	return ServerApi.apiCall('POST', `${USER_INVITATIONS_URL_PATH}/accept?token=${invitationToken}`);
}

const userInvitationsService = {
	getInvitation,
	getInvitationTokenPublicState,
	acceptInvitation,
	getAllUserPendingInvitations
};
export default userInvitationsService;
