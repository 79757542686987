import queryParamsOptions from '../consts/queryParamsOptions';
import { IGroupingRule } from '../interfaces/GroupingRule';
import { IProperty } from '../interfaces/Property';
import PropertyHelper from './PropertyHelper';

const formatRuleForForm = (rule: IGroupingRule): IGroupingRule => {
	const newRule = { ...rule };
	// todo: change it so the ignoreQueryParameters won't be on the model
	if (newRule.ignoreQueryParameters) {
		if (newRule.excludedQueryParameters && newRule.excludedQueryParameters.length > 0) {
			newRule.ignoreQueryParameters = queryParamsOptions.LIST;
		} else {
			newRule.ignoreQueryParameters = queryParamsOptions.IGNORE_ALL;
		}
	} else {
		newRule.ignoreQueryParameters = queryParamsOptions.INCLUDE_ALL;
	}
	newRule.excludedQueryParameters = newRule.excludedQueryParameters || [];
	return newRule;
};

const removeRule = (property: IProperty, urlPattern: string): IProperty => {
	const rules = PropertyHelper.getPropertyDiscoveryGroupingRules(property);
	return PropertyHelper.setPropertyDiscoveryGroupingRules(
		property,
		rules.filter((r) => r.urlPattern !== urlPattern)
	);
};

export default {
	formatRuleForForm,
	removeRule
};
