import {
	EvCard,
	EvContactUsButton,
	EvIcon,
	EvTitle
} from '@evinced-private/ui-common';
import React from 'react';
import { useHistory } from 'react-router';
import HappyLockIcon from '../../components/icons/HappyLockIcon.svg';
import RoutesHelper from '../../helpers/RoutesHelper';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import AuthenticationService from '../../services/AuthenticationService';
import './NoTenantPage.scss';

const title = "Connect to Your Company's Account";
const message = "We can't determine to which company account you belong. Please contact your system admin or Evinced customer support to resolve.";

export default (): JSX.Element => {
	const { tenant } = useUserTenant();
	const history = useHistory();
	if (tenant) {
		history.push(RoutesHelper.getHomepagePath());
		return null;
	}
	return (
		<div className="no-tenant-page">
			<div className="error-content">
				<EvCard className="error-card" title="">
					<>
						<EvIcon icon={HappyLockIcon} className="error-rocket-icon" />
						<EvTitle titleText={title} className="error-title" />
						<div className="error-contact">
							{message}
							<EvContactUsButton
								formTitle="How can we help?"
								description="Please share what's on your mind and we will get back to you shortly."
								portalId={process.env.HUBSPOT_API_KEY}
								userEmail={AuthenticationService.getUserEmail()}
								additionalInfo={{ isRequired: true, label: 'Message' }}
							/>
						</div>
					</>
				</EvCard>
			</div>
		</div>
	);
};
