import React, { FC, useEffect, useState } from 'react';
import { IPropertyView, IPropertyViewRow } from '../../interfaces/PropertyViews';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import SiteScannerTable from '../common/site-scanner-table/SiteScannerTable';
import './ManageViewsTable.scss';
import { getFilterViews, getViewsTableColumns } from './ManageViewsTableHelper';

interface IManageViewsTableProps {
	searchFilter: string;
}

const ManageViewsTable: FC<IManageViewsTableProps> = ({ searchFilter }) => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [allViews, setAllViews] = useState<IPropertyViewRow[]>(null);
	const [tableData, setTableData] = useState<IPropertyViewRow[]>([]);
	const properyViewContext: IPropertyViewContext = useProperyViewContext();

	useEffect(() => {
		if (!allViews) {
			return;
		}
		const filteredViews = getFilterViews(allViews, searchFilter);
		setTableData(filteredViews);
	}, [searchFilter, allViews]);

	useEffect(() => {
		const propertyViewsList: IPropertyView[] = properyViewContext.propertyViews;
		const data: IPropertyViewRow[] = propertyViewsList.map((propertyView) => ({
			...propertyView,
			searchStr: `${propertyView.name}`.toLowerCase()
		}));

		setAllViews(data);
		setLoading(false);
	}, [properyViewContext.propertyViews]);

	return (
		<SiteScannerTable
			tableId="manage-views-table"
			tableData={tableData}
			totalCount={tableData.length}
			className="manage-views-table"
			columns={getViewsTableColumns()}
			options={{
				title: 'Manage Views',
				pagination: true,
				dataType: 'views',
				caption: 'Manage views table'
			}}
			isTableLoading={isLoading}
			persistTableState
		/>
	);
};

export default ManageViewsTable;
