type PropertyLimits = {
	maxPagesCount: number;
};

// todo: change to async + get data from server
const getLimits = (): PropertyLimits => {
	return {
		maxPagesCount: 5000
	};
};

export default { getLimits };
