const calcPercentage = (count: number, totalCount: number, shouldRoundResult = true): number => {
	const decimal = count / totalCount;
	const percent = decimal * 100;
	if (shouldRoundResult) {
		return Math.round(percent);
	}
	return percent;
};

export default {
	calcPercentage
};
