import PropTypes from 'prop-types';
import React, { Component } from 'react';
import urlSetHelper from '../../helpers/UrlSetHelper.ts';
import scansService from '../../services/scan/ScansService.ts';
import JsonPopup from '../json-popup/JsonPopup.tsx';

class ScanConfigPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true
		};
	}

	loadUrlSet = () => {
		scansService.getScanUrlSetConfig(this.props.scanId).then((urlSetConfig) => {
			const cleanUrlSetConfig = urlSetHelper.removeMetaFieldsFromUrlSetConfig(urlSetConfig);
			this.setState({ urlSetConfig: cleanUrlSetConfig, isLoading: false });
		});
	};

	closePopup = (dataToSave, close) => {
		close();
	};

	render() {
		return (
			<JsonPopup
				title="Scan Url Set Configuration"
				initialValue={this.state.urlSetConfig}
				isReadonly={true}
				approveButtonText="Close"
				onApprove={this.closePopup}
				onOpen={this.loadUrlSet}
				triggerButtonProps={this.props.triggerButtonProps}
				isLoading={this.state.isLoading}
			/>
		);
	}
}

ScanConfigPopup.propTypes = {
	scanId: PropTypes.string,
	triggerButtonProps: PropTypes.object
};

export default ScanConfigPopup;
