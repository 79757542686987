const isDev = (): boolean => {
	return process.env.RUN_ENV === 'development';
};

const isCI = (): boolean => {
	return process.env.RUN_ENV === 'ci';
};

const isProduction = (): boolean => {
	return process.env.RUN_ENV === 'production';
};

export default { isCI, isDev, isProduction };
