export const encodeCollectionIds = (collectionIds: string[]): string => {
	return encodeURIComponent(collectionIds.join(','));
};

export const addCollectionsToUrlParams = (
	url: string,
	collectionIds: string[],
	showOnlyPropertiesInMyCollection: boolean,
	useQuestionMarkAsDelimiter = false
): string => {
	const delimiter: string = useQuestionMarkAsDelimiter ? '?' : '&';

	if (collectionIds.length > 0 && showOnlyPropertiesInMyCollection) {
		return `${url}${delimiter}collectionIds=${encodeCollectionIds(collectionIds)}`;
	}
	return url;
};
