import React, { FC } from 'react';
import { EvErrorCard } from '@evinced-private/ui-common';
import ContactSupportMailLink from '../contact-support/ContactSupportMailLink';
import './FullPageError.scss';

interface IFullPageErrorProps {
	title: string;
}

const FullPageError: FC<IFullPageErrorProps> = ({ title }: IFullPageErrorProps) => {
	const renderContactUs = (): JSX.Element => {
		return (
			<>
				Please contact us at <ContactSupportMailLink />
			</>
		);
	};
	return (
		<div className="full-page-error">
			<EvErrorCard title={title} contactUsMessage={renderContactUs()} />
		</div>
	);
};

export default FullPageError;
