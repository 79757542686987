enum SCAN_STATE {
	NEW = 'NEW', // pending in line - will be running very soon
	RUNNING = 'RUNNING', // currently running, no results or csvs
	INGESTING = 'INGESTING', // results are ready, but none of csvs is ready
	DONE = 'DONE', // scan is done, both csvs are ready
	FAILED = 'FAILED', // scan is failed
	CANCELING = 'CANCELING', // cancelling is in progress
	CANCELED = 'CANCELED', // cancelled in the past
	DONE_WITHOUT_CSV = 'DONE_WITHOUT_CSV', // no issues, with pages
	DONE_WITHOUT_PAGES_CSV = 'DONE_WITHOUT_PAGES_CSV' // no page, with issues
}

export default SCAN_STATE;
