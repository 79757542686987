enum SETTINGS_SECTION_TYPES {
	SCOPE = 'scope',
	GROUPING = 'grouping',
	SEEDS = 'seeds',
	NAME = 'name',
	LOGIN = 'Login',
	SCHEDULING = 'scheduling'
}

export default SETTINGS_SECTION_TYPES;
