import {
	BUTTON_TYPES,
	EvDropdownMenu,
	EvIcon,
	EvSpinner,
	IDropdownMenuOption
} from '@evinced-private/ui-common';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropertyViewsHelper from '../../../helpers/PropertyViewsHelper';
import RoutesHelper from '../../../helpers/RoutesHelper';
import { IPropertyView } from '../../../interfaces/PropertyViews';
import {
	IPropertyViewContext,
	useProperyViewContext
} from '../../../providers/PropertyViewProvider';
import { useUserTenant } from '../../../providers/userTenantProvider/UserTenantProvider';
import CaretDownIcon from '../../icons/CaretDownIcon.svg';
import './EvPropertyViews.scss';
import { ALL_CONTENT_VIEW, manageViewsButtonTitle } from './consts/PropertyViews';

interface IEvPropertyViewsProps {
	propertyId: string;
}

const EvPropertyViews: React.FC<IEvPropertyViewsProps> = ({ propertyId }) => {
	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewName: string = properyViewContext.currentView?.name || 'Select';
	const history = useHistory();
	const { isReadOnlyUser } = useUserTenant();

	const [propertyViewsOptions, setPropertyViewsOptions] = useState<IDropdownMenuOption[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const onOptionChange = useCallback(
		async (view: IPropertyView): Promise<void> => {
			await properyViewContext.updateSelectedView(view);
		},
		[properyViewContext]
	);

	const getViewsOptionsList = useCallback((): void => {
		const viewsOptions: IDropdownMenuOption[] = PropertyViewsHelper.getViewsOptionsList(
			[ALL_CONTENT_VIEW, ...properyViewContext.propertyViews],
			onOptionChange
		);

		setPropertyViewsOptions(viewsOptions);
		setIsLoading(false);
	}, [properyViewContext.propertyViews, onOptionChange]);

	useEffect(() => {
		getViewsOptionsList();
	}, [getViewsOptionsList]);

	return (
		<div className="filtered-views">
			<div className="views-list-label">Views</div>
			{isLoading ? (
				<EvSpinner />
			) : (
				<EvDropdownMenu
					id="manage-views"
					options={[...propertyViewsOptions]}
					className="manage-views"
					stickyButtons={
						!isReadOnlyUser() && [
							{
								className: 'manage-views-button',
								title: manageViewsButtonTitle,
								type: BUTTON_TYPES.PRIMARY,
								onClick: () => {
									history.push(RoutesHelper.getManageViewsPath(propertyId));
								},
								children: manageViewsButtonTitle
							}
						]
					}
					triggerButtonProps={{
						type: BUTTON_TYPES.CUSTOM,
						title: `Views selection. Currently on view ${currentViewName}`,
						children: (
							<div aria-hidden="true" className="property-views-list">
								<div className="selected-property-view">{`${currentViewName}`}</div>
								<EvIcon className="down-caret-icon" icon={CaretDownIcon} small />
							</div>
						)
					}}
				/>
			)}
		</div>
	);
};

export default EvPropertyViews;
