import { EvLinkNavigationTabs } from '@evinced-private/ui-common';
import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IPropertyViewContext, useProperyViewContext } from '../../providers/PropertyViewProvider';
import TabsViews from '../../types/TabsViews';
import './ScanTabsNavigation.scss';

const ScanTabsNavigation: FC = () => {
	const history = useHistory();
	const location = useLocation();
	const { propertyId, scanId } = useParams<{ [key: string]: string }>();

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id,
		[properyViewContext]
	);

	const viewsOrder = [
		TabsViews.OVERVIEW,
		TabsViews.COMPONENTS,
		TabsViews.ISSUE_TYPES,
		TabsViews.PAGES,
		TabsViews.BREAKDOWN
	];

	const getSelectedTabId = (): string => {
		if (location.pathname.includes(`/${RoutesHelper.getViewName(TabsViews.OVERVIEW)}`)) {
			return TabsViews.OVERVIEW;
		}
		if (location.pathname.includes(`/${RoutesHelper.getViewName(TabsViews.COMPONENTS)}`)) {
			return TabsViews.COMPONENTS;
		}
		if (location.pathname.includes(`/${RoutesHelper.getViewName(TabsViews.PAGES)}`)) {
			return TabsViews.PAGES;
		}
		if (location.pathname.includes(`/${RoutesHelper.getViewName(TabsViews.ISSUE_TYPES)}`)) {
			return TabsViews.ISSUE_TYPES;
		}
		return TabsViews.BREAKDOWN;
	};
	return (
		<nav className="scan-navigation">
			<EvLinkNavigationTabs
				selectedTabId={getSelectedTabId()}
				options={viewsOrder.map((viewName: TabsViews) => {
					return {
						onClick: () =>
							history.push(
								RoutesHelper.getScanDetailsView(propertyId, scanId, viewName, currentViewId),
								{
									from: history.location.pathname
								}
							),
						title: viewName,
						id: viewName
					};
				})}
			/>
		</nav>
	);
};

export default ScanTabsNavigation;
