import React, { FC, useState } from 'react';
import { BUTTON_TYPES, EvIcon, IEvButtonProps } from '@evinced-private/ui-common';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import logger from '../../services/Logger';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import DividerIcon from '../icons/DividerIcon.svg';
import './JobStatusAction.scss';

interface IJobStatusActionProps {
	onConfirm: () => Promise<void>;
	triggerButtonProps: IEvButtonProps;
	promptMessage: string | JSX.Element;
	jobsStatusText: string;
	popupTitle?: string;
	confirmButtonText?: string;
	isPopupOpen?: boolean;
}

const JobStatusAction: FC<IJobStatusActionProps> = ({
	isPopupOpen,
	triggerButtonProps,
	onConfirm,
	popupTitle = 'Confirm Cancel',
	promptMessage,
	confirmButtonText = "Yes, I'm sure",
	jobsStatusText
}) => {
	const [isDeleteInProgress, setDeleteInProgress] = useState(false);
	const notificationsContext = useNotifications();

	const onApplyClick = async (onClose: () => void): Promise<void> => {
		setDeleteInProgress(true);
		try {
			await onConfirm();
		} catch (err) {
			logger.error('error deleting job', err);
			notificationsContext.alert({ serverError: err });
		} finally {
			onClose();
			setDeleteInProgress(false);
		}
	};

	const buttons = [
		{
			title: 'Close',
			type: BUTTON_TYPES.SECONDARY,
			onClick: (onClose) => {
				logger.info('Confirm delete canceled');
				onClose();
			}
		},
		{
			title: confirmButtonText,
			onClick: onApplyClick,
			loading: isDeleteInProgress
		}
	];

	return (
		<div className="job-status-action">
			<EvIcon className="pipe" icon={DividerIcon} />
			<div className="task-action-block">
				{jobsStatusText}
				{isPopupOpen && (
					<SiteScannerPopup
						triggerButtonProps={triggerButtonProps}
						title={popupTitle}
						isCentered
						buttons={buttons}
					>
						<div className="delete-task-popup-content">{promptMessage}</div>
					</SiteScannerPopup>
				)}
			</div>
		</div>
	);
};

export default JobStatusAction;
