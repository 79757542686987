export const TOKEN_QUERY_PARAM = 'invitation_token';
export const EMAIL_QUERY_PARAM = 'email';
const getQueryParams = (): URLSearchParams => {
	return new URLSearchParams(window.location.search);
};
const getHashParams = (): URLSearchParams => {
	return new URLSearchParams(window.location.hash);
};

const getParamFromQueryOrHash = (paramName: string): string => {
	const tokenFromParams = getQueryParams().get(paramName);
	if (tokenFromParams) {
		return tokenFromParams;
	}
	// if the URL is redirected from Auth0 signup activation page,
	// the param will be in the hash (after #)
	return getHashParams().get(paramName);
};

const getInvitationTokenFromUrl = (): string => {
	return getParamFromQueryOrHash(TOKEN_QUERY_PARAM);
};

const getEmailFromUrl = (): string => {
	return getParamFromQueryOrHash(EMAIL_QUERY_PARAM);
};

export default { getInvitationTokenFromUrl, getEmailFromUrl, TOKEN_QUERY_PARAM, EMAIL_QUERY_PARAM };
