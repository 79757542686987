import React from 'react';
import { IUrlSeedDisplay } from '../interfaces/UrlSeedDisplay';
import UrlNormalizingHelper from './UrlNormalizingHelper';
import UrlValidationHelper, { UrlValidationResult } from './UrlValidationHelper';

const buildErrMessage = (errCount): string | JSX.Element => {
	return errCount ? (
		<div>
			{`${errCount} errors were found, please fix and
	import the file again.`}
		</div>
	) : (
		''
	);
};

const buildDupMessage = (dupCount): string | JSX.Element => {
	return dupCount ? <div>{`${dupCount} duplications ignored.`}</div> : '';
};

const buildAnnouncement = (
	newCount: number,
	errCount: number,
	dupCount: number
): string | JSX.Element => {
	const announcements = [];
	if (newCount > 0) {
		announcements.push(`${newCount} URLs were imported.`);
	}
	if (dupCount > 0) {
		announcements.push(buildDupMessage(dupCount));
	}
	if (errCount > 0) {
		announcements.push(buildErrMessage(errCount));
	}
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{announcements}</>;
};

const validateUrl = (url: string): UrlValidationResult => {
	const newSeed = UrlNormalizingHelper.formatURL(url);
	return UrlValidationHelper.validateUrlFormat(newSeed);
};

const getUrlsFromCSV = (
	fileContent
): { validUrls: IUrlSeedDisplay[]; errIndex: { line: number; msg: string }[] } => {
	const errIndex = [];
	const validUrls = [];
	fileContent.forEach((url, currentIndex) => {
		const formattedUrl = UrlNormalizingHelper.formatURL(url);
		const isValidUrl = validateUrl(formattedUrl);
		if (isValidUrl.isValid) {
			validUrls.push({ url: formattedUrl });
		} else {
			errIndex.push({ line: currentIndex + 1, msg: isValidUrl.errorMessages[0] });
		}
	});
	return { validUrls, errIndex };
};

const removeDuplications = (allSeeds: IUrlSeedDisplay[]): IUrlSeedDisplay[] => {
	const merged = allSeeds.reduce((res, seed): IUrlSeedDisplay[] => {
		const exists = res.find(
			(inListSeed) =>
				UrlNormalizingHelper.formatURL(inListSeed.url) === UrlNormalizingHelper.formatURL(seed.url)
		);
		if (exists) {
			return [...res];
		}
		return [...res, seed];
	}, []);
	return merged;
};

export default { buildAnnouncement, getUrlsFromCSV, removeDuplications };
