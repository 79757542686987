import { EvPopup, IEvPopupProps } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import { SITE_SCANNER_APP_ID } from '../../../consts/dom-consts';
import './SiteScannerPopup.scss';

const SiteScannerPopup: FC<IEvPopupProps> = (props) => {
	return <EvPopup appElement={SITE_SCANNER_APP_ID} {...props} />;
};

export default SiteScannerPopup;
