import { IScan } from '../interfaces/Scan';
import ScanCrawlStateHelper from './ScanCrawlStateHelper';
import { formatShortMonthWithoutTimeDatetime } from './DateFormatHelper';

export const COMPARABLE_SCANS_COUNT = 2;

const isScanDone = (scan: IScan): boolean => {
	return (
		!ScanCrawlStateHelper.isIngesting(scan.state) &&
		!ScanCrawlStateHelper.isAnyCsvReadyForDownload(scan.state)
	);
};

const getUnselectedScanIds = (scans: IScan[], selectedIds: string[]): string[] => {
	return scans.filter(({ id }) => id && !selectedIds.includes(id)).map(({ id }) => id);
};

const getNonSelectableScanIds = (selectedIds: string[], scans: IScan[]): string[] => {
	const nonDoneScanIds = scans.filter(isScanDone).map(({ id }) => id);
	return selectedIds.length === COMPARABLE_SCANS_COUNT
		? getUnselectedScanIds(scans, selectedIds).concat(nonDoneScanIds)
		: nonDoneScanIds;
};

const sortScansByDate = (scans: IScan[]): IScan[] => {
	return scans.sort((scanA, scanB) => {
		const timeA = new Date(scanA.createdTime).getTime();
		const timeB = new Date(scanB.createdTime).getTime();
		return timeA - timeB;
	});
};

const removeScansWithSameDate = (scans: IScan[]): IScan[] => {
	const uniqueScans = new Map();
	scans.forEach((scan) => {
		const dateKey = formatShortMonthWithoutTimeDatetime(scan.createdTime);
		uniqueScans.set(dateKey, scan);
	});
	return Array.from(uniqueScans.values());
};

export default {
	getNonSelectableScanIds,
	sortScansByDate,
	removeScansWithSameDate
};
