import {
	EvButton,
	TABLE_SR_SUMMARY_IDS,
	useSkipLinks,
	EvSection
} from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { withRouter } from 'react-router';
import CollectionsSwitch from '../../components/common/collections-switch/CollectionsSwitch';
import CreatePropertyPopup from '../../components/create-property-popup/CreatePropertyPopup';
import PropertiesTableV2 from '../../components/properties-table/PropertiesTableV2';
import SearchFilter from '../../components/search-filter/SearchFilter';
import { useCollections } from '../../providers/collections/CollectionsProvider';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import VerticalSeparator from '../dashboard/components/kpis/VerticalSeparator';
import './PropertiesPage.scss';

const addPropertyButtonTitle = 'Add Property';

const PropertiesPageV2: FC = () => {
	const [isPropertyCreationWizardOpened, setIsPropertyCreationWizardOpened] = useState(false);
	const [searchFilterValue, setSearchFilterValue] = useState('');

	const { isReadOnlyUser } = useUserTenant();
	const {
		propertyCollections,
		showOnlyPropertiesInMyCollection,
		collectionName,
		collectionIds,
		updatePropertiesCollectionState
	} = useCollections();
	// updating skip links when page loads
	useSkipLinks(true);

	return (
		<div className="properties-page">
			<div className={classNames('properties-page-title')}>
				<SearchFilter searchInputValue={searchFilterValue} onInputChange={setSearchFilterValue} />
				<div className="header-right">
					<div className="collections-switch">
						<CollectionsSwitch
							showOnlyPropertiesInMyCollection={showOnlyPropertiesInMyCollection}
							collectionName={collectionName}
							setShowOnlyPropertiesInMyCollection={updatePropertiesCollectionState}
						/>
					</div>
					{!isReadOnlyUser() && <VerticalSeparator />}
					{!isReadOnlyUser() && (
						<div className="page-actions">
							<EvButton
								title={addPropertyButtonTitle}
								onClick={() => setIsPropertyCreationWizardOpened(true)}
							>
								{addPropertyButtonTitle}
							</EvButton>
							<CreatePropertyPopup
								isOpen={isPropertyCreationWizardOpened}
								onClose={() => setIsPropertyCreationWizardOpened(false)}
							/>
						</div>
					)}
				</div>
			</div>
			<EvSection
				ariaLabel="Properties table"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				ariaLive="polite"
				skipLinkId="properties-table"
			>
				<PropertiesTableV2
					searchFilter={searchFilterValue}
					propertyCollections={propertyCollections}
					showOnlyPropertiesInMyCollection={showOnlyPropertiesInMyCollection}
					collectionName={collectionName}
					collectionIds={collectionIds}
				/>
			</EvSection>
		</div>
	);
};

export default withRouter(PropertiesPageV2);
