import analyticsService from './analytics/AnalyticsService';
import hubspotService from './analytics/HubspotService';
import AuthenticationService from './AuthenticationService';
import Logger from './Logger';
import UserService from './UserService';

async function initAppOnAuthentication(authResult): Promise<void> {
	AuthenticationService.setTokens(authResult);
	const evincedUserId = AuthenticationService.getEvincedUserId();
	analyticsService.setCurrentUser({ userId: evincedUserId || 'NO_USER_ID' });
	try {
		const userData = await UserService.getUserMetadata();
		hubspotService.identifyUserByEmail(userData.email);
		hubspotService.trackLogin();
	} catch (error) {
		Logger.error('Error getting user details, could not init hubspot API');
	}
}

export default { initAppOnAuthentication };
