export enum ROLE_TYPE_ENTITY {
	TENANT_ADMIN = 'TENANT_ADMIN',
	TENANT_USER = 'TENANT_USER',
	READ_ONLY = 'READ_ONLY'
}

export interface IUser {
	id: string;
	name: string;
	surname: string;
	email: string;
	role: {
		id: string;
		name: string;
		type: ROLE_TYPE_ENTITY;
	};
}
