import {
	BUTTON_SIZES,
	BUTTON_TYPES,
	EvButton,
	EvIcon,
	FailedIcon
} from '@evinced-private/ui-common';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import './Unauthorized403Page.scss';

const backToEvinced = 'Go Back';
const unauthorizedTitle = 'Access denied';
const unauthorizedMsg = "You don't have permission to perform this action.";

export default (): JSX.Element => {
	const history = useHistory();
	const redirectBackToProductHub = useCallback((): void => {
		history.goBack();
	}, [history]);

	return (
		<div className="error-403">
			<div className="error-403-content">
				<EvIcon icon={FailedIcon} className="failed-icon" />
				<div className="text-message">
					<p className="title">{unauthorizedTitle}</p>
					<p className="msg">{unauthorizedMsg}</p>
					<EvButton
						title={backToEvinced}
						type={BUTTON_TYPES.PRIMARY}
						onClick={redirectBackToProductHub}
						size={BUTTON_SIZES.BIG}
					>
						{backToEvinced}
					</EvButton>
				</div>
			</div>
		</div>
	);
};
