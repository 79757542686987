import { OptionType } from '@evinced-private/ui-common';
import TimezoneHelper from './timezone/TimezoneHelper';

enum TIME_SUFFIX {
	AM = 'am',
	PM = 'pm'
}

const USER_TIMEZONE_VALUE = TimezoneHelper.getClientTimezoneValue();
const GMT_TIMEZONE_VALUE = TimezoneHelper.getGMTTimezoneValue();

const getMonthDaysOptions = (): OptionType[] => {
	const options: OptionType[] = [];
	for (let i = 1; i < 32; i++) {
		options.push({ label: `${i}`, value: i });
	}
	return options;
};

export const monthsDayOptions: OptionType[] = getMonthDaysOptions();

export const weekdayOptions: OptionType[] = [
	{ label: 'Sunday', value: 7 },
	{ label: 'Monday', value: 1 },
	{ label: 'Tuesday', value: 2 },
	{ label: 'Wednesday', value: 3 },
	{ label: 'Thursday', value: 4 },
	{ label: 'Friday', value: 5 },
	{ label: 'Saturday', value: 6 }
];

const padNumber = (value: number): string => {
	return String(value).padStart(2, '0');
};

const getTimeOptions = (): OptionType[] => {
	const options: OptionType[] = [];
	for (let hour = 0; hour < 24; hour++) {
		// see if it's AM or PM
		const suffix = hour < 12 ? TIME_SUFFIX.AM : TIME_SUFFIX.PM;

		// remove 12 if time is after noon
		const normalizedHour = hour > 12 ? hour - 12 : hour;

		// make sure hour has 2 digits
		const numberToDisplay = padNumber(normalizedHour);

		options.push({
			label: `${numberToDisplay}:00 ${suffix}`,
			value: `${hour}:00`
		});
		options.push({
			label: `${numberToDisplay}:30 ${suffix}`,
			value: `${hour}:30`
		});
	}
	return options;
};
export const timeOptions = getTimeOptions();

/**
 *
 * @param time with format 'hh:mm'
 * @returns
 */
const parseTimeStr = (time: string): { hours: number; minutes: number } => {
	if (!time) {
		return {
			hours: 0,
			minutes: 0
		};
	}
	const hourPart = time.split(':')[0];
	const minutesPart = time.split(':')[1]?.split(' ')[0];
	return {
		hours: hourPart ? Number(hourPart) : 0,
		minutes: minutesPart ? Number(minutesPart) : 0
	};
};

export const timezoneOptions = TimezoneHelper.getTimezoneOptions();

const getTimeValue = (hours: number, minutes: number): string => {
	return `${hours}:${padNumber(minutes)}`;
};

const getInitialSelectedTimezone = (configuredTimezone: string): OptionType => {
	if (configuredTimezone) {
		return timezoneOptions.find((t) => t.value === configuredTimezone);
	}

	if (timezoneOptions.find((t) => t.value === USER_TIMEZONE_VALUE)) {
		return timezoneOptions.find((t) => t.value === USER_TIMEZONE_VALUE);
	}

	return timezoneOptions.find((t) => t.value === GMT_TIMEZONE_VALUE);
};

export default { parseTimeStr, getTimeValue, getInitialSelectedTimezone };
