import React, { createContext, FC, useContext, useMemo, useState } from 'react';

export interface INavigationRoute {
	id: string;
	url?: string;
	title: string;
}

interface INavigation {
	currentRoute: INavigationRoute;
	setCurrentRoute: (route: INavigationRoute) => void;
}

const NavigationContext = createContext<INavigation>(null);

const NavigationProvider: FC = ({ children }) => {
	const [currentRoute, setCurrentRoute] = useState<INavigationRoute>(null);
	const value = useMemo(() => {
		return { currentRoute, setCurrentRoute };
	}, [currentRoute, setCurrentRoute]);
	return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

const useNavigation = (): INavigation => useContext(NavigationContext);

export { NavigationProvider, useNavigation };
