import {
	EvLinkTypes,
	EvTableColumn,
	EvTableOptions,
	MultilineTextCellFormatter,
	SORT_ORDER,
	TableSortOption
} from '@evinced-private/ui-common';
import React from 'react';
import RoutesHelper from '../../helpers/RoutesHelper';
import {
	ComponentData,
	ComponentDataSampleIssue
} from '../../interfaces/ComponentsPaginationResponse';
import EvLinkLocal from '../common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../common/ev-table/cell-formatters/numberCellFormatter';
import { ScanTableDefinition } from '../scan-analysis/base/BaseScanViewV2';
import ScreenshotCellFormatter from '../scan-analysis/table-formatters/screenshot-cell-formatter/ScreenshotCellFormatter';
import ShowDetailsCellFormatter from '../show-details-cell-formatter/ShowDetailsCellFormatter';

const getDefaultSort = (): TableSortOption => {
	return {
		dataField: 'totalIssues',
		order: SORT_ORDER.DESC
	};
};

const getColumns = (propertyId: string, scanId: string): EvTableColumn[] => {
	return [
		{
			dataField: 'id',
			text: 'Component',
			style: { width: '300px' },
			headerStyle: { width: '300px' },
			formatter: (componentId: string) => {
				return (
					<EvLinkLocal
						url={RoutesHelper.getComponentDrilldownPath(propertyId, scanId, componentId)}
						linkText={componentId}
						type={EvLinkTypes.DARK}
					/>
				);
			},
			sort: true
		},
		{
			dataField: 'totalIssues',
			text: 'Total Issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'totalPages',
			text: 'Pages Impacted',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'issueTypes',
			text: 'Issue Types',
			style: { width: '200px', maxWidth: '200px' },
			headerStyle: { width: '200px' },
			sort: true,
			formatter: (types: string[]) => {
				return <MultilineTextCellFormatter items={types} itemType="issue-type" />;
			}
		},
		{
			dataField: 'sample',
			text: 'Example',
			style: { width: '250px' },
			headerStyle: { width: '250px' },
			classes: 'image-cell-td',
			formatter: (sample: ComponentDataSampleIssue) => {
				return (
					<ScreenshotCellFormatter
						cellDescriptor={`${sample.pageScreenshotUrl}`}
						screenshotDetails={{
							pageScreenshotUrl: sample.pageScreenshotUrl,
							boundingBoxes: [sample.boundingBox]
						}}
					/>
				);
			}
		},
		{
			dataField: '',
			text: '',
			style: { width: '70px', paddingRight: '10px', paddingLeft: '20px' },
			headerStyle: { width: '70px' },
			formatter: (cell, component: ComponentData) => {
				const sampleIssue = component.sample;
				return (
					<ShowDetailsCellFormatter
						componentDetails={{
							componentId: component.id,
							totalIssues: component.totalIssues,
							totalPages: component.totalPages
						}}
						issue={{ id: sampleIssue.issueId }}
						rowId={component.id}
						scanId={scanId}
					/>
				);
			}
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Issues comparison grouped by components',
		caption: 'Issues comparison grouped by components',
		pagination: true,
		remote: true,
		dataType: 'components',
		defaultSorted: [getDefaultSort()]
	};
};

const getTableDefinition = (
	compareComponentsData: ComponentData[],
	propertyId: string,
	scanId: string,
	totalCount: number,
	totalPaginationableResults: number
): ScanTableDefinition => {
	const result: ScanTableDefinition = {
		columns: getColumns(propertyId, scanId),
		options: getOptions(),
		data: compareComponentsData,
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition, getDefaultSort };
