import { IScanConfiguration } from './ScanConfiguration';
import { TScore } from './ScanScore';
import SCAN_STATE from './ScanState';

export enum FieldOptionName {
	SEVERITY = 'severity',
	TYPE = 'type',
	TAGS = 'tags'
}
export interface IScan {
	id?: string;
	name?: string;
	createdTime?: string;
	updatedTime: string;
	state: SCAN_STATE;
	propertyId: string;
	totalUrlsCount?: number;
	successfulUrlsCount?: number;
	failedUrlsCount?: number;
	configuration?: IScanConfiguration;
	score?: TScore;
	issuesCount?: number;
}
