import CONFORMANCE_LEVELS from '../consts/ConformanceLevels';
import TYPE_TO_PRETTY_TYPE from '../consts/EvincedTypeNames';
import NotGroupedComponent from '../consts/NotGroupedComponent';
import { OptionType } from '../types/OptionType';

function formatTypeLabel(option): string {
	if (option in TYPE_TO_PRETTY_TYPE) {
		return TYPE_TO_PRETTY_TYPE[option];
	}
	if (option === NotGroupedComponent.ID) {
		return NotGroupedComponent.DISPLAY_NAME;
	}
	if (option in CONFORMANCE_LEVELS) {
		return CONFORMANCE_LEVELS[option];
	}
	return `${option.charAt(0).toUpperCase()}${option.substring(1, option.length)}`;
}

function formatFilterOptionsForEvSelect(filterOptions: string[], labelPrefix = ''): OptionType[] {
	return filterOptions.map((option) => {
		return {
			value: option,
			label: labelPrefix + formatTypeLabel(option)
		};
	});
}

const capitalizeFirstLetter = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const addPercentSign = (v: string): string => `${v}%`;

// general formatter. taken from https://stackoverflow.com/a/9462382
const nFormatter = (num, digits = 1): string => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' }
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

	let item;
	if (Math.abs(num) <= 1) {
		item = lookup[0];
	} else {
		item = lookup
			.slice()
			.reverse()
			.find((item) => Math.abs(num) >= item.value);
	}

	// Check if num or item.value is undefined or null before performing the division
	const divisionResult = num && item?.value ? num / item.value : 0;

	// Use toFixed only if divisionResult is not NaN
	const formattedNumber = divisionResult?.toFixed(digits) || '';

	return item ? formattedNumber.replace(rx, '$1') + item.symbol : '0';
};

export default {
	formatFilterOptionsForEvSelect,
	capitalizeFirstLetter,
	addPercentSign,
	nFormatter
};
