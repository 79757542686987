/* eslint-disable no-mixed-spaces-and-tabs */
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { EvSpinner } from '@evinced-private/ui-common';
import React from 'react';
import InvitationTokenHelper from '../../helpers/InvitationTokenHelper';
import AuthToken from './AuthToken';

const invitationToken = InvitationTokenHelper.getInvitationTokenFromUrl();

export default withAuthenticationRequired(AuthToken, {
	onRedirecting: () => {
		return <EvSpinner />;
	},
	loginOptions: {
		...(invitationToken ? { appState: { invitationToken } } : {})
	}
});
