import { BUTTON_TYPES, EvSpinner, useSkipLinks, EvMainTitle } from '@evinced-private/ui-common';
import React, { useCallback, useEffect, useState } from 'react';
import EvPropertyViews from '../../components/common/ev-property-views/EvPropertyViews';
import PropertyUrlsTable from '../../components/property-urls-table/PropertyUrlsTable';
import UrlSetConfigPopup from '../../components/urlset-configuration/UrlSetConfigPopup';
import { formatDate } from '../../helpers/DateFormatHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IUrlSet } from '../../interfaces/UrlSet';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import logger from '../../services/Logger';
import TogglesService, { DevelopmentToggles } from '../../services/TogglesService';
import urlSetService from '../../services/UrlSetService';
import './UrlSetPage.scss';

interface IUrlSetPageProps {
	match: { params: { propertyId: string; urlSetId: string } };
	history: { push: (string) => void };
}

const UrlSetPage: React.FC<IUrlSetPageProps> = ({ match, history }: IUrlSetPageProps) => {
	const { propertyId, urlSetId } = match.params;
	const [loading, setLoading] = useState<boolean>(true);
	const [urlSet, setUrlSet] = useState<IUrlSet>(null);
	const [urlSetUrls, setUrlSetUrls] = useState<string[]>([]);
	const { setCurrentRoute } = useNavigation();
	const notificationsContext = useNotifications();

	const getPropertyAndScanDetails = useCallback((): void => {
		logger.debug('Getting info for property with ID: ', propertyId);
		Promise.all([urlSetService.getUrlSet(urlSetId), urlSetService.getUrlSetsUrls(urlSetId)])
			.then(([urlSetData, urlSetUrls]) => {
				urlSetData.createdTime = formatDate(urlSetData.createdTime);
				urlSetData.updatedTime = formatDate(urlSetData.updatedTime);
				setUrlSet(urlSetData);
				setUrlSetUrls(urlSetUrls);
				setLoading(false);

				setCurrentRoute({
					id: `url-set-page-${propertyId}`,
					url: RoutesHelper.getUrlSetPagePath(propertyId, urlSetId),
					title: `Crawl results ${urlSetData?.createdTime}`
				});
			})
			.catch((err) => {
				setLoading(false);
				logger.error('error while getting data', err);
				notificationsContext.alert({
					errorMessage: 'Error loading this page, redirecting to properties',
					onOK: () => history.push(RoutesHelper.getPropertiesPagePath())
				});
			});
	}, [notificationsContext, history, propertyId, urlSetId, setCurrentRoute]);

	const renderUrlSetConfigPopup = (): JSX.Element => {
		return (
			<UrlSetConfigPopup
				triggerButtonProps={{
					title: 'Url Set Settings',
					type: BUTTON_TYPES.ACTION,
					children: 'JSON Viewer'
				}}
				urlSetId={urlSetId}
			/>
		);
	};

	useEffect(() => {
		return () => {
			setCurrentRoute(null);
		};
	}, [setCurrentRoute]);

	useEffect(() => {
		getPropertyAndScanDetails();
	}, [getPropertyAndScanDetails]);
	useSkipLinks(true);

	if (loading) {
		return <EvSpinner />;
	}
	const showJsonConfiguration: boolean = TogglesService.getToggle(
		DevelopmentToggles.SHOW_URLSET_JSON_CONFIG
	);
	return (
		<div className="url-set-page">
			{urlSet && (
				<>
					<div className="table-title">
						<EvMainTitle>Crawl results {urlSet.createdTime}</EvMainTitle>
						{showJsonConfiguration && renderUrlSetConfigPopup()}
						<EvPropertyViews propertyId={propertyId} />
					</div>
					<div className="urls-list-container">
						<PropertyUrlsTable urlSetUrls={urlSetUrls} urlSetId={urlSetId} />
					</div>
				</>
			)}
		</div>
	);
};

export default UrlSetPage;
