import { BUTTON_TYPES, EvConfirm } from '@evinced-private/ui-common';
import React, { SFC } from 'react';
import { SITE_SCANNER_APP_ID } from '../../consts/dom-consts';
import { formatDate } from '../../helpers/DateFormatHelper';

interface IDeleteScanPopup {
	/**
	 * scan created time
	 */
	createdTime: string;
	/**
	 * scan id
	 */
	id: string;
	/**
	 * accessibilityProps is used to pass accessibility attributes into the trigger button
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	accessibilityProps?: any;
	onConfirm: (id: string) => void;
}

const DeleteScanPopup: SFC<IDeleteScanPopup> = ({
	createdTime,
	id,
	accessibilityProps,
	onConfirm
}: IDeleteScanPopup) => {
	const formattedDate = formatDate(createdTime);

	const onConfirmDelete = async (closeModal: () => void): Promise<void> => {
		await onConfirm(id);
		closeModal();
	};

	return (
		<EvConfirm
			appElement={SITE_SCANNER_APP_ID}
			title="Confirm Delete"
			approveButtonText="Delete"
			onConfirm={onConfirmDelete}
			promptMessage={`Are you sure you want to delete the scan from ${formattedDate} ?`}
			triggerButtonProps={{
				type: BUTTON_TYPES.ICON,
				title: 'Delete scan',
				children: 'Delete scan',
				accessibilityProps
			}}
		/>
	);
};

export default DeleteScanPopup;
