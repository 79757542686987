import ServerApi from '../../api/ServerApi';
import API_URL_PATHS from '../../consts/ApiUrlPaths';
import { MAX_PERCENT_OF_ISSUES_TO_COVER } from '../../helpers/ChartsHelper';
import { addCollectionsToUrlParams, encodeCollectionIds } from '../../helpers/CollectionsHelper';
import { generateUrlWithViewId } from '../../helpers/PropertyViewsHelper';
import { IScan } from '../../interfaces/Scan';
import { DEFAULT_SORT_OPTION_VALUES } from '../../pages/dashboard/DashboardConsts';
import {
	COMPARE_TYPE,
	TActiveProperties,
	TActivePropertiesList,
	TActivePropertyDataParams,
	TAverageScoreKpi,
	TPagesMonitoredKpi,
	TQuickWins,
	TScansParams,
	TScansWithStatus,
	TTenantCriticalIssuesKpi
} from '../../pages/dashboard/DashboardTypes';
import logger from '../Logger';

const { TENANT_OVERVIEW_URL_PATH, PROPERTIES_URL_PATH } = API_URL_PATHS;

export const getTenantScoreKpi = async (
	compareType: COMPARE_TYPE,
	collectionIds: string[],
	showOnlyPropertiesInMyCollection: boolean
): Promise<TAverageScoreKpi> => {
	const url = addCollectionsToUrlParams(
		`${TENANT_OVERVIEW_URL_PATH}/score?compareType=${compareType.toString()}`,
		collectionIds,
		showOnlyPropertiesInMyCollection
	);
	let tenantScansScore;

	try {
		tenantScansScore = await ServerApi.apiCall('GET', url);
	} catch (err) {
		logger.error('error for tenant score kpi request: ', err);
	}
	return tenantScansScore;
};

export const getCriticalIssuesKpi = async (
	compareType: COMPARE_TYPE,
	collectionIds: string[],
	showOnlyPropertiesInMyCollection: boolean
): Promise<TTenantCriticalIssuesKpi> => {
	const url = addCollectionsToUrlParams(
		`${TENANT_OVERVIEW_URL_PATH}/issues?compareType=${compareType.toString()}`,
		collectionIds,
		showOnlyPropertiesInMyCollection
	);
	let tenantScansCriticalIssues;

	try {
		tenantScansCriticalIssues = await ServerApi.apiCall('GET', url);
	} catch (err) {
		logger.error('error for critical issues kpi request: ', err);
	}
	return tenantScansCriticalIssues;
};

export const getPagesMonitoredKpi = async (
	compareType: COMPARE_TYPE,
	collectionIds: string[],
	showOnlyPropertiesInMyCollection: boolean
): Promise<TPagesMonitoredKpi> => {
	const url = addCollectionsToUrlParams(
		`${TENANT_OVERVIEW_URL_PATH}/pages?compareType=${compareType.toString()}`,
		collectionIds,
		showOnlyPropertiesInMyCollection
	);
	let tenantScansPagesMonitored;

	try {
		tenantScansPagesMonitored = await ServerApi.apiCall('GET', url);
	} catch (err) {
		logger.error('error for pages monitored kpi request: ', err);
	}
	return tenantScansPagesMonitored;
};

export const getActivePropertiesList = async (
	collectionIds: string[],
	showOnlyPropertiesInMyCollection = false
): Promise<TActivePropertiesList> => {
	const url = addCollectionsToUrlParams(
		`${PROPERTIES_URL_PATH}/active`,
		collectionIds,
		showOnlyPropertiesInMyCollection,
		true
	);
	let activePropertiesList;

	try {
		activePropertiesList = await ServerApi.apiCall('GET', url);
	} catch (err) {
		logger.error('error for active properties request: ', err);
	}
	return activePropertiesList;
};

export const getActivePropertiesData = async ({
	views = false,
	sortField = DEFAULT_SORT_OPTION_VALUES.sortBy,
	sortDirection = DEFAULT_SORT_OPTION_VALUES.orderBy,
	pageNumber,
	pageSize,
	compareType,
	collectionIds,
	showOnlyPropertiesInMyCollection
}: TActivePropertyDataParams): Promise<TActiveProperties> => {
	const requestParams = [
		`views=${views.toString()}`,
		`sortField=${sortField}`,
		`sortDirection=${sortDirection.toUpperCase()}`,
		`compareType=${compareType}`
	];
	if (pageNumber) {
		requestParams.push(`pageNumber=${pageNumber}`);
	}
	if (pageSize) {
		requestParams.push(`pageSize=${pageSize}`);
	}
	if (collectionIds.length > 0 && showOnlyPropertiesInMyCollection) {
		requestParams.push(`collectionIds=${encodeCollectionIds(collectionIds)}`);
	}

	let activePropertiesImpactData;
	try {
		activePropertiesImpactData = await ServerApi.apiCall(
			'GET',
			`property-overview?${requestParams.join('&')}`
		);
	} catch (err) {
		logger.error('error for property overview request: ', err);
	}
	return activePropertiesImpactData;
};

export const getActivePropertiesQuickWins = async ({
	topCriticalPropertiesCount = 8,
	topCriticalComponentsCount = 10,
	maxPercentCoverage = MAX_PERCENT_OF_ISSUES_TO_COVER,
	collectionIds,
	showOnlyPropertiesInMyCollection
}: {
	collectionIds: string[];
	topCriticalPropertiesCount?: number;
	topCriticalComponentsCount?: number;
	maxPercentCoverage?: number;
	showOnlyPropertiesInMyCollection?: boolean;
}): Promise<TQuickWins> => {
	const url = addCollectionsToUrlParams(
		`${TENANT_OVERVIEW_URL_PATH}/components?topCriticalPropertiesCount=${topCriticalPropertiesCount}&topCriticalComponentsCount=${topCriticalComponentsCount}&maxPercentCoverage=${maxPercentCoverage}`,
		collectionIds,
		showOnlyPropertiesInMyCollection
	);
	let quickWins: TQuickWins;

	try {
		quickWins = await ServerApi.apiCall('GET', url);
	} catch (err) {
		logger.error('error for quick wins request: ', err);
	}
	return quickWins;
};

export const getScans = async ({
	states = [],
	propertyId,
	viewId,
	collectionIds,
	showOnlyPropertiesInMyCollection
}: TScansParams): Promise<IScan[]> => {
	const requestParams = [`states=${states.join(',')}`];
	if (propertyId) {
		requestParams.push(`propertyId=${propertyId}`);
	}
	if (collectionIds.length > 0 && showOnlyPropertiesInMyCollection) {
		requestParams.push(`collectionIds=${encodeCollectionIds(collectionIds)}`);
	}
	let scans: IScan[];
	try {
		scans = await ServerApi.apiCall(
			'GET',
			generateUrlWithViewId(`scans?${requestParams.join('&')}`, viewId)
		);
	} catch (err) {
		logger.error('error for scans request: ', err);
	}
	return scans;
};

export const getScansWithStatus = async ({
	states = [],
	propertyId,
	viewId,
	collectionIds,
	showOnlyPropertiesInMyCollection
}: TScansParams): Promise<TScansWithStatus> => {
	const requestParams = [`states=${states.join(',')}`];
	if (propertyId) {
		requestParams.push(`propertyId=${propertyId}`);
	}
	if (collectionIds.length > 0 && showOnlyPropertiesInMyCollection) {
		requestParams.push(`collectionIds=${encodeCollectionIds(collectionIds)}`);
	}

	let scans: IScan[];
	try {
		scans = await ServerApi.apiCall(
			'GET',
			generateUrlWithViewId(`scans?${requestParams.join('&')}`, viewId)
		);
	} catch (err) {
		logger.error('error for scans request: ', err);
		return { failed: true, scans };
	}
	return { failed: false, scans };
};
