import { EVINCED_SEVERITIES, EVINCED_SEVERITIES_IDS, PieDatum } from '@evinced-private/ui-common';
import flatten from 'lodash/flatten';
import NotGroupedComponent from '../consts/NotGroupedComponent';
import { EVINCED_SEVERITIES_BY_ID, EVINCED_SEVERITIES_BY_LABEL } from '../consts/severityIDs';
import { IssueSeverities } from '../interfaces/IssuesOverviewResponse';
import { ComponentOverviewResponse } from '../types/OverviewTypes';
import ColorsHelper from './charts/ColorsHelper';
import IdFormatterHelper from './IdFormatterHelper';
import MathHelper from './MathHelper';

const otherComponentsLabel = 'Other components';

const getComponentsDistributionPieDataV2 = (
	componentsOverview: ComponentOverviewResponse,
	totalIssues: number
): PieDatum[] => {
	const otherComponentsValue = MathHelper.calcPercentage(
		componentsOverview.otherComponentsCount,
		totalIssues
	);
	return componentsOverview.components
		.map(({ component, issuesCount }) => {
			const value = MathHelper.calcPercentage(issuesCount, totalIssues);
			return {
				id: component,
				value,
				percent: value,
				label: component
			};
		})
		.filter((datum) => datum.id !== NotGroupedComponent.ID && datum.value > 0)
		.concat({
			id: otherComponentsLabel,
			value: otherComponentsValue,
			percent: otherComponentsValue,
			label: otherComponentsLabel
		});
};

const getCriticalIssuesDistributionPieDataV2 = (issueSeverities: IssueSeverities[]): PieDatum[] => {
	const criticalIssuesOverview = issueSeverities.find(
		({ severity }) => severity === EVINCED_SEVERITIES.CRITICAL
	);
	if (!criticalIssuesOverview) {
		return [];
	}
	return criticalIssuesOverview.types.map(({ type, issuesCount }) => {
		const value = MathHelper.calcPercentage(issuesCount, criticalIssuesOverview.issuesCount);
		return {
			id: IdFormatterHelper.formatTextToId(type),
			label: type,
			value,
			percent: value,
			color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_IDS.CRITICAL],
			severity: EVINCED_SEVERITIES.CRITICAL
		};
	});
};

const getIssuesDistributionPieDataV2 = (
	issueSeverities: IssueSeverities[],
	totalIssues: number
): PieDatum[] => {
	const data = issueSeverities.map(({ types, severity }) =>
		types
			.map(({ type, issuesCount }) => {
				const value = MathHelper.calcPercentage(issuesCount, totalIssues);
				return {
					id: IdFormatterHelper.formatTextToId(`${type} ${severity}`),
					label: type,
					value,
					percent: value,
					color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_BY_LABEL[severity]],
					severity
				};
			})
			.sort((datum1, datum2) => datum2.value - datum1.value)
	);
	return flatten(data);
};

const getSeveritiesDistributionPieDataV2 = (
	issueSeverities: IssueSeverities[],
	totalIssues: number
): PieDatum[] => {
	return issueSeverities.map(({ severity, issuesCount }) => {
		const value = MathHelper.calcPercentage(issuesCount, totalIssues);
		return {
			id: IdFormatterHelper.formatTextToId(severity),
			value,
			percent: value,
			label: severity,
			color: ColorsHelper.newSeverityColors[EVINCED_SEVERITIES_BY_ID[severity]]
		};
	});
};

export default {
	getComponentsDistributionPieDataV2,
	getCriticalIssuesDistributionPieDataV2,
	getIssuesDistributionPieDataV2,
	getSeveritiesDistributionPieDataV2
};
