export default class FileHelper {
	/**
	 * Creates a file locally with name @filename and content @content
	 * And makes the browser download it automatically for the user
	 * File type can be configured using @filetype parameter
	 * @param filename
	 * @param content
	 * @param filetype
	 */
	static createFileAndMakeBrowserDownloadIt(
		filename: string,
		content: string,
		filetype = 'octet/stream'
	): void {
		// Download file
		const a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		const blob = new Blob([content], { type: filetype });
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		// Set file name according to requirement
		a.download = filename;
		a.click();
		window.URL.revokeObjectURL(url);
	}
}
