import { BUTTON_TYPES } from '@evinced-private/ui-common';
import React, { FC } from 'react';
import PropertySourceHelper from '../../helpers/PropertySourceHelper';
import { IProperty } from '../../interfaces/Property';
import logger from '../../services/Logger';
import URL_SET_SOURCES from '../../types/UrlSetSources';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import DeleteCrawlPopup from '../delete-crawl-popup/DeleteCrawlPopup';

interface IChangePropertySourceConfirmPopupProps {
	sourceMode: URL_SET_SOURCES;
	isOpen: boolean;
	isCrawlRunning: boolean;
	isPopupLoading: boolean;
	originalProperty: IProperty;
	setIsOpen: (isOpen: boolean) => void;
	onConfirm: () => void;
	onCrawlCanceled: (onClose: () => void) => void;
}

const ChangePropertySourceConfirmPopup: FC<IChangePropertySourceConfirmPopupProps> = ({
	sourceMode,
	isOpen,
	isCrawlRunning,
	isPopupLoading,
	originalProperty,
	setIsOpen,
	onConfirm,
	onCrawlCanceled
}: IChangePropertySourceConfirmPopupProps) => {
	const { currentMode, otherMode } = PropertySourceHelper.getSourceDisplayText(sourceMode);
	const isListMode = PropertySourceHelper.isListMode(sourceMode);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getButtons = (): any[] => {
		const cancelButton = {
			title: `Stay in ${currentMode} mode`,
			type: BUTTON_TYPES.SECONDARY,
			onClick: () => {
				logger.info('Confirm canceled');
				setIsOpen(false);
			}
		};

		if (!isCrawlRunning) {
			return [
				cancelButton,
				{
					title: `Yes! Switch to ${otherMode} mode`,
					onClick: onConfirm
				}
			];
		}

		return [
			cancelButton,
			{
				title: 'Cancel crawl and move to List mode',
				render: (title, onClose) => {
					return (
						<DeleteCrawlPopup
							id={originalProperty.scanConfiguration.urlSetId}
							afterUrlSetDeleted={async () => onCrawlCanceled(onClose)}
							property={originalProperty}
							triggerButtonProps={{
								title: 'Cancel crawl and move to List mode',
								children: title,
								className: 'cancel-action-button'
							}}
						/>
					);
				}
			}
		];
	};

	const renderMoveToListModeText = (): JSX.Element => {
		return (
			<>
				Switching to the List mode will remove any existing configuration (Seeds, Scope and Grouping
				rules).
				<br />
				Once saved, the previous configuration cannot be reverted.
				<br />
				<br />
				Previously discovered URLs will automatically be added to the list.
				<br />
				<br />
				Switch to List mode?
			</>
		);
	};

	const renderMoveToDiscoveryModeText = (): JSX.Element => {
		return (
			<>
				Switching to Crawl mode will use the URLs in the list as seeds.
				<br />
				<br />
				Switch to Crawl Mode?
			</>
		);
	};

	return (
		<SiteScannerPopup
			title={`Switch to ${otherMode} mode?`}
			isControlled
			isCentered
			buttons={getButtons()}
			isPopupLoading={isPopupLoading}
			onClose={() => {
				setIsOpen(false);
			}}
			isOpen={isOpen}
		>
			<div className="prompt">
				{isListMode ? renderMoveToDiscoveryModeText() : renderMoveToListModeText()}
			</div>
		</SiteScannerPopup>
	);
};

export default ChangePropertySourceConfirmPopup;
