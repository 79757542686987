import { FormatHelper } from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ExportCsvButton from '../../../../components/export-csv/ExportCsvButton';
import BaseScanViewV2, {
	ScanTableDefinition
} from '../../../../components/scan-analysis/base/BaseScanViewV2';
import { MAX_RESULTS } from '../../../../consts/Results';
import { IPagesPaginationResponse } from '../../../../interfaces/PagesPaginationResponse';
import { SummaryItem } from '../../../../interfaces/SummaryItem';
import scanService from '../../../../services/scan/ScanService';
import { FilterParams } from '../../../../types/FilterParams';
import { PagesSummary } from '../../../../types/OverviewTypes';
import { PaginationParams } from '../../../../types/PaginationParams';
import ScanPagesTableHelperV2 from './ScanPagesTableHelperV2';

interface IScanPagesView {
	viewId: string;
	scanId: string;
	propertyId: string;
}

const defaultTableSort = ScanPagesTableHelperV2.getDefaultSort();

const ScanPagesView: FC<IScanPagesView> = ({ viewId, scanId, propertyId }: IScanPagesView) => {
	const [scanResults, setScanResults] = useState<IPagesPaginationResponse>(null);
	const [totalCount, setTotalCount] = useState<number>(null);
	const [summaryPaneInfo, setSummaryPaneInfo] = useState<PagesSummary>();
	const screenId = `scan-${scanId}-view-${viewId}-pages-view`;

	const loadTableItems = useCallback(
		async (
			scanId: string,
			paginationParams: PaginationParams,
			filtersParams?: FilterParams
		): Promise<void> => {
			const response: IPagesPaginationResponse = await scanService.getScanPagesV2(
				scanId,
				paginationParams,
				viewId,
				filtersParams
			);

			setScanResults(response);
		},
		[viewId]
	);

	useEffect(() => {
		if (scanResults?.overallTotal) {
			setTotalCount(scanResults.overallTotal);
		}
	}, [scanResults?.overallTotal]);

	const loadSummaryData = useCallback(async (): Promise<void> => {
		const summaryPaneInfo: PagesSummary = await scanService.getPagesSummaryV2(scanId, viewId);
		setSummaryPaneInfo(summaryPaneInfo);
	}, [scanId, viewId]);

	const tableDefinition = useMemo((): ScanTableDefinition => {
		if (!scanResults) {
			return null;
		}

		const { pages } = scanResults;
		const totalPaginationableResults =
			scanResults.overallTotal > MAX_RESULTS ? MAX_RESULTS : scanResults.overallTotal;

		return ScanPagesTableHelperV2.getTableDefinition(
			pages,
			propertyId,
			scanId,
			totalCount,
			totalPaginationableResults,
			screenId
		);
	}, [scanResults, totalCount, propertyId, scanId, screenId]);

	const summaryPaneData = useMemo((): SummaryItem[] => {
		if (!summaryPaneInfo) {
			return [];
		}
		const { pages, issues, criticalIssues, seriousIssues } = summaryPaneInfo;
		return [
			{
				value: FormatHelper.formatNumber(pages),
				label: 'Pages scanned'
			},
			{
				value: FormatHelper.formatNumber(summaryPaneInfo.pagesWithIssues),
				label: 'Pages with issues'
			},
			{ value: FormatHelper.formatNumber(issues), label: 'Total issues' },
			{
				value: FormatHelper.formatNumber(criticalIssues),
				label: 'Critical issues'
			},
			{ value: FormatHelper.formatNumber(seriousIssues), label: 'Serious issues' }
		];
	}, [summaryPaneInfo]);

	const renderActionButtons = useCallback(
		(): JSX.Element => (
			<div className="actions">
				<ExportCsvButton
					scanId={scanId}
					propertyId={propertyId}
					accessibilityProps={{ 'aria-haspopup': 'dialog' }}
					viewId={viewId}
				/>
			</div>
		),
		[scanId, propertyId, viewId]
	);

	return (
		<BaseScanViewV2
			viewId={viewId}
			scanId={scanId}
			screenId={screenId}
			summaryPaneItems={summaryPaneData}
			tableDefinition={tableDefinition}
			defaultTableSort={defaultTableSort}
			loadSummaryData={loadSummaryData}
			loadTableItems={loadTableItems}
			renderActionButtons={renderActionButtons}
		/>
	);
};

export default ScanPagesView;
