import { callGETAndCache } from '../../api/ServerCacheApi';
import { IFilterOptions } from '../../components/data-filter/DataFilterHelper';
import API_URL_PATHS from '../../consts/ApiUrlPaths';
import CONFORMANCE_LEVELS from '../../consts/ConformanceLevels';
import FormatHelper from '../../helpers/FormatHelper';
import PaginationRequestHelper from '../../helpers/PaginationRequestHelper';
import { generateUrlWithViewId } from '../../helpers/PropertyViewsHelper';
import { IComponentsPaginationResponse } from '../../interfaces/ComponentsPaginationResponse';
import { IIssuesPaginationResponse } from '../../interfaces/IssuesPaginationResponse';
import { IPaginationResponse } from '../../interfaces/PaginationResponse';
import { FieldOptionName } from '../../interfaces/Scan';
import { FilterParams } from '../../types/FilterParams';
import { PaginationParams } from '../../types/PaginationParams';
import { getScansFilterListOptions } from '../DataFilterService';

const { COMPARE_SCANS_PATH } = API_URL_PATHS;

export enum COMPARISON_TYPE {
	NEW = 'NEW',
	RECURRING = 'RECURRING',
	RESOLVED = 'RESOLVED'
}

export interface ICompareScansPagesPaginationResponse extends IPaginationResponse {
	pages: ICommonPageIssuesBreakdown[];
}

interface IScanPagseOverview {
	pageCount: number;
	creationTime: string;
}

export interface IIssueComponentCounts {
	issueCount: number;
	componentCount: number;
}

export interface IPagesOverview {
	baseScan: IScanPagseOverview;
	comparedScan: IScanPagseOverview;
	commonPages: number;
}

export interface IIssuesOverview {
	new: IIssueComponentCounts;
	recurring: IIssueComponentCounts;
	resolved: IIssueComponentCounts;
}

export interface ICommonPageIssuesBreakdown {
	newIssues: number;
	recurringIssues: number;
	resolvedIssues: number;
	url: string;
}
interface IIssuesBreakdown {
	newIssues: number;
	recurringIssues: number;
	resolvedIssues: number;
	baseScan: { creationTime: string; issueCount: number };
	comparedScan: { creationTime: string; issueCount: number };
}

async function getScanComparisonIssues(
	baseScanId: string,
	comparableScanId: string,
	type: COMPARISON_TYPE,
	onlyCommonPages: boolean,
	paginationParams: PaginationParams,
	filtersParams?: FilterParams,
	viewId: string = null
): Promise<IIssuesPaginationResponse> {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${COMPARE_SCANS_PATH}/issues/${type}?base=${baseScanId}&comparedScan=${comparableScanId}&common=${onlyCommonPages}${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}

async function getScanComparisonComponents(
	baseScanId: string,
	comparableScanId: string,
	type: COMPARISON_TYPE,
	onlyCommonPages: boolean,
	paginationParams: PaginationParams,
	filtersParams?: FilterParams,
	viewId: string = null
): Promise<IComponentsPaginationResponse> {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${COMPARE_SCANS_PATH}/components/${type}?base=${baseScanId}&comparedScan=${comparableScanId}&common=${onlyCommonPages}${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}

async function getScanComparisonPagesOverview(
	baseScanId: string,
	comparableScanId: string,
	viewId: string = null
): Promise<IPagesOverview> {
	const url = `${COMPARE_SCANS_PATH}/overview/pages?base=${baseScanId}&comparedScan=${comparableScanId}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}

async function getScanComparisonIssuesBreakdown(
	baseScanId: string,
	comparableScanId: string,
	onlyCommonPages: boolean,
	viewId: string = null
): Promise<IIssuesBreakdown> {
	const url = `${COMPARE_SCANS_PATH}/overview/issues?base=${baseScanId}&comparedScan=${comparableScanId}&common=${onlyCommonPages}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}
type getCommonPagesIssuesArgs = {
	baseScanId: string;
	comparableScanId: string;
	paginationParams?: PaginationParams;
	filtersParams?: FilterParams;
	viewId?: string;
};
async function getCommonPagesIssues({
	baseScanId,
	comparableScanId,
	paginationParams,
	filtersParams,
	viewId = null
}: getCommonPagesIssuesArgs): Promise<ICompareScansPagesPaginationResponse> {
	const requestParams = PaginationRequestHelper.createUrlParams(paginationParams, filtersParams);
	const url = `${COMPARE_SCANS_PATH}/pages?base=${baseScanId}&comparedScan=${comparableScanId}${requestParams}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}

async function getScanComparisonIssuesOverview(
	baseScanId: string,
	comparableScanId: string,
	onlyCommonPages: boolean,
	viewId: string = null
): Promise<IIssuesOverview> {
	const url = `${COMPARE_SCANS_PATH}/overview/components?base=${baseScanId}&comparedScan=${comparableScanId}&common=${onlyCommonPages}`;
	return callGETAndCache(generateUrlWithViewId(url, viewId));
}

export const getFilterOptions = async (
	baseScanId: string,
	comparableScanId: string,
	viewId: string = null
): Promise<IFilterOptions> => {
	const typeOptions = await getScansFilterListOptions(
		baseScanId,
		comparableScanId,
		FieldOptionName.TYPE,
		viewId
	);
	const severitiesOptions = await getScansFilterListOptions(
		baseScanId,
		comparableScanId,
		FieldOptionName.SEVERITY,
		viewId
	);
	const tagsOptions = await getScansFilterListOptions(
		baseScanId,
		comparableScanId,
		FieldOptionName.TAGS,
		viewId
	);

	return {
		severities: FormatHelper.formatFilterOptionsForEvSelect(severitiesOptions),
		types: FormatHelper.formatFilterOptionsForEvSelect(typeOptions),
		searchValue: '',
		tags: [
			...FormatHelper.formatFilterOptionsForEvSelect(
				tagsOptions.filter((tag) => tag in CONFORMANCE_LEVELS)
			),
			{
				value: CONFORMANCE_LEVELS.UNSPECIFIED,
				label: CONFORMANCE_LEVELS.UNSPECIFIED
			}
		]
	};
};

export default {
	getCommonPagesIssues,
	getScanComparisonIssues,
	getScanComparisonComponents,
	getScanComparisonPagesOverview,
	getScanComparisonIssuesOverview,
	getScanComparisonIssuesBreakdown,
	getFilterOptions
};
