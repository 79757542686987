import React, { FC } from 'react';
import classNames from 'classnames';
import { EvSection } from '@evinced-private/ui-common';
import './ChartWrapper.scss';

interface IChartWrapper {
	children: JSX.Element | JSX.Element[];
	title?: string;
	className?: string;
}

const ChartWrapper: FC<IChartWrapper> = ({ children, title, className }) => {
	return (
		<div className={classNames('chart-wrapper', { [className]: !!className })}>
			<EvSection className="charts" ariaLabel={title}>
				{children}
			</EvSection>
		</div>
	);
};

export default ChartWrapper;
