import { BUTTON_TYPES } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { formatDate } from '../../helpers/DateFormatHelper';
import scanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { IScan } from '../../interfaces/Scan';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import logger from '../../services/Logger';
import scansService from '../../services/scan/ScansService';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';
import './ExportCsvButton.scss';

enum CSV_TYPE {
	PAGES,
	ISSUES
}

type IExportCsvProps = {
	scanId: string;
	propertyId: string;
	isMenuAction?: boolean;
	/**
	 * accessibilityProps is used to pass accessibility attributes into the trigger button
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	accessibilityProps?: any;
	viewId?: string;
};

const ExportCsvButton: FC<IExportCsvProps> = ({
	scanId,
	propertyId,
	isMenuAction,
	accessibilityProps,
	viewId
}: IExportCsvProps) => {
	const [isDownloadingIssues, setIsDownloadingIssues] = useState<boolean>(false);
	const [isDownloadingPages, setIsDownloadingPages] = useState<boolean>(false);
	const [isScanLoading, setScanLoading] = useState<boolean>(false);
	const [isPagesCsvReady, setIsPagesCsvReady] = useState<boolean>(false);
	const [isIssuesCsvReady, setIsIssuesCsvReady] = useState<boolean>(false);
	const [scanFromServer, setScanFromServer] = useState<IScan>(null);
	const notificationsContext = useNotifications();

	const onOpen = async (): Promise<void> => {
		setScanLoading(true);
		const newScan = await scansService.getDoneScan(propertyId, scanId, viewId);
		setScanFromServer(newScan);
		setIsPagesCsvReady(scanCrawlStateHelper.isPagesCsvReady(newScan.state));
		setIsIssuesCsvReady(scanCrawlStateHelper.isIssuesCsvReady(newScan.state));
		setScanLoading(false);
	};

	const setIsDownloading = (state: boolean, csvType: CSV_TYPE): void => {
		if (csvType === CSV_TYPE.PAGES) {
			setIsDownloadingPages(state);
		} else {
			setIsDownloadingIssues(state);
		}
	};

	const onDownloadData = (close, csvType: CSV_TYPE): void => {
		const shouldGroupByPages = csvType === CSV_TYPE.PAGES;
		setIsDownloading(true, csvType);

		scansService
			.getScanCsv(scanId, shouldGroupByPages)
			.catch((error) => {
				logger.error({ error });
				notificationsContext.alert({
					title: 'Could not download csv',
					serverError: error
				});
			})
			.finally(() => {
				setIsDownloading(false, csvType);
				close();
			});
	};

	const buttons = [
		{
			title: 'Cancel',
			type: BUTTON_TYPES.SECONDARY,
			onClick: (closeModal) => {
				closeModal();
			}
		},
		{
			title: 'Download Pages CSV',
			disabled: isDownloadingPages || isDownloadingIssues || !isPagesCsvReady,
			loading: isDownloadingPages,
			onClick: (onClose) => {
				onDownloadData(onClose, CSV_TYPE.PAGES);
			}
		},
		{
			title: 'Download Issues CSV',
			disabled: isDownloadingIssues || isDownloadingPages || !isIssuesCsvReady,
			loading: isDownloadingIssues,
			onClick: (onClose) => {
				onDownloadData(onClose, CSV_TYPE.ISSUES);
			}
		}
	];

	const renderExportDetails = (): JSX.Element => {
		return (
			<div className="export-details">
				{isPagesCsvReady || isIssuesCsvReady ? (
					<>
						<div className="details-row">
							<div className="label">Scan:</div>
							<div className="data">{formatDate(scanFromServer.createdTime)}</div>
						</div>
						<div className="details-row">* Note: filters are not applied</div>
					</>
				) : (
					<>
						The system is generating your CSV file. In large scans this may take a few minutes and
						up to an hour. Please try again soon.
					</>
				)}
			</div>
		);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			<SiteScannerPopup
				isCentered
				triggerButtonProps={{
					title: 'Download CSV',
					children: 'Download CSV',
					type: isMenuAction ? BUTTON_TYPES.ICON : BUTTON_TYPES.PRIMARY,
					accessibilityProps
				}}
				onOpen={onOpen}
				className="export-csv-popup"
				isPopupLoading={isScanLoading}
				title="Download CSV"
				buttons={buttons}
				popupWidth={500}
			>
				{renderExportDetails()}
			</SiteScannerPopup>
		</>
	);
};

export default ExportCsvButton;
