export enum ProductType {
	SCANNER = 'SCANNER',
	MOBILE_SDK = 'MOBILE_SDK',
	MOBILE_FLOW_ANALYZER = 'MOBILE_FLOW_ANALYZER',
	WEB_FLOW_ANALYZER = 'WEB_FLOW_ANALYZER',
	WEB_SDK = 'WEB_SDK',
	DEVELOPER_ACCESSIBILITY_DEBUGGER = 'DEVELOPER_ACCESSIBILITY_DEBUGGER'
}

export type TenantProduct = {
	expirationTime: string;
	id: string;
	type: ProductType;
	tenantId: string;
};
export interface ITenant {
	id?: string;
	createdTime?: string;
	expirationTime?: Date;
	name: string;
	accountPlan: string;
	companyName: string;
	products?: TenantProduct[];
}
