import ServerApi from '../api/ServerApi';
import API_URL_PATHS from '../consts/ApiUrlPaths';
import UrlSetHelper from '../helpers/UrlSetHelper';
import { IUrlSet } from '../interfaces/UrlSet';

const { URLSETS_URL_PATH } = API_URL_PATHS;

export type CreateUrlSetRequestBody = {
	propertyId: string;
	urls?: string[];
};

async function createUrlSet(urlSetData: CreateUrlSetRequestBody): Promise<IUrlSet> {
	const body = { data: urlSetData };
	return ServerApi.apiCall('POST', URLSETS_URL_PATH, body);
}

async function getUrlSetsUrls(urlSetId: string): Promise<string[]> {
	const url = `${URLSETS_URL_PATH}/${urlSetId}/urls`;
	return ServerApi.apiCall('GET', url);
}

async function getUrlSet(urlSetId: string): Promise<IUrlSet> {
	const url = `${URLSETS_URL_PATH}/${urlSetId}?metadata=false`;
	return ServerApi.apiCall('GET', url);
}

async function getUrlSetsByProperty(propertyId: string): Promise<IUrlSet[]> {
	const url = `${URLSETS_URL_PATH}?propertyId=${propertyId}`;
	return ServerApi.apiCall('GET', url);
}

async function getLatestSuccessfulUrlSet(propertyId: string): Promise<IUrlSet | null> {
	const urlSets = await getUrlSetsByProperty(propertyId);
	const latestValidUrlSet = UrlSetHelper.findLatestDoneUrlSet(urlSets);
	if (!latestValidUrlSet) {
		return null;
	}
	return getUrlSet(latestValidUrlSet.id);
}
async function deleteUrlSet(urlSetId: string): Promise<IUrlSet[]> {
	const url = `${URLSETS_URL_PATH}/${urlSetId}`;
	return ServerApi.apiCall('DELETE', url);
}

const urlSetService = {
	createUrlSet,
	getUrlSet,
	getUrlSetsUrls,
	getUrlSetsByProperty,
	getLatestSuccessfulUrlSet,
	deleteUrlSet
};
export default urlSetService;
