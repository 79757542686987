import classNames from 'classnames';
import React, { FC } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './EvTabs.scss';

export enum VIEW_MODES {
	DEFAULT = 'default',
	DIVIDED = 'divided'
}

type TabOption = {
	label: string;
	key: string;
};
interface IEvTabsProps {
	options: TabOption[];
	tabPanels: JSX.Element[];
	selected: string;
	onSelect: ([key]: string) => void;
	className?: string;
	styles?: { [key: string]: React.CSSProperties };
	viewMode?: VIEW_MODES;
}
const EvTabs: FC<IEvTabsProps> = ({
	options,
	tabPanels,
	selected,
	onSelect,
	className,
	styles = {},
	viewMode = VIEW_MODES.DEFAULT
}: IEvTabsProps) => {
	const selectedIndex = options.findIndex((o) => o.key === selected);
	const onTabChanged = (index: number): void => {
		onSelect(options[index].key);
	};
	return (
		<Tabs
			selectedIndex={selectedIndex}
			onSelect={onTabChanged}
			className={classNames('ev-tabs', { [className]: !!className })}
		>
			<div className="tabs-list-wrapper" style={styles}>
				<TabList className="tabs-list">
					{options.map((o) => (
						<Tab
							className={classNames('single-tab', { divided: viewMode === VIEW_MODES.DIVIDED })}
							tabIndex="0"
							key={o.key}
						>
							{o.label}
						</Tab>
					))}
				</TabList>
			</div>

			{tabPanels.map((panel, index) => (
				<TabPanel key={panel.key || index}>{panel}</TabPanel>
			))}
		</Tabs>
	);
};
export default EvTabs;
