import { IEvButtonProps } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { FormError, validateProperty } from '../../helpers/FormHelper';
import PropertySourceHelper from '../../helpers/PropertySourceHelper';
import ScanCrawlStateHelper from '../../helpers/ScanCrawlStateHelper';
import { IProperty } from '../../interfaces/Property';
import { IUrlSetConfiguration } from '../../interfaces/UrlSerConfiguration';
import { IUrlSet } from '../../interfaces/UrlSet';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import propertiesService from '../../services/PropertiesService';
import urlSetService from '../../services/UrlSetService';
import JsonPopup from '../json-popup/JsonPopup';

interface IEditPropertyPopupProps {
	onSaveChanges: (property: IProperty, onClose: () => void) => void;
	propertyId: string;
	triggerButtonProps: IEvButtonProps;
}

const EditPropertyPopup: FC<IEditPropertyPopupProps> = ({
	onSaveChanges,
	propertyId,
	triggerButtonProps
}: IEditPropertyPopupProps) => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [propertyData, setPropertyData] = useState<IProperty>(null);
	const [propertyAndUrls, setPropertyAndUrls] = useState<IProperty>(null);
	const [isCrawlInProgress, setCrawlInProgress] = useState<boolean>();
	const [errors, setErrors] = useState<string[]>([]);
	const notificationsContext = useNotifications();

	const createPropertyAndUrlSetJson = (
		propertyData: IProperty,
		urlSetConfig: IUrlSetConfiguration
	): IProperty => {
		return {
			id: propertyData.id,
			name: propertyData.name,
			urlSetConfiguration: urlSetConfig
		};
	};

	const setPropertyState = (propertyData: IProperty, urlSet?: IUrlSet): void => {
		let propertyAndUrls: IProperty = null;
		let isCrawlInProgress = false;

		if (urlSet) {
			propertyAndUrls = createPropertyAndUrlSetJson(propertyData, urlSet.configuration);
			isCrawlInProgress = ScanCrawlStateHelper.isInProgress(urlSet.state);
		} else {
			propertyAndUrls = createPropertyAndUrlSetJson(propertyData, propertyData.urlSetConfiguration);
		}

		setPropertyData(propertyData);
		setPropertyAndUrls(propertyAndUrls);
		setCrawlInProgress(isCrawlInProgress);
		setLoading(false);
	};

	const loadProperty = (): void => {
		propertiesService
			.getProperty(propertyId)
			.then((propertyData) => {
				// if there is a urlset, take the data from there
				if (propertyData.scanConfiguration.urlSetId) {
					urlSetService.getUrlSet(propertyData.scanConfiguration.urlSetId).then((urlSetConfig) => {
						setPropertyState(propertyData, urlSetConfig);
					});
				} else {
					// else take the data from the property itself
					setPropertyState(propertyData);
				}
			})
			.catch((err) => {
				notificationsContext.alert({ title: 'Error loading property', serverError: err });
			});
	};

	const onSave = (dataToSave: IProperty, close: () => void): void => {
		const propertyToSave = { ...propertyData };
		propertyToSave.name = dataToSave.name;
		propertyToSave.urlSetConfiguration = dataToSave.urlSetConfiguration;
		const isListMode = PropertySourceHelper.isListMode(propertyToSave?.urlSetConfiguration?.source);
		const formErrors: FormError[] = validateProperty(propertyToSave, isListMode);
		if (formErrors.length) {
			setErrors(formErrors.map((e) => e.message));
			return;
		}
		setErrors([]);
		onSaveChanges(dataToSave, close);
	};

	const closePopup = (propertyToSave, close): void => {
		close();
	};

	return (
		<JsonPopup
			title="Edit Property"
			initialValue={propertyAndUrls}
			isReadonly={isCrawlInProgress}
			approveButtonText={isCrawlInProgress ? 'Close' : 'Save'}
			commentText={isCrawlInProgress ? 'Cannot edit property, there is a crawl in process.' : ''}
			onApprove={isCrawlInProgress ? closePopup : onSave}
			onOpen={loadProperty}
			triggerButtonProps={triggerButtonProps}
			isLoading={isLoading}
			errors={errors}
		/>
	);
};

export default EditPropertyPopup;
