/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
	BUTTON_TYPES,
	EvConfirm,
	EvToast,
	SORT_ORDER,
	TOAST_STATUS
} from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SITE_SCANNER_APP_ID } from '../../../../consts/dom-consts';
import { formatDate } from '../../../../helpers/DateFormatHelper';
import {
	IPropertyViewContext,
	useProperyViewContext
} from '../../../../providers/PropertyViewProvider';
import Logger from '../../../../services/Logger';
import scanService from '../../../../services/scan/ScanService';
import scansService from '../../../../services/scan/ScansService';
import { FilterParams } from '../../../../types/FilterParams';

type ICsvExportButton = {
	scanId: string;
	propertyId: string;
	filtersParams: FilterParams;
	sortParams: { sortField: string; sortOrder: SORT_ORDER };
};

const buttonTitle = 'Download CSV';

const successAnouncement = 'Export started. You will get it by email in a few minutes.';
const defaultErrorAnouncement = 'Server error, please try again.';
const errorAnouncement = 'Unable to export. Filter the issue count below 200k.';
const downloadButtonTitle = 'Download Issues';

const CsvExportButton: FC<ICsvExportButton> = ({
	scanId,
	propertyId,
	filtersParams,
	sortParams
}) => {
	const [toastStatus, setToastStatus] = useState(TOAST_STATUS.SUCCESS);
	const [announcement, setAnnouncement] = useState(successAnouncement);
	const [showAnnouncement, setShowAnnouncement] = useState(false);
	const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
	const [scanFromServer, setScanFromServer] = useState(null);

	const properyViewContext: IPropertyViewContext = useProperyViewContext();
	const currentViewId: string = useMemo(
		() => properyViewContext?.currentView?.id || null,
		[properyViewContext]
	);

	const csvButtonRef = useRef(null);

	const onToastClose = (): void => {
		setShowAnnouncement(false);
		csvButtonRef.current.focus();
	};

	const getScanFromServer = useCallback(async (): Promise<void> => {
		try {
			const scan = await scansService.getDoneScan(propertyId, scanId, currentViewId);
			setScanFromServer(scan);
		} catch (err) {
			Logger.error(err);
		}
	}, [propertyId, scanId, currentViewId]);

	useEffect(() => {
		getScanFromServer();
	}, [getScanFromServer]);

	const onButtonClick = (): void => {
		setExportButtonDisabled(true);

		scanService
			.getScanFilteredIssuesCsvRequest(scanId, filtersParams, sortParams, currentViewId)
			.then(() => {
				setAnnouncement(successAnouncement);
				setToastStatus(TOAST_STATUS.SUCCESS);
				setShowAnnouncement(true);
			})
			.catch(({ response }) => {
				switch (response?.status) {
					case 400:
						setToastStatus(TOAST_STATUS.FAIL);
						setAnnouncement(response?.data?.message || errorAnouncement);
						break;
					case 409:
						setToastStatus(TOAST_STATUS.SUCCESS);
						setAnnouncement(successAnouncement);
						break;
					default:
						setToastStatus(TOAST_STATUS.FAIL);
						setAnnouncement(defaultErrorAnouncement);
				}
				setShowAnnouncement(true);
			});
		setExportButtonDisabled(false);
	};

	return (
		<>
			{showAnnouncement && (
				<EvToast
					announcement={announcement}
					status={toastStatus}
					show={showAnnouncement}
					onToastClose={onToastClose}
				/>
			)}
			<EvConfirm
				appElement={SITE_SCANNER_APP_ID}
				title={downloadButtonTitle}
				promptMessage={`Scan: ${formatDate(scanFromServer?.createdTime)}.`}
				approveButtonText={buttonTitle}
				triggerButtonProps={{
					type: BUTTON_TYPES.PRIMARY,
					title: buttonTitle,
					children: buttonTitle,
					ref: csvButtonRef
				}}
				disabled={exportButtonDisabled}
				onConfirm={(closeModal) => {
					closeModal();
					onButtonClick();
				}}
			/>
		</>
	);
};

export default CsvExportButton;
