import React, { FC } from 'react';
import { useHistory } from 'react-router';
import RoutesHelper from '../helpers/RoutesHelper';
import { useUserTenant } from '../providers/userTenantProvider/UserTenantProvider';

const withLimitedAccess = (WrappedComponent): FC => {
	return (props): JSX.Element => {
		const { isReadOnlyUser } = useUserTenant();
		const history = useHistory();
		if (isReadOnlyUser()) {
			history.replace(RoutesHelper.getUnauthorized403Page());
			return null;
		}

		return <WrappedComponent {...props} />;
	};
};

export default withLimitedAccess;
