import {
	EvLink,
	EvLinkTypes,
	EvTableColumn,
	EvTableOptions,
	SORT_ORDER,
	TableSortOption
} from '@evinced-private/ui-common';
import React from 'react';
import EllipsedTextWithTooltip from '../../../../components/common/ellipsed-text-with-tooltip/EllipsedTextWithTooltip';
import EvLinkLocal from '../../../../components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../../../../components/common/ev-table/cell-formatters/numberCellFormatter';
import { ScanTableDefinition } from '../../../../components/scan-analysis/base/BaseScanViewV2';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import { PageData } from '../../../../interfaces/PagesPaginationResponse';

const PAGE_TITLE_MAX_LENGTH = '180px';

const getDefaultSort = (): TableSortOption => ({
	dataField: 'totalIssues',
	order: SORT_ORDER.DESC
});

const getColumns = (propertyId: string, scanId: string, pageId: string): EvTableColumn[] => {
	return [
		{
			dataField: 'url',
			text: 'URL',
			style: { maxWidth: '200px' },
			headerStyle: { maxWidth: '200px' },
			sort: true,
			formatter: (url: string) => {
				return (
					<EvLinkLocal
						linkText={url}
						url={RoutesHelper.getUrlDrilldownPath(propertyId, scanId, url)}
						customTooltip={{ dataTip: url, dataFor: 'table-tooltip' }}
						type={EvLinkTypes.DARK}
						pageId={pageId}
					/>
				);
			}
		},
		{
			dataField: 'pageTitle',
			text: 'Page Title',
			style: { maxWidth: PAGE_TITLE_MAX_LENGTH },
			headerStyle: { maxWidth: PAGE_TITLE_MAX_LENGTH },
			sort: true,
			formatter: (pageTitle: string) => {
				let formattedPageTitle: string;
				if (pageTitle === '') {
					formattedPageTitle = 'Untitled';
				} else {
					formattedPageTitle = pageTitle || 'N/A';
				}

				return (
					<EllipsedTextWithTooltip
						text={formattedPageTitle}
						maxWidth={PAGE_TITLE_MAX_LENGTH}
						showTooltipOnlyOnEllipsed
					/>
				);
			}
		},
		{
			dataField: 'totalIssues',
			text: 'Total issues',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'critical',
			text: 'Critical',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'serious',
			text: 'Serious',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'moderate',
			text: 'Moderate',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},
		{
			dataField: 'minor',
			text: 'Minor',
			style: { width: '100px' },
			headerStyle: { width: '100px' },
			sort: true,
			formatter: renderNumberCellFormatter
		},

		{
			dataField: 'url',
			text: 'Actions',
			style: { width: '400px' },
			headerStyle: { width: '400px' },
			sort: false,
			formatter: (url: string) => {
				return (
					<EvLink
						url={url}
						linkText="Open page"
						type={EvLinkTypes.DARK}
						openInNewTab={true}
						showIcon={true}
					/>
				);
			}
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Scan pages',
		caption: 'Scan pages table',
		pagination: true,
		dataType: 'pages with issues',
		remote: true,
		defaultSorted: [getDefaultSort()]
	};
};

type PagesTableRowData = {
	url: string;
	totalIssues: number;
	critical: number;
	serious: number;
	moderate: number;
	minor: number;
};
const getTableData = (pages: PageData[]): PagesTableRowData[] => {
	return pages.map((p) => {
		return {
			id: p.url,
			url: p.url,
			pageTitle: p.pageTitle,
			totalIssues: p.totalIssues || 0,
			critical: p.critical || 0,
			serious: p.serious || 0,
			moderate: p.moderate || 0,
			minor: p.minor || 0
		};
	});
};

const getTableDefinition = (
	pages: PageData[],
	propertyId: string,
	scanId: string,
	totalCount: number,
	totalPaginationableResults: number,
	pageId?: string
): ScanTableDefinition => {
	const options = getOptions();
	const result: ScanTableDefinition = {
		columns: getColumns(propertyId, scanId, pageId),
		options,
		data: getTableData(pages),
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition, getDefaultSort };
