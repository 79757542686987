import { OptionType } from '@evinced-private/ui-common';
import UUIDHelper from '../../helpers/UUIDHelper';
import urlDiscoveryScopeHelper from '../../helpers/UrlDiscoveryScopeHelper';
import {
	FILTER_NAMES,
	IFilter,
	INewView,
	IPropertyView,
	IViewState
} from '../../interfaces/PropertyViews';
import { IScopeRuleUIFormat } from '../../interfaces/ScopeRuleUIFormat';
import { createView, updateView } from '../../services/PropertyViewsService';
import SCOPE_ACTIONS from '../../types/scope/ScopeActions';
import SCOPE_TYPES from '../../types/scope/ScopeTypes';

const SELECT_ALL = 'Select all';

export const createDefaultViewsRulesList = (issuesList: OptionType[]): IScopeRuleUIFormat[] => {
	return [
		urlDiscoveryScopeHelper.createDefaultRuleInUIFormat(SCOPE_TYPES.URL),
		urlDiscoveryScopeHelper.createDefaultRuleInUIFormat(SCOPE_TYPES.ISSUE_TYPES, issuesList)
	];
};

const removeSelectAllOption = (list: string[]): string[] => {
	if (list[0] === SELECT_ALL) {
		list.shift();
	}
	return list;
};

const buildFiltersList = (rulesList: IScopeRuleUIFormat[]): IFilter[] => {
	const excludedUrls: string[] = [];
	const includedUrls: string[] = [];
	const excludedIssueTypes: string[] = [];
	const includedIssueTypes: string[] = [];
	const filters: IFilter[] = [];

	rulesList.forEach((rule: IScopeRuleUIFormat) => {
		if (rule.scopeType === SCOPE_TYPES.ISSUE_TYPES && rule.issueTypes?.length) {
			if (rule.scopeAction === SCOPE_ACTIONS.INCLUDE) {
				includedIssueTypes.push(...rule.issueTypes);
			} else {
				excludedIssueTypes.push(...rule.issueTypes);
			}
		} else if (rule.scopeType === SCOPE_TYPES.URL && rule.url) {
			if (rule.scopeAction === SCOPE_ACTIONS.INCLUDE) {
				includedUrls.push(rule.url);
			} else {
				excludedUrls.push(rule.url);
			}
		}
	});

	if (includedUrls.length) {
		filters.push({ name: FILTER_NAMES.URLS, included: true, value: includedUrls });
	}

	if (excludedUrls.length) {
		filters.push({ name: FILTER_NAMES.URLS, included: false, value: excludedUrls });
	}

	if (includedIssueTypes.length) {
		filters.push({
			name: FILTER_NAMES.ISSUE_TYPES,
			included: true,
			value: removeSelectAllOption(includedIssueTypes)
		});
	}

	if (excludedIssueTypes.length) {
		filters.push({
			name: FILTER_NAMES.ISSUE_TYPES,
			included: false,
			value: removeSelectAllOption(excludedIssueTypes)
		});
	}

	return filters;
};

export const saveNewView = async (view: IViewState): Promise<void> => {
	const filtersList: IFilter[] = buildFiltersList(view.rulesList);
	const newView: INewView = {
		name: view.name,
		propertyId: view.propertyId,
		configuration: {
			filters: filtersList
		}
	};
	await createView(newView);
};

export const editExistingView = async (view: IViewState): Promise<void> => {
	const filtersList: IFilter[] = buildFiltersList(view.rulesList);
	const updatedView: IPropertyView = {
		id: view.id,
		name: view.name,
		propertyId: view.propertyId,
		configuration: {
			filters: filtersList
		}
	};
	await updateView(updatedView);
};

const getUrlRuleInUIFormat = (url: string, scopeAction: SCOPE_ACTIONS): IScopeRuleUIFormat => {
	return {
		id: UUIDHelper.generateUUID(),
		url,
		scopeAction,
		scopeType: SCOPE_TYPES.URL
	};
};

const getIssueTypesRuleInUIFormat = (
	issueTypes: string[],
	scopeAction: SCOPE_ACTIONS
): IScopeRuleUIFormat => {
	return {
		id: UUIDHelper.generateUUID(),
		url: '',
		issueTypes,
		scopeAction,
		scopeType: SCOPE_TYPES.ISSUE_TYPES
	};
};

/**
 * For Edit View - Builds Rules list from View Filters (as received from server).
 * 1. Filters List can have 4 items - included/excluded urls, included/excluded issue types.
 * 2. Build includedUrls, excludedUrls, includedIssues, excludedIssues Lists
 * 3. these lists will be used to create URL rules and IssueType rules
 *
 * URLS:
 * 4. for each item in includedUrls/excludedUrls create UI Rule
 * 		- use url value and include/exclude - push to final RulesList[]
 *
 * Issue Types:
 * 5. each item in includedIssues should be added to included options array
 * 6. each item in excludedIssues should be added to excluded options array
 */

export const buildRulesList = (filtersList: IFilter[]): IScopeRuleUIFormat[] => {
	const includedUrls: string[] = [];
	const excludedUrls: string[] = [];
	const includedIssues: string[] = [];
	const excludedIssues: string[] = [];
	const finalRulesList: IScopeRuleUIFormat[] = [];

	filtersList.forEach((filter: IFilter) => {
		if (filter.name === FILTER_NAMES.URLS) {
			if (filter.included) {
				includedUrls.push(...filter.value);
			} else {
				excludedUrls.push(...filter.value);
			}
		}
		if (filter.name === FILTER_NAMES.ISSUE_TYPES) {
			if (filter.included) {
				includedIssues.push(...filter.value);
			} else {
				excludedIssues.push(...filter.value);
			}
		}
	});

	if (includedUrls.length > 0) {
		finalRulesList.push(
			...includedUrls.map((url: string) => getUrlRuleInUIFormat(url, SCOPE_ACTIONS.INCLUDE))
		);
	}

	if (excludedUrls.length > 0) {
		finalRulesList.push(
			...excludedUrls.map((url: string) => getUrlRuleInUIFormat(url, SCOPE_ACTIONS.EXCLUDE))
		);
	}

	if (includedIssues.length > 0) {
		finalRulesList.push(getIssueTypesRuleInUIFormat(includedIssues, SCOPE_ACTIONS.INCLUDE));
	}

	if (excludedIssues.length > 0) {
		finalRulesList.push(getIssueTypesRuleInUIFormat(excludedIssues, SCOPE_ACTIONS.EXCLUDE));
	}

	return finalRulesList;
};
