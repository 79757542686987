import { BUTTON_TYPES, EvButton, EvIcon, EvSelect, OptionType } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { IScopeRuleUIFormat } from '../../../../interfaces/ScopeRuleUIFormat';
import SCOPE_ACTIONS from '../../../../types/scope/ScopeActions';
import RemoveIcon from '../../../icons/RemoveIcon.svg';

const REMOVE_ISSUE_BUTTON_TITLE = 'Remove Issue Type Rule';
const ISSUE_TYPES_ACTIONS: OptionType[] = [
	{ label: 'Include Issue Types', value: SCOPE_ACTIONS.INCLUDE },
	{ label: 'Exclude Issue Types', value: SCOPE_ACTIONS.EXCLUDE }
];

interface IIssueTypesRuleRowProps {
	rule: IScopeRuleUIFormat;
	issueTypesList: OptionType[];
	onRemove?: () => void;
	onRuleChange: (rule: IScopeRuleUIFormat) => void;
}

const IssueTypesRuleRow: FC<IIssueTypesRuleRowProps> = ({
	rule,
	issueTypesList,
	onRemove,
	onRuleChange
}) => {
	const [selectedAction, setSelectedAction] = useState<OptionType>(
		ISSUE_TYPES_ACTIONS.find((action) => action.value === rule.scopeAction)
	);

	const [selectedIssueTypes, setSelectedIssueTypes] = useState<OptionType[]>(() => {
		if (rule.issueTypes?.length) {
			return rule.issueTypes.map((issueType: string) => {
				return { label: issueType, value: issueType };
			});
		}
		return issueTypesList;
	});

	return (
		<div className="rule-row issue-type-rule">
			<EvSelect
				className="issue-type-rule-select"
				placeholder="Select Action"
				value={[selectedAction]}
				options={ISSUE_TYPES_ACTIONS}
				onChange={(selectedItem: OptionType) => {
					setSelectedAction(selectedItem);
					onRuleChange({
						...rule,
						scopeAction: selectedItem.value as SCOPE_ACTIONS
					});
				}}
			/>
			<EvSelect
				className="issue-types-select-list"
				placeholder="Select Issue Type"
				allowSelectAll
				allSelectedPlaceholder="All issue types"
				isMulti
				value={selectedIssueTypes}
				options={issueTypesList}
				onChange={(issueTypes: []) => {
					setSelectedIssueTypes(issueTypes);
					onRuleChange({
						...rule,
						issueTypes: issueTypes.map((issue: OptionType) => issue.label)
					});
				}}
			/>
			<EvButton
				className="delete-rule-btn"
				key="Remove"
				title={REMOVE_ISSUE_BUTTON_TITLE}
				type={BUTTON_TYPES.ICON}
				onClick={onRemove}
			>
				<EvIcon icon={RemoveIcon} />
			</EvButton>
		</div>
	);
};

export default IssueTypesRuleRow;
