import { EvMarkdownViewer, EvTitle, HeadingLevelRange } from '@evinced-private/ui-common';
import React, { FC } from 'react';

interface IIssueTextBlockProps {
	sectionName: string;
	stringToRender: string;
	headingLevel?: HeadingLevelRange;
}

export const renderPart = (
	name: string,
	value: JSX.Element | string,
	headingLevel: HeadingLevelRange = 2
): JSX.Element => {
	return (
		<div className="part">
			<EvTitle headingLevel={headingLevel} className="name" titleText={name} />
			<div className="value">{value}</div>
		</div>
	);
};

const IssueTextBlock: FC<IIssueTextBlockProps> = ({
	sectionName,
	stringToRender,
	headingLevel
}): JSX.Element => {
	if (!stringToRender) {
		return null;
	}
	return renderPart(sectionName, <EvMarkdownViewer initialValue={stringToRender} />, headingLevel);
};

export default IssueTextBlock;
