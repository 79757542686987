import { EvLink } from '@evinced-private/ui-common';
import React from 'react';
import './ContactSupportMailLink.scss';

export const SUPPORT_EMAIL = 'support@evinced.com';

const ContactSupportMailLink = ({ linkText = null }): JSX.Element => (
	<EvLink
		className="contact-us-link"
		url={`mailto:${SUPPORT_EMAIL}`}
		linkText={linkText || SUPPORT_EMAIL}
	/>
);

export default ContactSupportMailLink;
