import { TableSortOption } from '@evinced-private/ui-common';
import _ from 'lodash';
import { action, makeObservable, observable, ObservableMap } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export const temporaryTableId = 'temporary-id';

interface IColumnFilterValue {
	columnId: string;
	value: string;
}

interface ITablePagination {
	page: number;
	sizePerPage: number;
}
class TableState {
	columnFilters: Array<IColumnFilterValue> = [];

	tableSort: Array<TableSortOption> = null;

	paginationInfo: ITablePagination = { page: 1, sizePerPage: 10 };
}

export default class TablesStore {
	constructor() {
		makeObservable(this, {
			tablesStates: observable,
			updateFilter: action,
			updateSortOrder: action,
			updatePagination: action,
			updateTableState: action
		});

		makePersistable(this, {
			name: 'TablesStateStore',
			properties: ['tablesStates'],
			storage: window.localStorage
		});
	}

	tablesStates = new ObservableMap<string, TableState>();

	updateFilter(tableId: string, columnId: string, value: string): void {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		const existingColumnFilter = tableState.columnFilters.find((f) => f.columnId === columnId);
		if (existingColumnFilter) {
			if (existingColumnFilter.value === value) {
				return;
			}
			existingColumnFilter.value = value;
		} else {
			tableState.columnFilters.push({ columnId, value });
		}
		this.tablesStates.set(tableId, { ...tableState });
	}

	updateTableState(
		tableId: string,
		sortOrder?: TableSortOption[],
		paginationInfo?: ITablePagination
	): void {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		const tableStateClone = { ...tableState };
		if (sortOrder) {
			tableStateClone.tableSort = sortOrder;
		}
		if (paginationInfo) {
			tableStateClone.paginationInfo = paginationInfo;
		}
		if (_.isEqual(tableStateClone, tableState)) {
			return;
		}
		this.tablesStates.set(tableId, { ...tableStateClone });
	}

	updateSortOrder(tableId: string, sortOrder: TableSortOption[]): void {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		tableState.tableSort = sortOrder;
		this.tablesStates.set(tableId, { ...tableState });
	}

	updatePagination(tableId: string, paginationInfo: ITablePagination): void {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		tableState.paginationInfo = paginationInfo;
		this.tablesStates.set(tableId, { ...tableState });
	}

	getFilterValue(tableId: string, columnId: string): string {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		const existingColumnFilter = tableState.columnFilters.find((f) => f.columnId === columnId);
		if (existingColumnFilter) {
			return existingColumnFilter.value;
		}
		return '';
	}

	getSortOrder(tableId: string): Array<TableSortOption> {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		return tableState.tableSort;
	}

	getPagination(tableId: string): ITablePagination {
		const tableState = this.tablesStates.get(tableId) || new TableState();
		return tableState.paginationInfo;
	}
}
