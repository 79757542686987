import { BUTTON_TYPES, EvSection, EvSpinner, EvMainTitle } from '@evinced-private/ui-common';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import EditViewButton from '../../components/edit-view-popup/EditViewButton';
import ManageViewsTable from '../../components/manage-views-table/ManageViewsTable';
import SearchFilter from '../../components/search-filter/SearchFilter';
import RoutesHelper from '../../helpers/RoutesHelper';
import withLimitedAccess from '../../hoc/withLimitedAccess';
import { useNavigation } from '../../providers/navigationProvider/NavigationProvider';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import { INotificationsContext } from '../../providers/notificationsProvider/NotificationsContext';
import Logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import './ManageViewsPage.scss';

const MANAGE_VIEWS_PAGE_TITLE = 'Manage Views';

const ManageViewsPage: FC = () => {
	const { propertyId } = useParams<{ propertyId: string }>();

	const [searchFilterValue, setSearchFilterValue] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const notificationsContext: INotificationsContext = useNotifications();
	const { setCurrentRoute } = useNavigation();

	useEffect(() => {
		setIsLoading(true);
		propertiesService
			.getProperty(propertyId)
			.then(() => {
				setCurrentRoute({
					id: `${MANAGE_VIEWS_PAGE_TITLE}-${propertyId}`,
					url: RoutesHelper.getManageViewsPath(propertyId),
					title: MANAGE_VIEWS_PAGE_TITLE
				});
			})
			.catch((err) => {
				Logger.error('ManageViewsPage failed to load property', err);
			})
			.finally(() => {
				setIsLoading(false);
			});

		return () => {
			notificationsContext.toast({ show: false });
			setCurrentRoute(null);
		};
	}, [propertyId, notificationsContext, setCurrentRoute]);

	return isLoading ? (
		<EvSpinner />
	) : (
		<div className="manage-views-page">
			<div className="manage-views-page-header">
				<EvMainTitle className="page-heading">{MANAGE_VIEWS_PAGE_TITLE}</EvMainTitle>
				<div className="add-view-actions">
					<EditViewButton
						type={BUTTON_TYPES.PRIMARY}
						propertyId={propertyId}
						innerText="Create View"
					/>
				</div>
			</div>

			<SearchFilter searchInputValue={searchFilterValue} onInputChange={setSearchFilterValue} />

			<EvSection ariaLabel="Manage Views table">
				<ManageViewsTable searchFilter={searchFilterValue} />
			</EvSection>
		</div>
	);
};

export default withLimitedAccess(ManageViewsPage);
