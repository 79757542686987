import AuthenticationService from './AuthenticationService';
import { DevelopmentToggles } from './TogglesService';

type UserToggles = {
	[key in DevelopmentToggles]: boolean;
};

const getUserToggles = (): UserToggles => {
	const appMetadata = AuthenticationService.extractAppMetadata();
	return appMetadata.TOGGLES || {};
};

export default { getUserToggles };
