import { useLocation } from 'react-router-dom';

type LocationFilters = { [key: string]: string };

const useLocationFilters = (): LocationFilters => {
	const location = useLocation<LocationFilters>();
	const { componentId, type, severity, isTemporary }: LocationFilters = location.state || {};

	return { componentId, type, severity, isTemporary };
};

export default useLocationFilters;
