import { SORT_ORDER } from '@evinced-private/ui-common';
import { TPropertiesInfoSortOptions, TSortOption } from './DashboardTypes';

export enum PROPERTIES_INFO_TYPES {
	PROPERTY_VIEW_NAME = 'PropertyView',
	SCORE_LIFT = 'scoreLift',
	SCORE = 'score',
	SCORE_100 = 'score_100',
	CRITICAL_ISSUES_COUNT = 'criticalIssuesCount',
	PAGES_COUNT = 'pagesCount'
}

const PROPERTIES_INFO_COLUMNS_LABELS = {
	[PROPERTIES_INFO_TYPES.PROPERTY_VIEW_NAME]: 'Property / View',
	[PROPERTIES_INFO_TYPES.SCORE_LIFT]: 'Score Change',
	[PROPERTIES_INFO_TYPES.SCORE]: 'Score',
	[PROPERTIES_INFO_TYPES.SCORE_100]: 'Score / 100',
	[PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT]: 'Critical Issues',
	[PROPERTIES_INFO_TYPES.PAGES_COUNT]: 'Pages Monitored'
};

const getOptionValue = (key, order): string => `${key}${order}`;
export const getLabel = (key, order = null): string => {
	const optionLabel = PROPERTIES_INFO_COLUMNS_LABELS[key];
	switch (order) {
		case SORT_ORDER.DESC:
			return `${optionLabel} (High to low)`;
		case SORT_ORDER.ASC:
			return `${optionLabel} (Low to high)`;
		default:
			return optionLabel;
	}
};
const getPropertySortOption = (
	optionVal: PROPERTIES_INFO_TYPES,
	optionOrder: SORT_ORDER
): TSortOption => {
	return {
		sortBy: optionVal,
		orderBy: optionOrder,
		value: getOptionValue(optionVal, optionOrder),
		label: getLabel(optionVal, optionOrder)
	};
};

export enum KPIS {
	AVERAGE_SCORE_KPI = 'averageScoreKpi',
	TENANT_CRITICAL_ISSUES_KPI = 'tenantCriticalIssuesKpi',
	PAGES_MONITORED_KPI = 'pagesMonitoredKpi'
}

export const DEFAULT_SORT_OPTION_VALUES = {
	sortBy: PROPERTIES_INFO_TYPES.SCORE_LIFT,
	orderBy: SORT_ORDER.ASC
};

export const DEFAULT_SORT_OPTION = {
	sortBy: DEFAULT_SORT_OPTION_VALUES.sortBy,
	orderBy: DEFAULT_SORT_OPTION_VALUES.orderBy,
	value: getOptionValue(DEFAULT_SORT_OPTION_VALUES.sortBy, DEFAULT_SORT_OPTION_VALUES.orderBy),
	label: getLabel(DEFAULT_SORT_OPTION_VALUES.sortBy, DEFAULT_SORT_OPTION_VALUES.orderBy)
};

export const PROPERTIES_INFO_SORT_OPTIONS: TPropertiesInfoSortOptions = [
	{ value: PROPERTIES_INFO_TYPES.SCORE_LIFT, order: SORT_ORDER.DESC },
	{ value: PROPERTIES_INFO_TYPES.SCORE_LIFT, order: SORT_ORDER.ASC },
	{ value: PROPERTIES_INFO_TYPES.SCORE, order: SORT_ORDER.DESC },
	{ value: PROPERTIES_INFO_TYPES.SCORE, order: SORT_ORDER.ASC },
	{ value: PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT, order: SORT_ORDER.DESC },
	{ value: PROPERTIES_INFO_TYPES.CRITICAL_ISSUES_COUNT, order: SORT_ORDER.ASC }
].map(({ value, order }) => getPropertySortOption(value, order));

export enum SCORE_LEVELS {
	EXCELLENT = 'EXCELLENT',
	GOOD = 'GOOD',
	MODERATE = 'MODERATE',
	POOR = 'POOR'
}

export enum PERFORMANCE_OVER_TIME_CHART_TYPE {
	SCORE = 'score',
	CRITICAL_ISSUES = 'criticalIssues',
	TOTAL_ISSUES = 'totalIssues'
}
