import {
	EvLinkTypes,
	EvTableColumn,
	EvTableOptions,
	SORT_ORDER,
	TableSortOption
} from '@evinced-private/ui-common';
import React from 'react';
import EvLinkLocal from '../../../../components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../../../../components/common/ev-table/cell-formatters/numberCellFormatter';
import { ScanTableDefinition } from '../../../../components/scan-analysis/base/BaseScanViewV2';
import RoutesHelper from '../../../../helpers/RoutesHelper';
import { IssueTypeData } from '../../../../interfaces/IssueTypesPaginationResponse';

const getDefaultSort = (): TableSortOption => ({
	dataField: 'issuesCount',
	order: SORT_ORDER.DESC
});

const getColumns = (propertyId: string, scanId: string, pageId: string): EvTableColumn[] => {
	return [
		{
			dataField: 'type',
			text: 'Issue type',
			style: { width: '200px' },
			headerStyle: { width: '200px' },
			sort: true,
			formatter: (type: string) => {
				return (
					<EvLinkLocal
						id={type}
						type={EvLinkTypes.DARK}
						url={RoutesHelper.getIssuetypeDrilldownPath(propertyId, scanId, type)}
						linkText={type}
						pageId={pageId}
					/>
				);
			}
		},
		{
			dataField: 'severity',
			text: 'Severity',
			style: { width: '150px' },
			headerStyle: { width: '150px' },
			sort: true
		},
		{
			dataField: 'issuesCount',
			text: 'Total issues',
			style: { width: '500px' },
			headerStyle: { width: '500px' },
			sort: true,
			formatter: renderNumberCellFormatter
		}
	];
};

const getOptions = (): EvTableOptions => {
	return {
		title: 'Scan issue types',
		caption: 'Scan issue types table',
		pagination: true,
		dataType: 'issue types',
		remote: true,
		defaultSorted: [getDefaultSort()]
	};
};

type IssuesTableRowData = {
	id: string;
	type: string;
	severity: string;
	issuesCount: number;
};
const getTableData = (types: IssueTypeData[]): IssuesTableRowData[] => {
	return types.map((i) => {
		return {
			id: `${i.type}-${i.severity}`,
			type: i.type,
			severity: i.severity,
			issuesCount: i.issuesCount
		};
	});
};

const getTableDefinition = (
	issueTypes: IssueTypeData[],
	propertyId: string,
	scanId: string,
	totalCount: number,
	totalPaginationableResults: number,
	pageId?: string
): ScanTableDefinition => {
	const options = getOptions();
	const result: ScanTableDefinition = {
		columns: getColumns(propertyId, scanId, pageId),
		options,
		data: getTableData(issueTypes),
		totalCount,
		totalPaginationableResults
	};
	return result;
};
export default { getTableDefinition, getDefaultSort };
