import { EVINCED_SEVERITIES_IDS } from '@evinced-private/ui-common';

export enum SEVERITIES_CHART_TYPE {
	TOTAL_ISSUES = 'totalIssues',
	PERCENTAGE = 'percentage',
	AVERAGE_PER_PAGE = 'avgPerPage'
}

export type SeverityTrendsDatum = {
	scanId: string;
	date: string;
	total: number;
	pages?: number;
	[EVINCED_SEVERITIES_IDS.CRITICAL]: number;
	[EVINCED_SEVERITIES_IDS.SERIOUS]: number;
	[EVINCED_SEVERITIES_IDS.BEST_PRACTICE]: number;
	[EVINCED_SEVERITIES_IDS.NEEDS_REVIEW]: number;
	[EVINCED_SEVERITIES_IDS.MINOR]: number;
	[EVINCED_SEVERITIES_IDS.MODERATE]: number;
};
