import {
	EvLink,
	EvSection,
	EvLinkTypes,
	EvTableColumn,
	EvTitle,
	SORT_ORDER
} from '@evinced-private/ui-common';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import React, { FC, useMemo } from 'react';
import EvLinkLocal from '../../../../../../components/common/ev-link-local/EvLinkLocal';
import renderNumberCellFormatter from '../../../../../../components/common/ev-table/cell-formatters/numberCellFormatter';
import SiteScannerTable from '../../../../../../components/common/site-scanner-table/SiteScannerTable';
import RoutesHelper from '../../../../../../helpers/RoutesHelper';
import { IssueSeverities } from '../../../../../../interfaces/IssuesOverviewResponse';
import { IssueTypeCount } from '../../../../../../services/scan/ScanOverviewService';
import './MostFrequestIssuesTable.scss';

const MOST_FREQUENT_ISSUES_TABLE = 'most-frequent-issues-table-title';

interface IMostFrequestIssuesTable {
	issueSeverities: IssueSeverities[];
	propertyId: string;
	scanId: string;
}

const MostFrequentIssuesTable: FC<IMostFrequestIssuesTable> = ({
	issueSeverities,
	scanId,
	propertyId
}: IMostFrequestIssuesTable) => {
	const NUMBER_OF_ROWS_TO_SHOW = 5;

	const tableId = useMemo(() => {
		return scanId
			? `scan-${scanId}-top-five-issues-table`
			: `property-${propertyId}-top-five-issues-table`;
	}, [scanId, propertyId]);

	const getTableData = (): IssueTypeCount[] => {
		if (!issueSeverities) {
			return [];
		}
		const issueTypeCounts = flatten(
			issueSeverities.map(({ types, severity }) =>
				types.map(({ issuesCount, type }) => ({
					count: issuesCount,
					type,
					severity
				}))
			)
		);
		return orderBy(issueTypeCounts, 'count', 'desc').slice(0, NUMBER_OF_ROWS_TO_SHOW);
	};

	const getColumns = (): EvTableColumn[] => {
		return [
			{
				dataField: 'type',
				text: 'Type',
				style: { width: '40px' },
				headerStyle: { width: '40px' },
				sort: false,
				// eslint-disable-next-line react/no-unstable-nested-components
				formatter: (type: string) => {
					return scanId ? (
						<EvLinkLocal
							url={RoutesHelper.getIssuetypeDrilldownPath(propertyId, scanId, type)}
							linkText={type}
							type={EvLinkTypes.DARK}
						/>
					) : (
						<div>{type}</div>
					);
				}
			},
			{
				dataField: 'severity',
				text: 'Severity',
				style: { width: '40px' },
				headerStyle: { width: '40px' },
				sort: false
			},
			{
				dataField: 'count',
				text: 'Count',
				style: { width: '40px' },
				headerStyle: { width: '40px' },
				sort: false,
				formatter: renderNumberCellFormatter
			}
		];
	};

	const tableData = getTableData();
	return (
		<EvSection
			className="most-frequest-issues"
			ariaLabelledby={MOST_FREQUENT_ISSUES_TABLE}
			skipLinkId="most-frequest"
		>
			<div className="title">
				<EvTitle titleText="Top 5 issue types" id={MOST_FREQUENT_ISSUES_TABLE} />
				<div className="actions">
					<EvLink
						linkText="View all issue types"
						url={RoutesHelper.getScanResultIssuesBreakdownPath(propertyId, scanId)}
						ariaLabel="view more issue types"
					/>
				</div>
			</div>
			<SiteScannerTable
				tableId={tableId}
				className="pages-table"
				tableData={tableData}
				totalCount={tableData.length}
				columns={getColumns()}
				options={{
					title: 'Top 5 issue types',
					caption: 'Top 5 issue types',
					defaultSorted: [
						{
							dataField: 'count',
							order: SORT_ORDER.DESC
						}
					],
					dataType: 'issue types'
				}}
				showInfoRow={false}
			/>
		</EvSection>
	);
};

export default MostFrequentIssuesTable;
