import RoutesHelper from '../../helpers/RoutesHelper';

export enum HOME_PAGES {
	DASHBOARD = 'dashboard',
	ALL_PROPERTIES = 'allProperties'
}

export const HOME_PAGE_TABS = [
	{ key: HOME_PAGES.DASHBOARD, label: 'Dashboard', route: RoutesHelper.getDashboardPath() },
	{
		key: HOME_PAGES.ALL_PROPERTIES,
		label: 'All Properties',
		route: RoutesHelper.getPropertiesPagePath()
	}
];
