import { EvSpinner } from '@evinced-private/ui-common';
import React, { FC, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useUniversalLogin from '../../hooks/useUniversalLogin';
import JiraApi from '../../api/JiraApi';

const JiraAuthenticationPage: FC = () => {
	const history = useHistory();

	const search = useMemo(() => history?.location?.search, [history]);
	const { isAuthenticated } = useUniversalLogin();

	useEffect(() => {
		if (search) {
			const queryParams = new URLSearchParams(search);
			const code = queryParams.get('code');
			if (code) {
				JiraApi.getAndSaveJiraToken(code);
			}
		}
	}, [search]);

	if (!isAuthenticated()) {
		return <Redirect to="/login" />;
	}

	return <EvSpinner />;
};

export default JiraAuthenticationPage;
