import TabsViews from '../types/TabsViews';

import { TOKEN_QUERY_PARAM } from './InvitationTokenHelper';

const productHubUrl = process.env.PRODUCT_HUB_URL;

export default class RoutesHelper {
	public static COMPARE_SCANS_PARAMS = {
		BASE_SCAN_ID: 'base',
		COMPARABLE_SCAN_ID: 'comparable'
	};

	public static getViewIdParam(viewId: string = null, concatSign = '?'): string {
		return viewId ? `${concatSign}viewId=${viewId}` : '';
	}

	public static getHomepagePath(): string {
		return '/';
	}

	public static getPropertiesPagePath(): string {
		return '/properties';
	}

	public static getDashboardPath(): string {
		return '/dashboard';
	}

	public static getLoginPath(): string {
		return '/login';
	}

	public static getLoginSuccessPath(): string {
		return '/login-success';
	}

	public static getSignupPath(): string {
		return '/signup';
	}

	public static getPropertyPagePath(propertyId: string, viewId: string = null): string {
		return `/properties/${propertyId}${this.getViewIdParam(viewId)}`;
	}

	public static getScanResultsPagePath(
		propertyId: string,
		scanId: string,
		viewId: string = null
	): string {
		return `/properties/${propertyId}/scans/${scanId}${this.getViewIdParam(viewId)}`;
	}

	public static getScanUrlsPagePath(propertyId: string, scanId: string): string {
		return `/properties/${propertyId}/scans/${scanId}/urls`;
	}

	public static getPropertyTrendsPath(propertyId: string, viewId: string = null): string {
		return `/properties/${propertyId}/trends${this.getViewIdParam(viewId)}`;
	}

	public static getManageViewsPath(propertyId: string): string {
		return `/properties/${propertyId}/views`;
	}

	public static getViewName(view: TabsViews): string {
		switch (view) {
			case TabsViews.BREAKDOWN:
				return 'all-issues';
			case TabsViews.COMPONENTS:
				return 'components';
			case TabsViews.ISSUE_TYPES:
				return 'issue-types';
			case TabsViews.PAGES:
				return 'pages';
			default:
				return 'overview';
		}
	}

	public static getScanDetailsView(
		propertyId: string,
		scanId: string,
		view: TabsViews,
		viewId: string = null
	): string {
		return `/properties/${propertyId}/scans/${scanId}/${this.getViewName(
			view
		)}${this.getViewIdParam(viewId)}`;
	}

	public static getScanDetailsViewById(
		propertyId: string,
		scanId: string,
		view: TabsViews,
		id: string
	): string {
		return `/properties/${propertyId}/scans/${scanId}/${this.getViewName(view)}/${id}`;
	}

	public static getUrlSetPagePath(propertyId: string, urlSetId: string): string {
		return `/properties/${propertyId}/urlset/${urlSetId}`;
	}

	public static getNewPropertyCreationPagePath(baseUrl = ''): string {
		return `/properties/new/settings?baseurl=${baseUrl ? encodeURIComponent(baseUrl) : ''}`;
	}

	public static getPropertySettingsPagePath(propertyId: string): string {
		return `/properties/${propertyId}/settings`;
	}

	public static getSettingsPath(): string {
		return '/settings';
	}

	public static getPagePath(): string {
		return '/page';
	}

	public static getViewsPath(): string {
		return '/views';
	}

	public static getScanComparisonPath(): string {
		return '/scan-comparison';
	}

	public static getCrawlResultsPath(): string {
		return '/urlset';
	}

	public static getScannedPagesPath(): string {
		return '/urls-status';
	}

	public static getIssuesPath(): string {
		return '/issues';
	}

	public static getCommonListPath(): string {
		return '/scan-comparison/common-pages';
	}

	public static getNewPropertyPath(): string {
		return '/properties/new/settings';
	}

	public static getInvitationActivationPage(invitationToken: string): string {
		return `/invitation-activation${
			invitationToken ? `?${TOKEN_QUERY_PARAM}=${invitationToken}` : ''
		}`;
	}

	public static getNoTenantPage(): string {
		return '/no-tenant';
	}

	public static getProductActivationPage(): string {
		return '/product-activation';
	}

	public static getScanResultPagesBreakdownPath(propertyId: string, scanId: string): string {
		return this.getScanDetailsView(propertyId, scanId, TabsViews.PAGES);
	}

	public static getScanResultIssuesBreakdownPath(propertyId: string, scanId: string): string {
		return this.getScanDetailsView(propertyId, scanId, TabsViews.ISSUE_TYPES);
	}

	public static getScanResultComponentsBreakdownPath(propertyId: string, scanId: string): string {
		return this.getScanDetailsView(propertyId, scanId, TabsViews.COMPONENTS);
	}

	public static getScanResultComponentsBreakdownByIdPath(
		propertyId: string,
		scanId: string,
		componentId: string
	): string {
		return this.getScanDetailsViewById(propertyId, scanId, TabsViews.COMPONENTS, componentId);
	}

	public static getScanResultOverviewPath(propertyId: string, scanId: string): string {
		return this.getScanDetailsView(propertyId, scanId, TabsViews.OVERVIEW);
	}

	public static getScanResultUrlsStatusPath(propertyId: string, scanId: string): string {
		return `/properties/${propertyId}/scans/${scanId}/urls-status`;
	}

	public static getComponentDrilldownPath(
		propertyId: string,
		scanId: string,
		componentId: string
	): string {
		const baseComponentsPath = this.getScanResultComponentsBreakdownPath(propertyId, scanId);
		return `${baseComponentsPath}/${componentId}`;
	}

	public static getUrlDrilldownPath(propertyId: string, scanId: string, url: string): string {
		const baseUrlsPath = this.getScanResultsPagePath(propertyId, scanId);
		return `${baseUrlsPath}/page?url=${encodeURIComponent(url)}`;
	}

	public static getIssuetypeDrilldownPath(
		propertyId: string,
		scanId: string,
		issueType: string
	): string {
		const baseIssuesPath = this.getScanResultIssuesBreakdownPath(propertyId, scanId);
		return `${baseIssuesPath}/${issueType}`;
	}

	public static getScanComparisonPagePath(
		propertyId: string,
		baseScanId: string,
		comparableScanId: string,
		viewId: string = null
	): string {
		return `/properties/${propertyId}/scan-comparison?${
			this.COMPARE_SCANS_PARAMS.BASE_SCAN_ID
		}=${baseScanId}&${
			this.COMPARE_SCANS_PARAMS.COMPARABLE_SCAN_ID
		}=${comparableScanId}${this.getViewIdParam(viewId, '&')}`;
	}

	public static getCommonPagesPagePath(
		propertyId: string,
		baseScanId: string,
		comparableScanId: string,
		viewId: string = null
	): string {
		return `/properties/${propertyId}/scan-comparison/common-pages?${
			this.COMPARE_SCANS_PARAMS.BASE_SCAN_ID
		}=${baseScanId}&${
			this.COMPARE_SCANS_PARAMS.COMPARABLE_SCAN_ID
		}=${comparableScanId}${this.getViewIdParam(viewId, '&')}`;
	}

	public static getIssueDetailsPath(propertyId: string, scanId: string, issueId: string): string {
		return `/properties/${propertyId}/scans/${scanId}/issues/${issueId}`;
	}

	// External URLs
	public static getUsersInvitationsHubPath(): string {
		return `${productHubUrl}/manage-users/active-users`;
	}

	public static getUnauthorized403Page = (): string => {
		return '/unauthorized';
	};
}
