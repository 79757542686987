import { EvSpinner, IEvButtonProps } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import GA_CONSTS from '../../consts/google-analytics';
import { IUrlSet } from '../../interfaces/UrlSet';
import { useNotifications } from '../../providers/notificationsProvider/NotificationsConsumer';
import logger from '../../services/Logger';
import propertiesService from '../../services/PropertiesService';
import analyticsService from '../../services/analytics/AnalyticsService';
import scansService from '../../services/scan/ScansService';
import SiteScannerPopup from '../common/site-scanner-popup/SiteScannerPopup';

interface IStartScanPopupProps {
	propertyId: string;
	triggerButtonProps: IEvButtonProps;
	onScanCreated: () => void;
	urlSet: IUrlSet;
}

const StartScanPopup: FC<IStartScanPopupProps> = ({
	propertyId,
	triggerButtonProps,
	onScanCreated,
	urlSet
}: IStartScanPopupProps) => {
	const [isLoading, setLoading] = useState(true);
	const [isScanCurrentlyRunning, setScanCurrentlyRunning] = useState(false);
	const [isCrawlCurrentlyRunning, setCrawCurrentlyRunning] = useState(false);
	const [isPdfMode, setIsPdfMode] = useState(false);
	const [startScanClicked, setStartScanClicked] = useState(false);
	const notificationsContext = useNotifications();

	const checkIfScanOrCrawlAreRunning = async (): Promise<void> => {
		setLoading(true);
		const [isScanRuning, isCrawlRunning, isPdf] = await Promise.all([
			scansService.isScanCurrentlyRunning(propertyId),
			propertiesService.isCrawlCurrentlyRunning(propertyId),
			propertiesService.isPdfMode(propertyId)
		]);
		setScanCurrentlyRunning(isScanRuning);
		setCrawCurrentlyRunning(isCrawlRunning);
		setIsPdfMode(isPdf);
		setLoading(false);
	};

	const createScan = async (onClose): Promise<void> => {
		setStartScanClicked(true);
		try {
			await scansService.createScan(propertyId);
			onScanCreated();
			onClose();
		} catch (err) {
			const msg = 'Cannot start a new scan';
			logger.error(msg, err);
			onClose();
			notificationsContext.alert({ errorMessage: msg, serverError: err });
		} finally {
			setStartScanClicked(false);
		}

		analyticsService.fireEvent({
			category: GA_CONSTS.GA_CATEGORIES.SCANS,
			action: GA_CONSTS.GA_ACTIONS.CREATED
		});
	};

	const isScanningDisabled = (): boolean => {
		return (
			isScanCurrentlyRunning ||
			isCrawlCurrentlyRunning ||
			isPdfMode ||
			isLoading ||
			!urlSet ||
			!urlSet.totalUrlsCount
		);
	};

	const renderScanningDisabledMsg = (): JSX.Element => {
		if (isPdfMode) {
			return <div> Scans are disabled for properties in PDF mode</div>;
		}
		if (isScanCurrentlyRunning) {
			return <div> A scan is already running on this property.</div>;
		}
		if (isCrawlCurrentlyRunning) {
			return (
				<div>
					A crawl is running on this property.
					<br />
					Scans can run once the crawl is complete.
				</div>
			);
		}
		return (
			<div>
				No URLs to scan. <br />
				Run a crawl in order to create the property URLs.
			</div>
		);
	};

	const getTitle = (): string => {
		if (isScanningDisabled()) {
			return 'Cannot scan property';
		}
		return 'Scan Now';
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getButtons = (): any[] => {
		if (isLoading) {
			return [];
		}
		if (isScanningDisabled()) {
			return [
				{
					title: 'OK',
					onClick: (onClose) => {
						onClose();
					}
				}
			];
		}
		return [
			{
				title: 'Scan',
				disabled: isScanningDisabled(),
				loading: startScanClicked,
				onClick: (onClose) => {
					createScan(onClose);
				}
			}
		];
	};
	const renderBody = (): JSX.Element => {
		if (isLoading) {
			return <EvSpinner />;
		}
		return (
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<>
				<div className="popup-form-row">
					{isScanningDisabled() ? (
						renderScanningDisabledMsg()
					) : (
						<div>
							Do you want to run a scan on this property?{' '}
							<b>({urlSet.totalUrlsCount || 0} pages)</b>
						</div>
					)}
				</div>
			</>
		);
	};

	return (
		<SiteScannerPopup
			title={getTitle()}
			isSrOnly={isLoading}
			isCentered
			triggerButtonProps={triggerButtonProps}
			onOpen={checkIfScanOrCrawlAreRunning}
			buttons={getButtons()}
		>
			{renderBody()}
		</SiteScannerPopup>
	);
};

export default StartScanPopup;
