import Logger from './Logger';

type BoundingBox = { x: number; y: number; height: number; width: number };
// Change bbox from list to object
const bboxToObject = (bbox: number[]): BoundingBox => {
	// in case the bounding box array is not in the correct format, return a 0 sized box
	if (!bbox || bbox.length !== 4) {
		Logger.info(
			`Screenshot bounding box is invalid, value received: ${bbox && JSON.stringify(bbox)}`
		);
		return { x: 0, y: 0, height: 0, width: 0 };
	}
	const x = bbox[0];
	const y = bbox[1];
	const width = bbox[2] - x;
	const height = bbox[3] - y;
	return { x, y, height, width };
};

const createImageFromUrl = async (url: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const origImage = new Image();
		origImage.crossOrigin = 'anonymous';
		origImage.src = url;
		origImage.onload = () => resolve(origImage);
		origImage.onerror = () => reject(new Error('Error loading the image'));
	});
};

const drawAllRects = (canvas, bboxList): void => {
	const ctx = canvas.getContext('2d');

	for (const bbox of bboxList) {
		const { x, y, height, width }: BoundingBox = bboxToObject(bbox);
		ctx.beginPath();
		ctx.strokeStyle = 'red';
		ctx.rect(x, y, width, height);
		ctx.lineWidth = 5;
		ctx.stroke();
	}
};

export const getEscapedUrl = (url: string): string => {
	const splitUrl = url.split('?');
	let newUrl = splitUrl[0];
	if (splitUrl.length >= 2) {
		const params = `?${splitUrl[1]}`;
		newUrl = `${newUrl}${encodeURIComponent(params)}`;
	}
	return newUrl;
};

export const getScreenshotAndMarkIssues = async (
	origScreenshotPath: string,
	bboxList: number[][]
): Promise<string> => {
	const screenshotImage = await createImageFromUrl(origScreenshotPath);
	const canvas = document.createElement('canvas');
	canvas.width = screenshotImage.width;
	canvas.height = screenshotImage.height;

	const ctx = canvas.getContext('2d');
	ctx.drawImage(screenshotImage, 0, 0);
	drawAllRects(canvas, bboxList);

	const screenshotWithIssues = canvas.toDataURL();
	return screenshotWithIssues;
};

export const cropImage = async (imageUrl, bbox, elementWidth): Promise<string> => {
	const ELEMENT_HEIGHT = 200;

	const imageObject = await createImageFromUrl(imageUrl);
	const {
		x: bboxX,
		y: bboxY,
		height: bboxHeight,
		width: bboxWidth
	}: BoundingBox = bboxToObject(bbox);

	const bboxXMax = bboxX + bboxWidth;
	const bboxYMax = bboxY + bboxHeight;

	if (
		bboxX < 0 ||
		bboxY < 0 ||
		bboxX + bboxWidth > imageObject.width ||
		bboxY + bboxHeight > imageObject.height ||
		bboxWidth <= 0 ||
		bboxHeight <= 0
	) {
		throw new Error('Got incorrect bbox dimensions');
	}

	const resMaxHeight = Math.max(bboxHeight, ELEMENT_HEIGHT);
	const resMaxWidth = Math.max(bboxWidth, elementWidth);

	const remainingPaddingHeight = resMaxHeight - bboxHeight;
	const remainingPaddingWidth = resMaxWidth - bboxWidth;

	const resX = Math.max(0, bboxX - remainingPaddingWidth / 2);
	const resY = Math.max(0, bboxY - remainingPaddingHeight / 2);
	const resXMax = Math.min(imageObject.width, bboxXMax + remainingPaddingWidth / 2);
	const resYMax = Math.min(imageObject.height, bboxYMax + remainingPaddingHeight / 2);
	const resWidth = resXMax - resX;
	const resHeight = resYMax - resY;

	const canvas = document.createElement('canvas');
	canvas.width = resWidth;
	canvas.height = resHeight;

	const ctx = canvas.getContext('2d');

	ctx.drawImage(imageObject, resX, resY, resWidth, resHeight, 0, 0, resWidth, resHeight);

	const croppedImage = canvas.toDataURL();
	return croppedImage;
};
