import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ITrend } from '../../../interfaces/Trend';
import EvTabs, { VIEW_MODES } from '../../common/ev-tabs/EvTabs';
import SeverityTrendChart from '../severity-trend-chart/SeverityTrendChart';
import './SeverityTrends.scss';
import { SEVERITIES_CHART_TYPE } from './SeverityTrendTypes';

interface ISeverityTrends {
	scanTrendsData: ITrend[];
	setChartType: (type: SEVERITIES_CHART_TYPE) => void;
	chartType: SEVERITIES_CHART_TYPE;
	onBarItemClick: (scanId: string, severity: string) => void;
}

const tabOptions = [
	{ key: SEVERITIES_CHART_TYPE.TOTAL_ISSUES, label: 'Total Issues' },
	{ key: SEVERITIES_CHART_TYPE.PERCENTAGE, label: 'Percentage' },
	{ key: SEVERITIES_CHART_TYPE.AVERAGE_PER_PAGE, label: 'Avg Per Page' }
];

// this function calculates positioning of Tabs,
// since it should be in the middle between chart title and filters
const getStylesForTabs = (): React.CSSProperties => {
	const chartWidth = document.querySelector('.severity-trend-chart').clientWidth;
	const filtersWidth = document.querySelector('.severity-trend-chart .ev-chart-legend').clientWidth;
	const titleWidth = 152;
	const tabsWidth = 274;
	const emptySpace = chartWidth - (filtersWidth + 60 + titleWidth + tabsWidth);
	const leftPosition = 30 + titleWidth + emptySpace / 2;
	return { position: 'absolute', left: leftPosition, top: 30 };
};

const SeverityTrends: FC<ISeverityTrends> = ({
	scanTrendsData,
	chartType,
	setChartType,
	onBarItemClick
}: ISeverityTrends) => {
	const [tabsStyles, setTabStyles] = useState(null);

	useEffect(() => {
		setTimeout(() => {
			const styles = getStylesForTabs();
			setTabStyles(styles);
		}, 0);
	}, []);

	const severityTrends = useMemo(() => {
		return tabOptions.map((option) => (
			<SeverityTrendChart
				scanTrendsData={scanTrendsData}
				chartType={option.key}
				onBarItemClick={onBarItemClick}
			/>
		));
	}, [scanTrendsData, onBarItemClick]);

	const renderTabsSelection = useCallback(
		() => (
			<EvTabs
				className="trends-charts-tabs"
				options={tabOptions}
				selected={chartType}
				onSelect={setChartType}
				tabPanels={severityTrends}
				styles={tabsStyles}
				viewMode={VIEW_MODES.DIVIDED}
			/>
		),
		[chartType, setChartType, severityTrends, tabsStyles]
	);

	return <div className="severity-trends">{renderTabsSelection()}</div>;
};

export default SeverityTrends;
