import {
	BarDatum,
	ChartsHelper,
	EvBarChart,
	EvChartBottomTick,
	EvChartTooltip,
	EvIcon,
	EvSpinner
} from '@evinced-private/ui-common';
import capitalize from 'lodash/capitalize';
import maxBy from 'lodash/maxBy';
import React, { FC, useCallback, useMemo } from 'react';
import { formatShortMonthDatetime } from '../../helpers/DateFormatHelper';
import ChartsRenderingHelper from '../../helpers/charts/ChartsRenderingHelper';
import ColorsHelper from '../../helpers/charts/ColorsHelper';
import NoDataIndication from '../common/ev-table/no-data-indication/NoDataIndication';
import NoTrendsIcon from '../icons/NoTrendsIcon.svg';
import './ScanComparisonIssueStatusBreakdown.scss';

const title = 'Breakdown by Issue Status';
const patterns = [
	{
		id: 'reoccurring',
		label: 'Reoccurring',
		color: ColorsHelper.reocurringColor
	},
	{
		id: 'resolved',
		label: 'Resolved',
		color: ColorsHelper.resolvedColor
	},
	{
		id: 'new',
		label: 'New',
		color: ColorsHelper.newIssuesColor
	}
];

const keys = patterns.map((l) => l.id);

export interface IIssueStatusBreakdown {
	newIssues: number;
	reoccurringIssues: number;
	resolvedIssues: number;
	baseDate: string;
	comparedDate: string;
}

type IIssueStatusBreakdownProps = IIssueStatusBreakdown & {
	isLoading: boolean;
};

const ScanComparisonIssueStatusBreakdown: FC<IIssueStatusBreakdownProps> = ({
	isLoading,
	newIssues = 0,
	reoccurringIssues = 0,
	resolvedIssues = 0,
	baseDate,
	comparedDate
}) => {
	const chartData = useMemo(
		() => [
			{
				date: formatShortMonthDatetime(baseDate),
				resolved: resolvedIssues,
				reoccurring: reoccurringIssues,
				total: resolvedIssues + reoccurringIssues
			},
			{
				date: formatShortMonthDatetime(comparedDate),
				new: newIssues,
				reoccurring: reoccurringIssues,
				total: newIssues + reoccurringIssues
			}
		],
		[baseDate, comparedDate, newIssues, reoccurringIssues, resolvedIssues]
	);

	const getGridYValues = (barData: BarDatum[]): number[] => {
		const field = 'total';
		const maxDatum = maxBy(barData, field);
		const maxValue = Number(maxDatum?.[field] || 0);
		return ChartsHelper.getYAxisValues(maxValue);
	};
	const yAxisValues = useMemo(() => getGridYValues(chartData), [chartData]);

	const renderTick = useCallback(
		(axisPosition) => ChartsRenderingHelper.renderTickInKFormat(yAxisValues, axisPosition),
		[yAxisValues]
	);

	if (isLoading) {
		return (
			<div className="compare-scans-issue-status-breakdown">
				<EvSpinner />
			</div>
		);
	}

	if (newIssues === 0 && reoccurringIssues === 0 && resolvedIssues === 0) {
		return (
			<div className="compare-scans-issue-status-breakdown">
				<NoDataIndication
					className="scan-no-data-indication"
					title="No Issues"
					renderIcon={() => <EvIcon className="no-data-indication-icon" icon={NoTrendsIcon} />}
					renderTextMessage={() => <span>There are no issues to show</span>}
				/>
			</div>
		);
	}

	const tooltipRenderer = ({ id, value }): JSX.Element => {
		return <EvChartTooltip label={`${capitalize(String(id))} Issues`} value={value} />;
	};

	const renderSRSummary = (): JSX.Element => {
		return (
			<>
				{title}
				On {chartData[0].date}: Resolved issues - {chartData[0].resolved}. On {chartData[1].date}:
				Reoccurring issues - {chartData[1].reoccurring}, New issues - {chartData[1].new}.
			</>
		);
	};

	return (
		<EvBarChart
			title={title}
			className="compare-scans-issue-status-breakdown"
			customSrSummaryRenderer={renderSRSummary}
			data={chartData}
			keys={keys}
			indexBy="date"
			patterns={patterns}
			height={410}
			isComplex
			withFiltering
			barDesignProps={{
				margin: { top: 40, right: 0, bottom: 60, left: 70 },
				enableLabel: false,
				innerPadding: 2,
				padding: 0.715,
				tooltip: tooltipRenderer,
				animate: false,
				axisTop: null,
				axisBottom: {
					tickSize: 0,
					tickPadding: 25,
					renderTick: (tick) => <EvChartBottomTick tick={tick} />
				},
				axisLeft: {
					tickSize: 0,
					tickPadding: 10,
					tickRotation: 0,
					legend: 'Issues',
					legendOffset: -55,
					legendPosition: 'middle',
					renderTick: renderTick('axisLeft')
				}
			}}
		/>
	);
};

export default ScanComparisonIssueStatusBreakdown;
