import { IPaginationResponse } from './PaginationResponse';

export enum PageResultState {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	FILTERED_OUT = 'FILTERED_OUT'
}

export type RequestedUrl = {
	url: string;
	finalUrl: string;
	status: PageResultState.SUCCESS;
};

export interface IRequestedUrlsPaginationResponse extends IPaginationResponse {
	pages: RequestedUrl[];
}
