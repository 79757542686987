/* eslint-disable react/jsx-no-useless-fragment */
import { EvSection, EvTitle } from '@evinced-private/ui-common';
import classNames from 'classnames';
import React, { FC } from 'react';
import { SummaryItem } from '../../interfaces/SummaryItem';
import ISSUE_COMPARISON_TYPE from '../../pages/scans-comparison/IssueComparisonType';
import SummaryPane from '../common/summary-pane/SummaryPane';
import './ScanComparisonSummaryPane.scss';

const getClassNames = (type: ISSUE_COMPARISON_TYPE): string => {
	return classNames('issue-summary-item', {
		'new-issues': ISSUE_COMPARISON_TYPE.NEW_ISSUES === type,
		'reocurring-issues': ISSUE_COMPARISON_TYPE.RECURRING_ISSUES === type,
		'resolved-issues': ISSUE_COMPARISON_TYPE.RESOLVED_ISSUES === type
	});
};

export interface IIssuesSummaryItem {
	label: ISSUE_COMPARISON_TYPE;
	issueCount: string | number | JSX.Element;
	componentCount: string | number | JSX.Element;
}

interface IScanComparisonSummary {
	pages: SummaryItem[];
	issues: IIssuesSummaryItem[];
	commonOnly: boolean;
}

const ScanComparisonSummaryPane: FC<IScanComparisonSummary> = ({ pages, issues, commonOnly }) => {
	const renderIssuesSummary = (): JSX.Element => {
		return (
			<ul>
				{issues.map(({ issueCount, componentCount, label }) => (
					<li
						className={getClassNames(label)}
						key={`issue-summary-${label}`}
						aria-label={`${issueCount} ${label} in ${componentCount} components`}
					>
						<>
							<div className="value" aria-hidden="true">
								{issueCount}
							</div>
							<div className="label" aria-hidden="true">
								{label}
							</div>
							<div className="issue-summary-item-divider">
								<hr />
								<span>in</span>
								<hr />
							</div>
							<div className="value" aria-hidden="true">
								{componentCount}
							</div>
							<div className="label" aria-hidden="true">
								Components
							</div>
						</>
					</li>
				))}
			</ul>
		);
	};

	const issuesFoundTitleText = `Issues Found Across Components in ${
		commonOnly ? 'Common' : 'All'
	} Pages`;

	return (
		<EvSection
			ariaLabel="Scan comparison summary"
			skipLinkId="scan-comparison-summary"
			className="summary-section"
		>
			<EvTitle
				titleText="Pages Found in Comparison"
				className="new-section-title"
				headingLevel={3}
			/>
			<SummaryPane items={pages} shouldHaveSkipLink={false} />
			<hr />
			<EvTitle titleText={issuesFoundTitleText} className="new-section-title" headingLevel={3} />

			{renderIssuesSummary()}
		</EvSection>
	);
};

export default ScanComparisonSummaryPane;
